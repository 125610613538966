/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Stack, Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./RightSidebar.css";
import ProjectDetails from "./ProjectDetails";
import ActionButtons from "./ActionButtons";
import PriceSummary from "./PriceSummary";
import EditableProjectDetails from "./EditableProjectDetails";
import SeeProfile from "./SeeProfile";
import { useJobSharedState } from "src/utils/jobStateProvider";
// import RejectAlert from "./RejectAlert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSharedState } from "src/utils/SharedStateProvider";
import ProjectDetailsIcon from "../../Assets/icon-project-details-solid.svg";
import { Files } from "./Files";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useRef } from "react";

export default function RightSidebar() {
  const [value, setValue] = useState("1");
  const role = parseInt(localStorage.getItem("role"));
  const { userJob, submitContract } = useJobSharedState();
  const { dispute, userId } = useJobSharedState();
  const [save, setSave] = useState(false);
  const {
    jobId,
    showRightSidebar,
    setShowRightSidebar,
    isEditing,
    setIsEditing,
    showSiderBar,
    setHideRightSideBar,
    hideRightSideBar,
  } = useSharedState();
  const matches = useMediaQuery("(min-width:900px)");
  //const {userId} = JobStateProvider()

  // Toggle editing mode
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const buyerTabs = [
    { label: "Project", value: "1" },
    // { label: "Price", value: "2" },
    { label: "Profile", value: "3" },
    // { label: "Files", value: "4" },
  ];
  const sellerTabs = [
    { label: "Project", value: "1" },
    { label: "Profile", value: "3" },
    { label: "Files", value: "4" },
  ];

  const rightSidebarRef = useRef(null);

  useEffect(() => {
    if (hideRightSideBar && rightSidebarRef) {
      rightSidebarRef.current.classList.add("hide");
      rightSidebarRef.current.classList.remove("show");
      const timeoutId = setTimeout(() => {
        rightSidebarRef.current.style.display = "none";
      }, 300);
      return () => clearTimeout(timeoutId);
    } else {
      if (rightSidebarRef.current !== null) {
        rightSidebarRef.current.classList.add("show");
        rightSidebarRef.current.style.display = "block";
        const timeout = setTimeout(() => {
          rightSidebarRef.current.classList.remove("hide");
        }, 300);
        return () => clearTimeout(timeout);
      }
    }
  }, [hideRightSideBar]);

  // useEffect(() => {
  //   if (
  //     (submitContract || userJob.price) &&
  //     parseInt(role) === 1 &&
  //     userJob.jobStatus === "pending"
  //   ) {
  //     setValue("2");
  //   }
  // }, [submitContract, userJob]);
  useEffect(() => {
    if (parseInt(role) === 4 && userId) {
      setValue("3");
    }
  }, [userId]);
  const handleHideRightSideBar = () => {
    setHideRightSideBar(true);
  };
  useEffect(() => {
    setIsEditing(false);
    setValue("1");
  }, [hideRightSideBar,jobId]);
  
  return (
    <>
      {(showRightSidebar || matches) && (
        <Stack
          ref={rightSidebarRef}
          direction="column"
          // className={hideRightSideBar ? "Rightsidebar hide" : "Rightsidebar show"}
          className={"Rightsidebar"}
          sx={{ width: showSiderBar ? "40%" : "320px" }}
        >
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value} sx={{ width: "100%" }}>
              <Box
                sx={(theme) => ({
                  borderBottom: 1,
                  borderColor: "divider",
                  [theme.breakpoints.down("md")]: {
                    display: "flex",
                    width: "100%",
                  },
                })}
                className="rightsidebar-header"
              >
                <Box sx={{ display: "flex" }}>
                  <button
                    className="right_nav_back_btn"
                    onClick={() => handleHideRightSideBar()}
                  >
                    <ChevronRightIcon />
                  </button>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {role === 1
                      ? buyerTabs.map((obj) =>
                          ((userJob.jobStatus === "pending" ||
                            userJob.jobStatus === "waiting" ||
                            userJob.jobStatus === "refund") &&
                            (obj.value === "3" || obj.value === "4")) ||
                          (!userJob.price && obj.value === "2") ? (
                            ""
                          ) : (
                            <Tab
                              label={obj.label}
                              value={obj.value}
                              key={obj.value}
                              className={
                                obj.value === "1"
                                  ? "buyerTab BorderTab"
                                  : "buyerTab"
                              }
                            />
                          )
                        )
                      : sellerTabs.map((obj) => (
                          <Tab
                            label={obj.label}
                            value={obj.value}
                            key={obj.value}
                            className={
                              obj.value === "1"
                                ? "sellerTab BorderTab"
                                : "sellerTab"
                            }
                          />
                        ))}
                  </TabList>
                </Box>
                <Box
                  sx={(theme) => ({
                    display: "none",
                    [theme.breakpoints.down("sm")]: {
                      display: "flex",
                      alignItems: "center",
                      mr: 1,
                    },
                  })}
                  id="right_sidebar_btn"
                >
                  <Button
                    sx={{ minWidth: "40px", height: "40px" }}
                    onClick={() => {
                      setShowRightSidebar(!showRightSidebar);
                    }}
                  >
                    <img src={ProjectDetailsIcon} alt="" />
                  </Button>
                </Box>
              </Box>
              <TabPanel value="1">
                <Box className="ProjectDetails">
                  {isEditing ? (
                    <EditableProjectDetails
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      save={save}
                      setSave={setSave}
                    />
                  ) : (
                    <ProjectDetails
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <PriceSummary />
              </TabPanel>
              <TabPanel value="3">
                <SeeProfile />
              </TabPanel>
              <TabPanel value="4">
                <Files />
              </TabPanel>
            </TabContext>
          </Box>

          <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
            <ActionButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              value={value}
              save={save}
              setSave={setSave}
            />
          </Box>

          {/* {dispute ? <RejectAlert /> : ""} */}
        </Stack>
      )}
    </>
  );
}
