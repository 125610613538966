import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginFooter from "../LoginFooter/LoginFooter";
import LoginHeader from "../LoginHeader/LoginHeader";
import { toast } from "react-toastify";
import { emailSchema } from "src/validationSchema/loginSchema";
import { useSharedState } from "src/utils/SharedStateProvider";
import { userAPI } from "src/services/userApis";

export const ForgetPassword = () => {
  const { setLoading } = useSharedState();
  const [value, setValue] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValue((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleBlur = () => {
    validateForm();
  };
  const validateForm = () => {
    const { error } = emailSchema.validate(value, {
      abortEarly: false,
    });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      userAPI
        .forgotPassword(value)
        .then((res) => {
          if (res.success === true) {
            sessionStorage.setItem("email", value.email);
            setLoading(false);
            navigate("/forgotPasswordVerification");
          } else {
            setLoading(false);
            toast.error(res.body.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }
  };
  return (
    <>
      <Box
        className="LoginPage"
        sx={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
      >
        <LoginHeader />
        <Box className="SignIn">
          <Grid
            container
            sx={{
              background: "white",
              borderRadius: "20px",
              maxWidth: "564px",
              margin: "0 auto",
            }}
            className="ForgotPasswordBox"
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                p: 2,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", textAlign: "center" }}
                >
                  OOPS !
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", textAlign: "center" }}
                >
                  Lost track of your key didn’t you?
                </Typography>

                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="p"
                    sx={(theme) => ({
                      lineHeight: "1.3",
                      [theme.breakpoints.down("md")]: { fontSize: "14px" },
                    })}
                  >
                    Just pop in your mail, we’ll shoot a password reset OTP
                    your way. No Sweat!
                  </Typography>
                </Box>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email address*"
                  type="email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                  sx={{ mt: 2 }}
                  onChange={handleChange}
                  value={value.email}
                  onBlur={handleBlur}
                />
                <Button
                  type="submit"
                  variant="contained"
                  className="LoginButton"
                  sx={{ width: "100%", mt: 2 }}
                >
                  Send Code
                </Button>
              </form>
              {/* <Box sx={{ my: 2 }} className="newHere">
                <Typography variant="p">
                  Didn’t get? &nbsp;
                  <Link
                    to="/forgotpasswordverification"
                    underline="none"
                    sx={{ color: "#333333 !important" }}
                  >
                    Resend
                  </Link>
                </Typography>
              </Box> */}
            </Grid>
          </Grid>
        </Box>
        <LoginFooter />
      </Box>
    </>
  );
};
