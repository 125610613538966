import * as React from "react";
import { Box, Typography, Link, Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ProfilePhoto from "../../Assets/AiProfile.png";
import ProfilePhoto1 from "../../Assets/user-default-icon.png";
import "./Notifications.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { baseURLs } from "src/constants/api.constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NewNotification from "./NewNotification";
import moment from "moment";
import { userAPI } from "src/services/userApis";

export const Notifications = () => {
  const [notifications, setNotifications] = React.useState([]);
  const { setShowNotifications, setNotificationCouter, notificationCouter } =
    useSharedState();
  const getNotification = async () => {
    await userAPI
      .getNotifications()
      .then((res) => {
        setNotifications(res.data.data);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };

  React.useEffect(() => {
    getNotification();
  }, []);

  const markAsReadNotification = () => {
    userAPI
      .markAsReadNotifications()
      .then((res) => {
        setNotificationCouter(0);
        getNotification();
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };

  const markAsRead = async (notificationId) => {
    await userAPI
      .markAsReadNotificationById(notificationId)
      .then((res) => {
        setNotificationCouter(notificationCouter - 1);
        getNotification();
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const navigate = useNavigate();
  const handleClickNotification = async (id, notificationId) => {
    await markAsRead(notificationId);
    setShowNotifications(false);
    navigate(`/dashboard/chat/${id}`);
  };
  return (
    <Box className="NotificationsBar">
      <Box className="Notify-Header">
        <Link
          onClick={() => {
            setShowNotifications(false);
          }}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Typography>
            {" "}
            <KeyboardArrowRightIcon /> Notifications
          </Typography>
        </Link>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Typography sx={{ fontSize: "16px !important" }}>
            Notifications
          </Typography>
        </Box>
        <Link
          onClick={() => {
            markAsReadNotification();
          }}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          Mark all as read
        </Link>
      </Box>
      <Box className="Notify-Details-Cnt">
        {notifications.length !== 0 ? (
          notifications.map((notification, index) => (
            <Link
              key={notification}
              component={Button}
              onClick={() => {
                handleClickNotification(notification.jobId, notification.id);
              }}
            >
              <Box className="NotificationBox">
                <Box className="Notify-Details">
                  <img
                    src={
                      notification.senderId === 1
                        ? ProfilePhoto
                        : notification.sender.profileImage !== null
                        ? `${baseURLs.yemo}/${notification.sender.profileImage}`
                        : ProfilePhoto1
                    }
                    alt="logo"
                    style={{ borderRadius: "50%" }}
                  />
                  <Typography variant="subTitle1">
                    {notification.content}
                  </Typography>
                </Box>
                <Box className="Notify-Time">
                  {moment(notification.createdAt).format("MMM D, YYYY h:mma")}
                </Box>
              </Box>
            </Link>
          ))
        ) : (
          <NewNotification />
        )}
      </Box>
      <Box className="Notify-Footer">
        <Button
          variant="contained"
          onClick={() => {
            setShowNotifications(false);
          }}
          className="rejectButton"
        >
          Close
        </Button>
        <Button
          variant="contained"
          className="LoginButton"
          onClick={() => {
            markAsReadNotification();
          }}
        >
          Mark all as read
        </Button>
      </Box>
    </Box>
  );
};
