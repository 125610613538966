import React, { useEffect, useState } from "react";
import "../../style/style.css";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./SignIn.css";
//import GoogleIcon from "@mui/icons-material/Google";
import { Link, useNavigate } from "react-router-dom";
import { loginUserSchema } from "../../validationSchema/loginSchema";
import LoginHeader from "../LoginHeader/LoginHeader";
import LoginFooter from "../LoginFooter/LoginFooter";
import { toast } from "react-toastify";
import { useSharedState } from "src/utils/SharedStateProvider";
import { socket } from "../Main/Message";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userAPI } from "src/services/userApis";
import { useAuth } from "src/utils/auth-context";

export const SignIn = () => {
  const { setLoading } = useSharedState();
  const [touchedFields, setTouchedFields] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleTogglePassword = () => setShowPassword(!showPassword);
  const getToken = () => localStorage.getItem("authProviderToken");
  const { login } = useAuth();
  const validateForm = () => {
    const { error } = loginUserSchema.validate(formData, { abortEarly: false });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleBlur = (event) => {
    const { name } = event.target;
    if (!touchedFields.includes(name)) {
      setTouchedFields([...touchedFields, name]);
    }
    validateForm();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm()) {
      userAPI
        .logIn(formData)
        .then(async (res) => {
          if (res.success === true) {
            localStorage.clear();
            const data = res.body;
            localStorage.setItem("tour", data.data.user.tour);
            localStorage.setItem("authProviderToken", data.data.token);
            localStorage.setItem("userId", data.data.user.id);
            localStorage.setItem("role", data.data.user.roleId);
            localStorage.setItem("userName", data.data.user.name);
            localStorage.setItem("profileImage", data.data.user.profileImage);
            const token = getToken();
            await login?.(data.data.user, token);
            //  setSession(token);
            setLoading(false);
            if (token !== null) {
              socket.auth = { token };
              socket.connect();
              console.log("Socket connecting...");
            }
            navigate("/dashboard");
          } else {
            if (res.body.data?.isVerified === false) {
              toast.error(res.body.message);
              sessionStorage.setItem("email", formData.email);
              setLoading(false);
              navigate("/verification");
            } else {
              setLoading(false);
              toast.error(res.body.message);
              if(res.body.message === "Wrong Password!!"){
                setErrors({
                  password:"Please enter correct password",
                });
              }
              if(res.body.message === "Please signup first!!"){
                navigate("/signup")
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!!");
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem("authProviderToken");
  }, []);

  return (
    <Box
      className="LoginPage"
      sx={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
    >
      <LoginHeader />
      <Box className="SignIn">
        <Grid
          container
          sx={{
            background: "white",
            borderRadius: "20px",
            maxWidth: "564px",
            margin: "0 auto",
          }}
          className="SignInBox"
        >
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              p: 2,
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={(theme) => ({
                  fontWeight: "700",
                  textAlign: "center",
                  fontSize: "28px",
                  [theme.breakpoints.down("md")]: { mb: 2 },
                })}
              >
                Welcome back amigo!
              </Typography>
              <Box
                sx={(theme) => ({
                  mt: 2,
                  [theme.breakpoints.down("md")]: { display: "none" },
                })}
              >
                <Typography variant="p" sx={{ lineHeight: "1.3" }}>
                  <Typography
                    variant="span"
                    sx={{ color: "#333333", fontWeight: "600" }}
                  >
                    Been here, done that?
                  </Typography>
                  <br /> Just whisper{" "}
                  <Typography
                    variant="span"
                    sx={{
                      color: "#018385",
                      fontWeight: "600",
                      fontStyle: "italic",
                    }}
                  >
                    'Alohomora'
                  </Typography>{" "}
                  with your credentials
                  <br /> or take the shortcut with the button below.
                </Typography>
              </Box>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                name="email"
                label="Email address*"
                value={formData.email}
                error={!!errors.email}
                helperText={
                  // touchedFields.includes("email") ?
                  errors.email
                  //  : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ mt: 2 }}
                autoComplete="email"
                InputLabelProps={{ shrink: true }}
                inputProps={{ inputMode: "email" }}
              />
              <TextField
                name="password"
                label="Password*"
                value={formData.password}
                type={showPassword ? "text" : "password"}
                error={!!errors.password}
                helperText={
                  // touchedFields.includes("password") ?
                  errors.password
                  // : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ mt: 2 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                      >
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff sx={{ color: "#0F485B" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="current-password"
              />
              <Box sx={{ my: 2 }}>
                <Link
                  to="/forgotpassword"
                  underline="none"
                  sx={{ color: "#333333 !important" }}
                >
                  Forgot Password ?
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                className="LoginButton"
                sx={{ width: "100%" }}
              >
                Sign In
              </Button>
            </form>
            {/* <Divider sx={{ my: 2 }}>Or Login with</Divider>
            <Button variant="contained" sx={{ width: "100%" }} className="SocialLoginButton">
              <GoogleIcon sx={{ mr: 2 }} /> Login With Google
            </Button> */}
            <Box sx={{ mt: 2 }} className="newHere">
              <Typography variant="p">
                New here &nbsp;
                <Link
                  to="/SignUp"
                  underline="none"
                  sx={{ color: "#333333 !important" }}
                >
                  Signup
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LoginFooter />
    </Box>
  );
};
