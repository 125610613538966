/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import "./Main.css";
import { useState, useEffect } from "react";
import { useSharedState } from "src/utils/SharedStateProvider";
import AviIcon from "../../Assets/avi-icon.png";
import { baseURLs } from "src/constants/api.constants";
import LinkIconRecording from "../../Assets/link-icon.svg";
import "./MeetingRecording.css";
import LinkIcon from "../../Assets/link-variant.svg";
// import MeetingIcon from "../../Assets/link-variant.png";
import "./MeetingLinks.css";
import { Link } from "react-router-dom";
import { socket } from "./Message";
import { useJobSharedState } from "src/utils/jobStateProvider";
import MessageProfile from "./MessageProfile";
import { getFileExtension } from "src/utils/helper";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { chatAPI } from "src/services/jobApis";
import { toast } from "react-toastify";
export default function LeftMessage({
  job,
  msg,
  subCategories,
  categories,
  handleClick,
  questionId,
  filteredMessages,
  forceRerender,
}) {
  const { jobId, highlightedMessage, highlightedMessageRef } = useSharedState();
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const role = parseInt(localStorage.getItem("role"));
  const userId = parseInt(localStorage.getItem("userId"));
  const { meetingStatus, setJoinButtonClicked, setMeetingStatus } =
    useJobSharedState();

  useEffect(() => {
    if (highlightedMessageRef.current) {
      // highlightedMessageRef.current.style.background ="yellow"
      highlightedMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [highlightedMessage]);

  const isMessageHighlighted =
    filteredMessages &&
    filteredMessages.some((filteredMsg) => filteredMsg.id === msg.id);
  useEffect(() => {
    // setSelectedCategory(null);
    //  setSelectedSubCategory(null);
  }, [jobId]);
  useEffect(() => {
    if (job.category) {
      setSelectedCategory(job.category);
    }
    if (job.subCategory) {
      setSelectedSubCategory(job.subCategory);
    }
  }, [job]);
  const handleSetCategory = (name) => {
    setSelectedCategory(name);
  };
  const handleSetSubCategory = (name) => {
    setSelectedSubCategory(name);
  };
  const handelJointMeeting = (link) => {
    window.open(link, "_blank");

    // Check if the interval has already started
    const regex = /meetingID=([^&]+)/; // Match anything that's not an ampersand (&)
    const match = regex.exec(link);

    if (match) {
      const meetingId = match[1];

      if (meetingId && !meetingStatus) {
        const data = {
          meetingId,
          jobId,
        };

        // Listen for the "getmeetingStatus" socket event
        socket.on("getmeetingStatus", (data) => {
          setMeetingStatus(data.data);

          if (data.data === true) {
            setJoinButtonClicked(false);
            // If the meeting is over, clear the interval and remove the event listener
            clearInterval(interval);
            socket.off("getmeetingStatus");
            socket.off("meetingStatus");
          }
        });

        // Start a setInterval to periodically check meeting status
        const interval = setInterval(() => {
          socket.emit("meetingStatus", data);
          if (meetingStatus) {
            setJoinButtonClicked(false);
            clearInterval(interval); // Stop the interval
          }
        }, 30000); // 5 minutes in milliseconds
      }
    }
  };
  useEffect(() => {
    if (meetingStatus) {
      setJoinButtonClicked(false);
    }
  }, [meetingStatus]);

  console.log(msg.meetingSchedulers);
  const getStatusByUserId = (array, userIdToFind) => {
    const foundObject = array.find((obj) => obj.userId === userIdToFind);

    return foundObject ? foundObject.status : null;
  };

  const updateScheduler = (messageId, status) => {
    chatAPI
      .updateMeetingScheduler({ messageId, status })
      .then((res) => {
        if (res.data.success) {
          const messageData = {
            jobId: jobId,
            message: `meeeting updated`,
          };
          socket.emit("yemoMessage", { messageData });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  return (
    <>
      {msg !== undefined ? (
        <Box>
          {msg.type === "payment" && (role === 1 || role === 4) ? (
            <>
              <MessageProfile msg={msg} />
            </>
          ) : (
            ""
          )}

          <Box
            className="leftMessage"
            ref={highlightedMessage === msg.id ? highlightedMessageRef : null}
          >
            {msg.type === "text" ? (
              <Typography
                variant="p"
                sx={{
                  whiteSpace: "pre-wrap",
                  background: isMessageHighlighted ? "yellow" : "",
                }}
              >
                {msg.content}
              </Typography>
            ) : msg.type === "options" &&
              (msg.content === "Select a sub-category for your job") ? (
              <>
                <Typography variant="p">{msg.content}</Typography>
                <Box className="chipGroup">
                  {subCategories.map((subCategory) => (
                    <Chip
                      className={
                        subCategory.name === selectedSubCategory
                          ? "MuiChip-root-selected"
                          : ""
                      }
                      key={subCategory.id}
                      label={subCategory.name}
                      onClick={() => {
                        if (questionId === 3) {
                          handleSetSubCategory(subCategory.name);
                          handleClick(subCategory.id);
                        }
                      }}
                      component="a"
                      variant="outlined"
                      clickable
                      sx={{ background: "#eee" }}
                    />
                  ))}
                </Box>
              </>
            ) : msg.type === "options" &&
              (msg.content === "Select a category for your job") ? (
              <>
                <Typography variant="p">{msg.content}</Typography>
                <Box className="chipGroup">
                  {categories.map((category) => (
                    <Chip
                      className={
                        selectedCategory === category.name
                          ? "MuiChip-root-selected"
                          : ""
                      }
                      key={category.id}
                      label={category.name}
                      onClick={() => {
                        if (questionId === 2) {
                          handleSetCategory(category.name);
                          handleClick(category.id);
                        }
                      }}
                      component="a"
                      variant="outlined"
                      clickable
                      sx={{ background: "#eee" }}
                    />
                  ))}
                </Box>
              </>
            ) : msg.type === "reject" ? (
              <>
                {msg.content.split("title").map((line, lineIndex) => (
                  <Typography variant="p" key={line}>
                    {lineIndex === 0 ? (
                      line
                    ) : line.includes(": -") ? (
                      <span>
                        title: -{line.split(": -")[1].split("decription")[0]}
                        <br />
                        description: -{line.split(": -")[1]}
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                ))}
              </>
            ) : msg.type === "payment" && (role === 1 || role === 4) ? (
              <>
                <Typography variant="p">{msg.content}</Typography>
              </>
            ) : msg.type === "file" ? (
              <>
                <Box className="leftMessageFile">
                  <a href={`${baseURLs.yemo}/${msg.file}`}
                        target="_blank"
                        download
                        rel="noreferrer"
                        style={{textDecoration:"none", color:"black"}}>
                  <Typography variant="p">
                    {msg.file === "" ? (
                      <img src={AviIcon} alt="aviIcon" />
                    ) : (
                      <a
                        className="img-icon"
                        href={`${baseURLs.yemo}/${msg.file}`}
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        <img
                          src={AviIcon}
                          alt="aviIcon"
                          height="40px"
                          width="30px"
                        />
                        <p className="img-icon-text">
                          {getFileExtension(msg.content)}
                        </p>
                      </a>
                    )}
                    <span>{msg.content}</span>
                  </Typography>
                  </a>
                </Box>
              </>
            ) : msg.type === "link" ? (
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Box className="MeetingLinks">
                  <Box className="LinkTxt">
                    <Avatar>
                      <img src={LinkIcon} alt="link" />
                    </Avatar>
                    <Typography variant="p">Meeting Link</Typography>
                  </Box>
                  <Button
                    variant="contained"
                    className="join-btn"
                    onClick={() => {
                      handelJointMeeting(msg.content);
                    }}
                  >
                    Join Now
                  </Button>
                </Box>
              </Box>
            ) : msg.type === "recording" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  background: "#EEFBFF",
                  borderRadius: "10px",
                }}
              >
                <Box className="MeetingRecording">
                  <Link
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      window.open(msg.file, "_blank");
                    }}
                  >
                    <Box className="LinkTxt">
                      <Avatar>
                        <img src={LinkIconRecording} alt="LinkIconRecording" />
                      </Avatar>
                      <Box>
                        <Typography variant="p">Meeting Recording</Typography>
                        <Typography variant="span">{msg.content}</Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>
            ) : msg.type === "meeting" ? (
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Box className="Meeting">
                  <Box className="MeetingLinkTxt" sx={{ paddingBottom: "2%" }}>
                  <Avatar>
                      <img src={LinkIcon} alt="link" />
                    </Avatar>
                    <Typography
                      className="meetingTypo"
                      variant="p"
                      sx={{ color: "white", backgroundColor: "#0171DF" }}
                    >
                      {msg.meetingData[0]?.title}
                    </Typography>
                  </Box>
                  <Box className="MeetingLinkTxt" sx={{ paddingBottom: "2%" }}>
                    <AccessTimeIcon />
                    &nbsp;
                    {msg.meetingData[0]?.duration} &nbsp;&nbsp;&nbsp;
                    {msg.meetingData[0]?.start ? (
                      <>
                        <EventAvailableIcon />
                        {moment(msg.meetingData[0]?.start).format(
                          "MM-DD-YYYY | hh:mma"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  {getStatusByUserId(msg.meetingSchedulers, userId) ===
                  "accepted" ? (
                    <Button
                      variant="contained"
                      className="join-btn"
                      disabled={!msg.file}
                      onClick={() => {
                        if (parseInt(msg.userId) === parseInt(userId)) {
                          handelJointMeeting(msg.file);
                        } else {
                          handelJointMeeting(msg.content);
                        }
                      }}
                    >
                      Join Now
                    </Button>
                  ) : getStatusByUserId(msg.meetingSchedulers, userId) ===
                    "select" ? (
                    <>
                      <Button
                        variant="contained"
                        className="acceptButton"
                        onClick={() => {
                          updateScheduler(msg.id, "accepted");
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        className="declineButton"
                        onClick={() => {
                          updateScheduler(msg.id, "rejected");
                        }}
                      >
                        Decline
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}

      {/* <Box >
        I am left message
         <MeetingLinks />
        <MeetingRecording />
      </Box> */}
    </>
  );
}
<>
  {/* {(msg.type === "text" ||
            msg.type === "options" ||
            msg.type === "reject" ||
            msg.type === "payment" ||
            msg.type === "file") && (
            <Box className="MessageDetail" sx={{ mt: 2 }}>
              <Box sx={{ px: 2, display: "flex", alignItems: "center" }}>
                <img
                  alt="profile"
                  src={
                    msg.userId === 1
                      ? Yemologo
                      : (msg?.profileImage || msg.profileImage !== null ) 
                      ? `${baseURLs.yemo}/${msg.profileImage}`
                      : Profile
                  }
                  height="25px"
                  width="25px"
                  style={{ borderRadius: 50 }}
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "12px",
                    ml: 1,
                    color: "#9D9EA8",
                    fontWeight: "500",
                  }}
                >
                  {msg.name} {moment(msg.created_at).format("h:mmA")}
                </Typography>
              </Box>
            </Box>
          )} */}
</>;
