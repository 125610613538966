/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
// import { baseURLs } from "src/constants/api.constants";
import { userAPI } from "src/services/userApis";
import SearchIcon from "@mui/icons-material/Search";
import "./TransactionTable.css"
import JobInvoice from "./JobInvoice";
import WalletInvoice from "./WalletInvoice";
import { useReactToPrint } from "react-to-print";
export default function TranscationTable() {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [invoice,setInvoice] = useState({});
  const invoiceRef = useRef(null);
  const getTransaction = () => {
    userAPI
      .getTransactions()
      .then((res) => {
        setTransactions(res.data.body.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTransactionName = (transaction) => {
    const { paymentIntentId, jobId, job } = transaction;

    if (paymentIntentId === "1" && jobId !== null) {
      return job.name.toLowerCase();
    } else if (paymentIntentId !== "1" && jobId !== null) {
      return job.name.toLowerCase();
    } else if (paymentIntentId === "1" && jobId === null) {
      return "Receive payment in your wallet";
    } else if (paymentIntentId !== "1" && jobId === null) {
      return "Wallet Recharge";
    }
  };

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const download = (data) => {
    userAPI
      .downloadInvoice(data)
      .then((res) => {
        console.log("response", res.data.body);
        if (res.data.success) {
          setInvoice(res.data.body.data);
          // window.open(
          //   `${baseURLs.yemo}/uploads/pdfs/${res.data.body.data}`,
          //   "_blank"
          // );

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
  
      const value = event.target.value;
      console.log('Selected rows per page:', value);
    
      setRowsPerPage(value === 'all' ? filteredTransactions.length : parseInt(value, 10));
      setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTransactions = transactions.filter((transaction) =>
    getTransactionName(transaction)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  console.log(searchTerm);
  const sortedTransactions = filteredTransactions.sort(
    (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
  );

  const paginatedTransactions = sortedTransactions.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );
  console.log(paginatedTransactions);
useEffect(()=>{
  if(invoice.name){
    handlePrint();
    setInvoice({});
  }
},[invoice])

  return (
    <div style={{  display: "flex", flexDirection: "column", height: "calc(100vh - 142px)"  }}>
      <div 
      style={{display:"none"}}
      >
      <div ref={invoiceRef}>
      {
        invoice.jobName === "Wallet Recharge" ? <WalletInvoice data={invoice} />: <JobInvoice data={invoice} />
      }
        </div>
      </div>
      
      <Box sx={{ padding: "0px 27px", zIndex: 1  }}>
        <TextField
          // label="Search"
          variant="standard"
          margin="normal"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
                &nbsp;
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ flex: 1, overflowY: "auto" }}>
      <Table>
        <TableHead sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
          <TableRow>
            <TableCell
              sx={{
                padding: "7px",
                paddingLeft: "22px",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              Index
            </TableCell>
            <TableCell
              sx={{ padding: "7px", alignItems: "center", fontWeight: "bold" }}
            >
              Project Name
            </TableCell>
            <TableCell
              sx={{ padding: "7px", alignItems: "center", fontWeight: "bold" }}
            >
              Date
            </TableCell>
            <TableCell
              sx={{
                padding: "7px",
                alignItems: "center",
                paddingBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Amount
            </TableCell>
            <TableCell
              sx={{
                padding: "7px",
                alignItems: "center",
                paddingBottom: "10px",
                fontWeight: "bold",
                paddingLeft:"1%"
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                padding: "7px",
                alignItems: "center",
                paddingBottom: "10px",
                fontWeight: "bold",
                paddingLeft: "1%",
              }}
            >
              In-Voice
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedTransactions.map((transaction, i) => (
            <TableRow key={transaction.id}>
              <TableCell
                sx={{
                  padding: "7px",
                  paddingLeft: "22px",
                  alignItems: "center",
                }}
              >
                &nbsp;{i + 1}
              </TableCell>
              <TableCell sx={{ padding: "7px", alignItems: "center" }}>
                {getTransactionName(transaction)}
              </TableCell>
              <TableCell sx={{ padding: "7px", alignItems: "center" }}>
                <Typography>
                  {moment(transaction.createdAt).format("D MMMM YYYY")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "7px",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                <Typography>$ {transaction.price}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  padding: "7px",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                <Chip
                label={transaction.status}
                  sx={{ display: "inline" }}
                  className={`t-${transaction.status}`}
                  component="span"
                >
                  {transaction.status}  
                </Chip>
              </TableCell>
              <TableCell
                sx={{
                  padding: "7px",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                {transaction.status === "success" ? (
                  <Button
                    variant="contained"
                    className="LoginButton"
                    onClick={() => {
                      download(transaction);
                    }}
                  >
                    Print
                  </Button>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box>
      <TablePagination
        rowsPerPageOptions={[12, 20, 24,'all']}
        component="div"
        count={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
   
  );
}
