import {
  Box,
  Button,
  Checkbox,
  Rating,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Profile from "../../Assets/user-default-icon.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { useSharedState } from "src/utils/SharedStateProvider";
import { socket } from "./Message";
import { supervisorApi } from "src/services/api";
import { toast } from "react-toastify";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";

export default function UserSelecet() {
  const [user, setUser] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { setUserId, setSuperviserAccept, setAcceptReject } =
    useJobSharedState();
  const { jobId, setAcceptInvite , setGetJobs, setJobId} = useSharedState();
  const navigate = useNavigate()

  const handleCheckboxChange = (userId) => {
    setSelectedUserId((prevSelectedUserId) =>
      prevSelectedUserId === userId ? null : userId
    );
    setUserId((prevSelectedUserId) =>
      prevSelectedUserId === userId ? null : userId
    );
  };
  useEffect(() => {
    supervisorApi
      .getSellers()
      .then((res) => {
        //console.log(res.data);
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  }, []);
  const handelBackButton = (userId) => {
    setUserId((prevSelectedUserId) =>
      prevSelectedUserId === userId ? null : userId
    );
  };
  const handelAssignJob = async (user) => {
    const data = {
      userId: user.id,
      jobId,
    };
    await supervisorApi
      .addSellerToJob(data)
      .then((res) => {
        if (res.data.success === true) {
          setSuperviserAccept(false);
          const messageData = {
            jobId: jobId,
            message: `${user.name} accepted your contract!!`,
          };
          socket.emit("yemoMessage", { messageData });
          socket.emit("rightSideBar", messageData);
          setJobId(null);
          navigate('/dashboard')
        }
         setAcceptReject(true);
         setAcceptInvite(true);
         setGetJobs(true);
         
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  return (
    <Box sx={{ overflowY: "scroll", backgroundColor: "white" }}>
      <Table>
        {user.map((user) => (
          <TableRow key={user.id}>
            <TableCell
              sx={{
                padding: "8px",
                paddingRight: "0px",
                textAlign: "left",
                width: "5%",
              }}
            >
              <Checkbox
                checked={selectedUserId === user.id}
                onChange={() => handleCheckboxChange(user.id)}
              />
            </TableCell>
            <TableCell
              sx={{
                padding: "8px",
                paddingRight: "0px",
                textAlign: "left",
                width: "3%",
              }}
            >
              <img
                src={Profile}
                alt="aiprofile"
                height="50px"
                width="50px"
                style={{ borderRadius: "50%" }}
              />
            </TableCell>
            <TableCell sx={{ textAlign: "left" }}>
              <Typography style={{ fontSize: "18px", fontWeight: "650" }}>
                {user.name}
              </Typography>
              <Typography>
                {user.skills.map((skills, i) => (
                  <React.Fragment key={skills}>
                    {skills.name}&nbsp;
                    {i !== user.skills.length - 1 && "|"}&nbsp;
                  </React.Fragment>
                ))}
              </Typography>
              <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="text-feedback"
              value={user.avgFeedback}
              readOnly
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Box sx={{ ml: 2 }}>
              {user.avgFeedback ? user.avgFeedback : 0} Ratings (
              {user.distinctReviews} Reviews)
            </Box>
          </Box>
            </TableCell>
            <TableCell
              sx={{
                padding: "8px",
                textAlign: "right",
                paddingBottom: "10px",
              }}
            >
              {selectedUserId === user.id ? (
                <Button
                  variant="contained"
                  className="LoginButton"
                  onClick={() => {
                    handelAssignJob(user);
                  }}
                  sx={{ marginRight: "6%" }}
                >
                  Assign Job
                </Button>
              ) : (
                <Button
                  variant="text"
                  onClick={() => {
                    handelBackButton(user.id);
                  }}
                >
                  <ChevronRightIcon color="action" />
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Box>
  );
}
