/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  FormHelperText,
} from "@mui/material";
import "../../style/style.css";
import TextField from "@mui/material/TextField";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./ContactForm.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import contactUsSchema from "src/validationSchema/contactUsSchema";
import { useSharedState } from "src/utils/SharedStateProvider";
import { jobAPI } from "src/services/jobApis";
import MobileProfileBackBtn from "../profile/MobileProfileBackBtn";

const ContactForm = () => {
  const { setLoading } = useSharedState();
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const { setSelectedComponent } = useSharedState();
  const [feedback, setFeedback] = useState({
    selectedOption: "",
    feedback: "",
    feedbackImage: [],
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFeedback((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleTextArea = (event) => {
    const { name, value } = event.target;
    if (value.length <= 100) {
      setFeedback((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const getFeedBackTypes = () => {
    jobAPI
      .getFeedbackTypes()
      .then((res) => {
        console.log();
        setOptions(res.data.data);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  useEffect(() => {
    getFeedBackTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    // const file = event.target.files[0];
    if (files.length > 0) {
      setFeedback((prevFormData) => ({
        ...prevFormData,
        feedbackImage: files,
      }));
    } else {
      setFeedback((prevFormData) => ({
        ...prevFormData,
        feedbackImage: null,
      }));
    }
  };
  const validateForm = () => {
    const { error } = contactUsSchema.validate(feedback, {
      abortEarly: false,
    });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };
  const submitFeedback = (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm()) {
      jobAPI
        .getPlatFormFeedBack(feedback)
        .then((res) => {
          toast.success(res.data.message);
          setFeedback({
            feedback: "",
            selectedOption: "",
            feedbackImage: [],
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setSelectedComponent("Support");
  }, []);
  const characterCount = feedback.feedback.length;
  return (
    <>
      <Box className="profilepage">
        <MobileProfileBackBtn />
        {/* <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" }}>
          <Link to="..">
            <Button sx={{ minWidth: "40px", height: "40px" }}>
              <ChevronLeftIcon />
            </Button>
          </Link>
          Contact Us
        </Box> */}
        <Divider />
        <Box className="contactUsForm">
          <Typography variant="body2">
            Email us at{" "}
            <a href="mailto:legal@thegatewaycorp.com">support@yemo.ai</a>
          </Typography>

          <Typography variant="body1">Give us a Feedback </Typography>
          <form onSubmit={submitFeedback}>
            <Box>
              <FormControl fullWidth className="selectList">
                <InputLabel id="demo-simple-select-label">
                  Select feedback topic*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="selectedOption"
                  label="Select feedback topic*"
                  onChange={handleChange}
                  value={feedback.selectedOption}
                  error={!!errors.selectedOption}
                  // anchorEl={anchorEl}
                  style={{ maxWidth: "610px" }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      className="customSelectBox"
                      value={option.id.toString()}
                    >
                      {option.type}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.selectedOption}</FormHelperText>
              </FormControl>

              <TextField
                id="outlined-multiline-static"
                label="Anything else you’d like to share?*"
                multiline
                rows={4}
                name="feedback"
                value={feedback.feedback}
                onChange={handleTextArea}
                error={!!errors.feedback}
                helperText={errors.feedback ?? `(${characterCount}/100)`}
                placeholder="I have feedback on..."
              />
              <label
                htmlFor="formFileMultiple"
                style={{ display: "contents", cursor: "pointer" }}
              >
                <Box
                  className="UploadCnt"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <UploadFileIcon />
                  Click to upload or drag and drop
                  <Typography variant="body1">
                    SVG, JPG, JPEG, PNG & PDF(max. 3MB)
                  </Typography>
                  <input
                    type="file"
                    multiple
                    id="formFileMultiple"
                    style={{ display: "none" }}
                    name="feedbackImage"
                    accept=".svg, .jpg, .jpeg, .png, .pdf"
                    onChange={handleImageUpload}
                  />
                  {feedback.feedbackImage.length > 0 && (
                    <div>
                      <p>Selected files:</p>
                      <ul>
                        {feedback.feedbackImage.map((file, index) => (
                          <div key={file}>{file.name}</div>
                        ))}
                      </ul>
                    </div>
                  )}
                  {errors.feedbackImage && (
                    <div style={{ color: "red" }}>{errors.feedbackImage}</div>
                  )}
                </Box>
              </label>
            </Box>

            <Button
              type="submit"
              variant="contained"
              className="LoginButton"
              sx={{ py: 1, px: 2 }}
            >
              Submit Feedback
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ContactForm;
