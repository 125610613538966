/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "../../style/style.css";
import "./CardSectionStyles.css";
import { paymentAPI } from "src/services/api";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./CardDetailsForm.css";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";

const CheckoutForm = () => {
  const { setLoading } = useSharedState();
  const stripe = useStripe();
  const elements = useElements();
  // const [customerId, setCustomerId] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const { setPaymentMethodId, customerId, showForm, setShowForm } =
    usePaymentState();
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    const stripeScript = document.createElement("script");
    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.async = true;
    document.body.appendChild(stripeScript);
  }, []);

  const validate = () => {
    if (
      cardHolderName === null ||
      cardHolderName === "" ||
      cardHolderName?.length < 3 ||
      cardHolderName?.length > 70
    ) {
      setErrors({
        name: "Please provide a valid name!",
      });
      return false;
    }
    if (!complete) {
      setErrors({
        card: "Please enter valid card details!",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (validate()) {
      setLoading(true);
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      // Validate card data using Stripe's createToken method
      await stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          // card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardHolderName || "System",
          },
        })
        .then(async ({ paymentMethod }) => {
          return await paymentAPI.attachPayMethodToCustomer({
            paymentMethodId: paymentMethod.id,
            customerId,
          });
        })
        .then((paymentMethod) => {
          setCardHolderName("");
          setLoading(false);
          setPaymentMethodId(paymentMethod.id);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setCardHolderName(value);
  };
  // const CARD_ELEMENT_OPTIONS = {
  //   style: {
  //     base: {
  //       color: "#32325d",
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: "antialiased",
  //       fontSize: "16px",
  //       "::placeholder": {
  //         color: "#aab7c4",
  //       },
  //       border: "1px solid",
  //     },
  //     invalid: {
  //       color: "#fa755a",
  //       iconColor: "#fa755a",
  //     },
  //   },
  // };
  const handlePaymentChange = (elementData) => {
    console.log(elementData);
    if (!elementData.complete && !elementData.error) {
      console.log("not complete", elementData.complete);
      setComplete(false);
    } else if (elementData.complete && !elementData.error) {
      console.log("complete", elementData.complete);
      setComplete(true);
    }
  };
  return (
    <>
      {/* <form onSubmit={handleSubmit}> */}
      <Box className="FormContainer">
        <Typography variant="body1">
          Enter the credit card details below and refill your credits
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Cardholder name*"
              value={cardHolderName}
              error={!!errors.name}
              helperText={errors.name}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
            />
          </Grid>
          {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}
          <CardElement onChange={handlePaymentChange} />
          {errors.card ? (
            <FormHelperText style={{ paddingLeft: "2%", color: "red" }}>
              {errors.card}
            </FormHelperText>
          ) : (
            ""
          )}
          {/* <Grid item xs={12}>
            <CardNumberElement
              options={{
                style: {
                  base: {
                    lineHeight: "40px",
                    fontSize: "16px",
                  },
                },
                placeholder: "Card number",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                  },
                },
                placeholder: "Expiration date",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CardCvcElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                  },
                },
                placeholder: "CVC / CVC2",
              }}
            />
          </Grid>
          */}
          <Grid item xs={12} style={{ paddingTop: "20px" }}>
            <Button
              disabled={!stripe}
              variant="contained"
              className="LoginButton"
              onClick={handleSubmit}
            >
              Save Card
            </Button>
            {showForm ? (
              <Button
                variant="contained"
                className="LoginButton"
                onClick={() => {
                  setShowForm(false);
                }}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
            <TextField fullWidth label="Postal Code" />
          </Grid> */}
        <Box className="PoweredBy">
          <Typography variant="p">Powered By Stripe.</Typography>
          <Typography variant="p">
            <strong>Note:</strong> No information is being stored on the
            platform.
          </Typography>
        </Box>
      </Box>

      {/* <PaymentElement /> */}
      {/* <CustomElement /> */}
      {/* <CardElement /> */}
      {/* <button disabled={!stripe}>Submit</button> */}
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
      {/* </form> */}
    </>
  );
};

export default CheckoutForm;
