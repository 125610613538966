import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, Link } from "@mui/material";
import "./LoginFooter.css";

export default function LoginFooter() {
  return (
    <Box className="LoginFooter">
      <Typography
        variant="p"
        sx={(theme) => ({
          color: "#999999",
          fontSize: "14px",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            order: "2",
            mt: 1,
            fontSize: "12px",
            flexDirection: "column",
          },
        })}
      >
        YeMo.ai 2024 © All Rights Reserved
      </Typography>
      <Box
        sx={(theme) => ({ [theme.breakpoints.down("md")]: { width: "100%" } })}
      >
        <Link
          href="/PrivacyPolicy"
          underline="none"
          target="_blank"
          sx={{ color: "#999999", fontSize: "14px", pr: 2 }}
        >
          Policies
        </Link>
        <Link
          href="/TermsAndConditions"
          underline="none"
          target="_blank"
          sx={{ color: "#999999", fontSize: "14px", pr: 2 }}
        >
          Terms
        </Link>
        <Link
          href="/ContactUs"
          underline="none"
          target="_blank"
          sx={{ color: "#999999", fontSize: "14px" }}
        >
          Contact Us
        </Link>
      </Box>
    </Box>
  );
}
