import * as React from "react";
import { Box } from "@mui/material";
import ChatHeader from "./ChatHeader";
import "./Main.css";
import Message from "./Message";
import { useJobSharedState } from "src/utils/jobStateProvider";
import SuperHeader from "./SuperHeader";
import UserSelecet from "./UserSelecet";
import { useAuth } from "src/utils/auth-context";
import { useSharedState } from "src/utils/SharedStateProvider";

export default function Chat() {
  const { superviserAccept } = useJobSharedState();
  const { showSiderBar, hideRightSideBar, showChatHeader } = useSharedState();
  // const role = parseInt(localStorage.getItem("role"));
  const { user } = useAuth();

  // if (loading) {
  //   // Handle loading state
  //   return <div>Loading...</div>;
  // }

  // // Access user data
  // console.log('User data:', user);

  return (
    <>
      <Box
        className="ChatArea"
        style={{
          width:
            hideRightSideBar && showSiderBar
              ? "100vw"
              : showSiderBar
              ? "calc(100% - 472px)"
              : hideRightSideBar
              ? "100%"
              : "calc(100% - 346px)",
        }}
      >
        {user?.roleId === 4 ? <SuperHeader /> : showChatHeader ? <ChatHeader />: ""}
        {superviserAccept ? <UserSelecet /> : <Message />}
      </Box>
    </>
  );
}
