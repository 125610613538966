/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Radio, Typography } from "@mui/material";

// import bcVisa from "../../Assets/blue-card-visa.png";
// import bcAmericanExpress from "../../Assets/blue-card-american-express.png";
// import bcDinnersclub from "../../Assets/blue-card-dinnersclub.png";
// import bcJcb from "../../Assets/blue-card-jcb.png";
// import bcMaster from "../../Assets/blue-card-master.png";
// import bcDiscover from "../../Assets/blue-card-discover.png";

import "./MyCardList.css";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { cardBrand } from "src/utils/helper";

//const label = { inputProps: { "aria-label": "Switch demo" } };

function YellowCard({ cardData, onChange, showAllCards, showCardButtons }) {
  const { setPaymentMethodId } = usePaymentState();
  const [showRadio, setShowRadio] = useState(false);
  const { id, cardName, last4, exp_month, exp_year, isDefault, index, brand } =
    cardData;
  if (isDefault) {
    setPaymentMethodId(id);
  }
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  const url = window.location.href;
  useEffect(() => {
    if (url.indexOf("dashboard/carddetails") !== -1) {
      setShowRadio(true);
    } else {
      setShowRadio(false);
    }
  }, []);
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{ maxWidth: 352, backgroundImage: `url(${cardBrand(brand, "y")})` }}
        className="Mycard"
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">XXXX XXXX XXXX {last4}</Typography>
            </Grid>
            <Grid item xs={8} className="name">
              <Typography variant="body2">Name</Typography>
              <Typography variant="body1">{cardName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography variant="body2">Expiry Date</Typography>
                  <Typography variant="body1">
                    {" "}
                    {exp_month}/{exp_year.toString().substr(-2)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">CVV</Typography>
                  <Typography variant="body1">***</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "352px",
        }}
      >
        {showRadio || showAllCards || showCardButtons ? (
          <>
            <Typography variant="h4">Card {index + 1}</Typography>
            <Radio
              checked={isDefault}
              onChange={handleChange}
              value={id}
              name="radio-buttons"
              // inputProps={{ "aria-label": "A" }}
            />
          </>
        ) : (
          ""
        )}
        {/* <Switch {...label} /> */}
      </Box>
    </Box>
  );
}

export default YellowCard;
