import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Paper, Button } from '@mui/material'
import React, { useState } from 'react'

export default function ResourceTable({handleClick}) {
    const roles = [
        "Business Domain Consultant (Internal/3rd Party)",
        "Business Analyst/ Functional Consultant",
        "Business Project Manager",
        "Technical Project Manager",
        "Project/Product Manager",
        "Salesforce Technical Lead",
        "Data Scientist/ Architect",
        "BI Engineer / Big Data Analyst",
        "Marketing & Research Analyst",
        "Business/ Marketing Content Writer",
        "Software Engineer - Microsoft .NET (MVC)",
        "Software Engineer - SharePoint",
        "Software Engineer - MS Dynamics (NAV, AX, CRM)",
        "Software Engineer - Open Source",
        "Software Engineer - Mobile (iOS, Android, Hybrid)",
        "Junior Salesforce Developer",
        "Salesforce Mid-level Developer",
        "Salesforce Architect",
        "UX Analyst/ Wireframe Engineer",
        "UI Designer",
        "Front-End (HTML) Developer",
        "Video Editor/ Designer | 2D/3D Animation Expert",
        "Database Engineer",
        "Test Engineer - Manual/Automation",
        "QA Salesforce",
        "Application Support Engineer",
        "Network/Infrastructure Engineer",
        "DevOps Engineer",
        "Electronic Data Interchange (EDI) Expert",
        "Security Analyst",
        "Desktop Publishing (DTP) Expert - Multimedia (BPO) / Data Entry",
        "Offshore to Onsite",
        "Onsite - BPM/TPM",
        "Onsite - Others",
        "AI Strategist/Consultant",
        "Data Scientist",
        "Machine Learning Engineer",
        "Jr AI Developer/Engineer",
        "Data Engineer",
        "AI Ethicist/Compliance Officer",
        "UX Designer for AI",
        "AI Project Manager",
        "AI Quality Assurance (QA) Engineer",
        "Customer Support/Service Specialist",
        "AI Sales and Marketing Specialist",
        "Legal and Compliance Expert",
        "Deep Learning Engineer",
        "Data Annotator Engineer"
    ];

    const [roleResources, setRoleResources] = useState({});
    const handleInputChange = (role, value) => {
        const numericValue = Number(value);
    
        if (numericValue > 0) {
            setRoleResources(prev => ({ ...prev, [role]: numericValue }));
        } else {
            setRoleResources(prev => {
                const updatedResources = { ...prev };
                delete updatedResources[role];
                return updatedResources;
            });
        }
    };
    

    const handleSubmit = async () => {
        const result = Object.entries(roleResources)
            .filter(([role, resources]) => resources)
            .map(([role, resources]) => `${role} - ${resources}`)
            .join('\n');
        await handleClick(result)
    };


    return (
        <Box className='resourceTable'>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                <Typography className='resource-heading'>
                    Resources count
                </Typography>
                <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto", maxHeight: "80vh", mt: 2}}>
                    <Table sx={{ minWidth: 300 }} aria-label="resource table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography className='resource-typo'>Role</Typography></TableCell>
                                <TableCell><Typography className='resource-typo'>Number of Resources</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roles.map((role, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography className='resource-table-cell' sx={{ wordWrap: "break-word" }}>
                                            {role}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: "130px" }}>
                                        <TextField
                                            type="number"
                                            placeholder="Enter no."
                                            fullWidth
                                            size="small"
                                            value={roleResources[role] || ''} // Set input value from state
                                            onChange={(e) => handleInputChange(role, e.target.value)} // Update state on change
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Submit Button */}
                <Button variant="contained" className="LoginButton" onClick={async ()=>{await handleSubmit()}} sx={{ mt: 1 }}>
                    Submit
                </Button>
            </Box>
        </Box>
    )
}
