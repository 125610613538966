import React from "react";
import Bell from "../../Assets/notificationbell.png";
import "./NewNotification.css";
const NewNotification = () => {
  return (
    <div className="notification-bell">
      <img
        className="bell-img"
        src={Bell}
        alt="a icon of notification bell"
        height="115px"
        width="115px"
      />
      <h3 className="bell-text">
        We'ill let you know when we get <br /> the news for you{" "}
      </h3>
    </div>
  );
};

export default NewNotification;
