const Joi = require("joi");
const meetingSchema = Joi.object({
    title: Joi.string().required().messages({
      "any.required": "Please give a title to the meeting",
    }),
    description: Joi.string().required().messages({
      "any.required": "Please give a description for the meeting",
    }),
  });

  export default meetingSchema;