import axios from "axios";
import { baseURLs, chatRoutes, jobRoutes } from "src/constants/api.constants";
import { handleApiError } from "src/utils/apiError";

export const jobAPI = {
  createJob: async (body) =>
    handleApiError(async () => {
      const data = await axios.post(
        `${baseURLs.yemo}${jobRoutes.createJob}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return data;
    }),
  getAllJobs: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getAllJobsByUserId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getDraftJobs: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getDraftJobs}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getWaitingJobs: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getWaitingJobs}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getInProgressJobs: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getInProgressJobs}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getCompletedJobs: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getCompletedJobs}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getInvitedJobs: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getInvitedJobs}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),

  submitContract: (jobId) =>
    handleApiError(async () => {
      const submitted = await axios.put(
        `${baseURLs.yemo}${jobRoutes.submitContract}/${jobId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return submitted;
    }),
  getJobById: (jobId) =>
    handleApiError(async () => {
      const getJobById = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getJobById}/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return getJobById;
    }),
  acceptOrRejectRequest: (data) =>
    handleApiError(async () => {
      const request = await axios.put(
        `${baseURLs.yemo}${jobRoutes.acceptOrRejectRequest}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return request;
    }),

  rejectJob: (data, jobId) =>
    handleApiError(async () => {
      const reject = await axios.post(
        `${baseURLs.yemo}${jobRoutes.rejectJob}/${jobId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return reject;
    }),
  deleteJob: (jobId) =>
    handleApiError(async () => {
      const deleteJob = await axios.delete(
        `${baseURLs.yemo}${jobRoutes.deleteJob}/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return deleteJob;
    }),
  updateJob: (body, jobId) =>
    handleApiError(async () => {
      const updateJob = await axios.put(
        `${baseURLs.yemo}${jobRoutes.updateJob}/${jobId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return updateJob;
    }),

  getChatProfile: async (userId) =>
    handleApiError(async () => {
      const getChatProfile = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getChatProfile}/${userId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return getChatProfile;
    }),
  updateStatusMarkAsComplete: (jobId) =>
    handleApiError(async () => {
      const updateStatusMarkAsComplete = await axios.patch(
        `${baseURLs.yemo}${jobRoutes.updateStatusMarkAsComplete}/${jobId}`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return updateStatusMarkAsComplete;
    }),

  markJobAsComplete: (jobId, data) =>
    handleApiError(async () => {
      const markJobAsComplete = await axios.put(
        `${baseURLs.yemo}${jobRoutes.markJobAsComplete}/${jobId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return markJobAsComplete;
    }),

  leaveJob: async (rejectData, jobId) =>
    handleApiError(async () => {
      const leave = await axios.put(
        `${baseURLs.yemo}${jobRoutes.leaveJob}/${jobId}`,
        rejectData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return leave;
    }),
  rejectRequestForCompletion: (jobId) =>
    handleApiError(async () => {
      const reject = await axios.patch(
        `${baseURLs.yemo}${jobRoutes.rejectRequestForJobCompletion}/${jobId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return reject;
    }),
  confirmPayment: (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.confirmPayment}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  getFeedbackQuestions: () =>
    handleApiError(async () => {
      const feedback = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getFeedbackQuestions}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return feedback;
    }),
  addFeedback: (jobId, req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.addFeedback}/${jobId}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  getFeedback: (jobId) =>
    handleApiError(async () => {
      const feedback = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getFeedback}/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return feedback;
    }),
  getFeedbackTypes: (jobId) =>
    handleApiError(async () => {
      const feedback = await axios.get(
        `${baseURLs.yemo}${jobRoutes.getAllFeedbackTypes}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return feedback;
    }),
  getPlatFormFeedBack: (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.givePlatFormFeedback}`,
        req,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  getPlatFormFeedBackHome: (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.givePlatFormFeedbackHome}/${req.email}`,
        req,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
};

export const chatAPI = {
  getMessagesByJobId: (data) =>
    handleApiError(async () => {
      const jobMessage = await axios.post(
        `${baseURLs.yemo}${chatRoutes.getMessagesByJobId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),
  sendFiles: (body) =>
    handleApiError(async () => {
      const sendFiles = await axios.post(
        `${baseURLs.yemo}${chatRoutes.sendFiles}/${body.jobId}?Path=${body.path}`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return sendFiles;
    }),
  getFilesByJobId: (jobId) =>
    handleApiError(async () => {
      const jobMessage = await axios.post(
        `${baseURLs.yemo}${chatRoutes.getFilesByJobId}`,
        jobId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),

  getFilesAndFolderByJobId: (data) =>
    handleApiError(async () => {
      const jobMessage = await axios.post(
        `${baseURLs.yemo}${chatRoutes.getFilesAndFolderByJobId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),
  getJobFolderByUserId: () =>
    handleApiError(async () => {
      const jobMessage = await axios.get(
        `${baseURLs.yemo}${chatRoutes.getJobFolderByUserId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),
  moveFile: (data) =>
    handleApiError(async () => {
      const jobMessage = await axios.put(
        `${baseURLs.yemo}${chatRoutes.moveFile}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),

  createFolder: (jobId, data) =>
    handleApiError(async () => {
      const jobMessage = await axios.post(
        `${baseURLs.yemo}${chatRoutes.createFolder}/${jobId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),
  createmeeting: (jobId) =>
    handleApiError(async () => {
      const createmeeting = await axios.post(
        `${baseURLs.yemo}${chatRoutes.createmeeting}`,
        jobId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return createmeeting;
    }),
  scheduleMeeting: async (data) =>
    handleApiError(async () => {
      const status = await axios.post(
        `${baseURLs.yemo}/${chatRoutes.scheduleMeeting}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  updateMeetingScheduler: async (data) =>
    handleApiError(async () => {
      const status = await axios.patch(
        `${baseURLs.yemo}/${chatRoutes.updateMeetingScheduler}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  getDays: async () =>
    handleApiError(async () => {
      const user = await axios.get(`${baseURLs.yemo}${chatRoutes.getDays}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authProviderToken")}`,
        },
      });
      return user;
    }),
  getTimeSlots: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${chatRoutes.getTimeSlots}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
  createTimeSlots: async (data) =>
    handleApiError(async () => {
      const status = await axios.put(
        `${baseURLs.yemo}${chatRoutes.createTimeSlots}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  getMatchingTimeSlots: async (data) =>
    handleApiError(async () => {
      const status = await axios.post(
        `${baseURLs.yemo}/${chatRoutes.getMatchingTimeSlots}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  markAsRead: async (jobId) =>
    handleApiError(async () => {
      const read = await axios.put(
        `${baseURLs.yemo}${chatRoutes.markAsRead}/${jobId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return read;
    }),
    getUserEvents: () =>
    handleApiError(async () => {
      const jobMessage = await axios.get(
        `${baseURLs.yemo}${chatRoutes.getUserEvents}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobMessage;
    }),
};
