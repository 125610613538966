import { passwordObj } from "./loginSchema";

const Joi = require("joi");

export const forgotPasswordSchema = Joi.object({
  ...passwordObj,
  email: Joi.string(),
});

export const resetPasswordProfileSchema = Joi.object({
  oldPassword: Joi.string().required().messages({
    "string.empty": `Old Password is required`,
  }),
  newPassword: passwordObj.password,
});
