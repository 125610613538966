import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import "./globalFile.css";
// import sortIcon from "../../Assets/sort.svg";
import sortAlphabeticalIcon from "../../Assets/sort-alphabetical-variant.svg";
import foldericon from "../../Assets/Vector.svg";
import moment from "moment";
import { useSharedState } from "src/utils/SharedStateProvider";
import { chatAPI } from "src/services/jobApis";
import { toast } from "react-toastify";

const Globalfile = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { setJobName ,setLoading } = useSharedState();
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState(null);
  const [serchBar, setSerchBar] = useState(false);

  const columns = [
    { id: "Name", label: "Name" },
    { id: "jobId", label: "Job ID" },
    { id: "Date", label: "Date of Change" },
    { id: "action", label: "Action" },
  ];

  const handleRowClick = (data) => {
    setJobName(data.name);
    navigate(`/dashboard/file/jobFiles/${data.jobId}`);
  };
  const getFiles = () => {
    chatAPI
      .getJobFolderByUserId()
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    getFiles();
  }, []);
  const handleGotoJob = (data) => {
     // Open the job folder in a new tab
    window.location.href =`/dashboard/chat/${data.jobId}`;
    setLoading(true)
  };
  return (
    <Box className="JobPagepage" >
      {serchBar ? (
        <Box
          className="searchContainer"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Box className="searchIcon">
            <IconButton
              aria-label="back"
              onClick={() => {
                setSerchBar(false);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <InputBase
            placeholder="Search..."
            className="input"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ my: 2, px: 2, alignItems: "center" }}>
            <Link to="..">
              <Button sx={{ minWidth: "40px", height: "40px" }}>
                <ChevronLeftIcon sx={{ color: "black" }} />
              </Button>
            </Link>
            My Files
          </Box>
          <Box className="filterIcons">
            <Button
              onClick={() => {
                setSerchBar(true);
              }}
            >
              <SearchIcon sx={{ color: "#555" }} />
            </Button>
            {/* |
            <Button>
              <img src={sortIcon} alt="sortIcon" />
            </Button> */}
            |
            <Button
              onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            >
              <img src={sortAlphabeticalIcon} alt="sortAlphabeticalIcon" />
            </Button>
          </Box>
        </Box>
      )}

      <TableContainer className="centerTable">
        <Table>
          <TableHead>
            <TableRow className="filesTableRow">
              {columns.map((column) => (
                <TableCell key={column.id} style={{ width: column.width }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter(
                (row) =>
                  row.name.toLowerCase().includes(searchText.toLowerCase()) ||
                  row.jobUid.toLowerCase().includes(searchText.toLowerCase())
              )
              .sort((a, b) => {
                if (sortOrder === "asc") {
                  return a.name.localeCompare(b.name, undefined, {
                    sensitivity: "base",
                  });
                } else if (sortOrder === "desc") {
                  return b.name.localeCompare(a.name, undefined, {
                    sensitivity: "base",
                  });
                }
                return 0;
              })
              .map((row) => (
                <TableRow
                 className="filesTableRow"
                  key={row.id}
                  onClick={() => {
                    handleRowClick(row);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={foldericon}
                        alt="foldericon"
                        style={{ paddingRight: "15px" }}
                      />
                      {row.name}
                    </Box>
                  </TableCell>
                  <TableCell>{row.jobUid}</TableCell>
                  <TableCell>
                    {moment(row.lastUpdateTime).format("DD-MM-YYYY")}|
                    {moment(row.lastUpdateTime).format("h:mmA")}
                  </TableCell>
                  <TableCell  >
                    <Link to={`/dashboard/chat/${row.id}`} onClick={() => {handleGotoJob(row)}}>Go To Job</Link>
                  </TableCell>
                </TableRow>
              ))}

            {data.filter(
              (row) =>
                row.name.toLowerCase().includes(searchText.toLowerCase()) ||
                row.jobUid.toLowerCase().includes(searchText.toLowerCase())
            ).length === 0 && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Globalfile;
