import React, { createContext, useContext, useState } from "react";

const PaymentStateContext = createContext();

export const PaymentStateProvider = ({ children }) => {
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [nullCards, setNullCards] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("wallet");
  const [showRechargeWallet, setShowRechargeWallet] = useState(false);
  const [showForm, setShowForm] = useState(false);
  return (
    <PaymentStateContext.Provider
      value={{
        paymentMethodId,
        setPaymentMethodId,
        customerId,
        setCustomerId,
        nullCards,
        setNullCards,
        selectedPayment,
        setSelectedPayment,
        showRechargeWallet,
        setShowRechargeWallet,
        showForm, 
        setShowForm,
      }}
    >
      {children}
    </PaymentStateContext.Provider>
  );
};

export const usePaymentState = () => useContext(PaymentStateContext);
