export const baseURLs = {
//  ip: "http://localhost:3000",
//  yemo: `http://localhost:3000/v1`,
   ip: "https://api.yemo.ai",
   yemo:`https://api.yemo.ai/v1`,
};

export const stripePublishableKey =
  "pk_test_51NSI09SEhtAAns2S7eexIOWmaXyJbZKsNLevklcxG7CcPYI0RDyxVcHcDLOHYuJeoJAolnOYQ0T5qbXiLMKlcTFr00b5KkReTN";
//"pk_test_51NYOkySFIOqczIMjBwt9CeLRE4jKSvUeqjsTnk4rLKpTssWgmZDcd7rFPzkHbGlG61z98ffsx3PkZrNJJRpHmofC00zzyjhBKA";

export const userRoutes = {
  getUser: "/users/getusers",
  getUserById: "/users/getUserById",
  createUser: "/users/createUser",
  logIn: "/users/login",
  forgotPassword: "/users/forgotPassword",
  resetPassword: "/users/resetPassword",
  resetPasswordProfile: "/users/resetPasswordProfile",
  getAllStatus: "/users/getAllStatuses",
  verification: "/users/verification",
  updateTwoFactor: "/users/updateTwoFactor",
  resendOTP: "/users/resendOtp",
  getProfile: "/users/getProfile",
  verifyOtpResetPassword: "/users/verifyOtpResetPassword",
  updateProfile: "/users/updateProfile",
  updateUserStatus: "/users/updateUserStatus",
  downloadInvoice: "/users/getInvoice",
  getNotifications: "/users/getNotification",
  markAsReadNotifications: "/users/markAsReadNotification",
  markAsReadNotificationById: "/users/markAsReadNotificationById",
  getTransactions: "/users/getUserTransactions",
  decativateUser: "/users/decativateUser",
  deleteUser: "/users/deleteUser",
  getAllSkills: "/users/getAllSkills",
  updateUserSkills: "/users/updateUserSkills",
  updateTour:"/users/updateTour",
};

export const chatRoutes = {
  sendFiles: "/chat/sendFiles",
  getMessagesByJobId: "/chat/getMessagesByJobId",
  getFilesByJobId: "/chat/getFilesByJobId",
  markAsRead: "/chat/markAsReadMessages",

  createmeeting: "/chat/createmeeting",
  getDays: "/chat/getDays",
  getTimeSlots: "/chat/getTimeSlots",
  createTimeSlots: "/chat/createTimeSlots",
  getMatchingTimeSlots: "/chat/getMatchingTimeSlots",
  scheduleMeeting: "/chat/scheduleMeeting",
  updateMeetingScheduler: "/chat/updateMeetingScheduler",
  getUserEvents:"/chat/getUsersEvents",
  
  createFolder: "/chat/createFolder",
  getFilesAndFolderByJobId: "/chat/getFilesAndFolderByJobId",
  moveFile: "/chat/moveFile",
  getJobFolderByUserId: "/chat/getJobFolderByUserId",
};

export const jobRoutes = {
  createJob: "/job/createJob",
  updateJob: "/job/updateJob",
  getAllJobsByUserId: "/job/getAllJobsByUserId",
  getDraftJobs: "/job/getDraftJobsByUserId",
  getWaitingJobs: "/job/getWaitingJobsByUserId",
  getInProgressJobs: "/job/getInProgressJobsByUserId",
  getCompletedJobs: "/job/getCompletedJobsByUserId",
  getInvitedJobs: "/job/getInvitedJobs",
  submitContract: "/job/submitContract",
  getJobById: "/job/getJobById",
  rejectJob: "/job/rejectJob",
  deleteJob: "/job/deleteJob",
  updateStatusMarkAsComplete: "/job/updateStatusMarkAsComplete",
  markJobAsComplete: "/job/markJobAsComplete",
  rejectRequestForJobCompletion: "/job/rejectRequestJobCompletion",
  leaveJob: "/job/leaveJob",
  getChatProfile: "/job/getChatProfile",

  acceptOrRejectRequest: "/job/acceptOrRejectRequest",
  getPrice: "/job/getPrice",
  getPriceTab:"/job/getPriceTab",
  confirmPayment: "/job/confirmPayment",
  createTransaction: "/job/createTransaction",
  updateWallet: "/job/updateWallet",
  payWithWallet: "/job/payWithWallet",

  getFeedbackQuestions: "/job/getFeedbackQuestions",
  addFeedback: "/job/createFeedback",
  getFeedback: "/job/getFeedback",

  getAllFeedbackTypes: "/job/getAllFeedBackTypes",
  givePlatFormFeedback: "/job/givePlatFormFeedback",
  givePlatFormFeedbackHome: "/job/givePlatFormFeedbackHome",
};

export const stripeRoutes = {
  getPaySKey: "/stripe/secret",
  getCustomerId: "/stripe/customer/getCustomerId",
  paymentMethodToCustomer: "/stripe/customer/attachPaymentMethod",
  getPaymentMethodByCustomer: "/stripe/customer/paymentmethods",
  createPaymentIntent: "/stripe/create-payment-intent",
  setDefaultCard: "/stripe/customer/setDefaultCard",
};

export const adminRoutes = {
  getCategories: "/admin/getAllCategories",
  getSubCategoriesByCategoryId: "/admin/getSubCategoriesByCategoryId",
  askQuestionYemo: "/admin/getQuestionsByJob",
  getStaticData: "/admin/getStaticData",
};

export const googleRoutes = {
  getGoogleToken: "/auth/getGoogleToken",
  createCalenderEvent: "/auth/createCalenderEvent",
  getCalenderEvents: "/auth/getCalendarEvents",
  getMicrosoftToken: "/auth/getMicrosoftToken",
  getMicrosoftEvents: "/auth/getMicrosoftEvents",
  //  updateCalenderEvent:"/auth/updateCalenderEvent",
};

export const supervisorRoutes = {
  getsuperviserJob: "/supervisor/getAllSupervisorJob",
  getAllWatingJob: "/supervisor/getWaitingSupervisorJob",
  getAllConflictJob: "/supervisor/getConflictSupervisorJob",
  getAllCompletedJob: "/supervisor/getCompletedSupervisorJob",
  getAllSellers: "/supervisor/getSellers",
  addSellerToJob: "/supervisor/addSellerToJob",
};

export const idleTimeInMinute = 80;
