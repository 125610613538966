import * as React from "react";
import "./Error.css";
import errorImage from "../../Assets/image-404.png";
const Error = () => {
  return (
    <>
      <div className="main-container">
        <div className="sub-container-1">
          <img
            src={errorImage}
            alt="showing error 404"
            height="242px"
            width="409px"
          />
        </div>
        <div className="sub-container-2">It looks like you’re lost!</div>
        <div className="sub-container-3">
          We can’t find the page that you are looking for
        </div>
        <a href="/">
          <button className="sub-container-4">BACK TO HOMEPAGE</button>
        </a>
      </div>
    </>
  );
};

export default Error;
