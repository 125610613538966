/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import SendIcon from "@mui/icons-material/Send";
import EmojiPicker from "emoji-picker-react";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { toast } from "react-toastify";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchedulePopUp from "./SchedulePopUp";
import { chatAPI } from "src/services/jobApis";
import debounce from "lodash/debounce";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const buyerOptions = [
  { id: 1, label: "Create A Meeting", path: "create", icon: <CallIcon /> },
  {
    id: 2,
    label: "Schedule A Meeting",
    path: "schedule",
    icon: <CalendarMonthIcon />,
  },
];

export default function ChatFooter({
  displayOrder,
  questionId,
  disabled,
  setDisabled,
  socket,
  job,
  setChatLoading,
  setIsTyping,
  lastMessage,
  setOneMessageChatloading,
  chat,
  setChat,
  setQuestionLoading,
  clickSend, setClickSend
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [showEmojis, setShowEmojis] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
 
  const [userId, setUserId] = useState();
  const LoginUser = parseInt(localStorage.getItem("userId"));
  const { jobId, setUpdateName, chatLoading, setHideRightSideBar } =
    useSharedState();
  const role = parseInt(localStorage.getItem("role"));
  const emojiButtonRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const mobileEmojiBtnRef = useRef(null);
  const mobileMenuContainerRef = useRef(null);
  const { joinButtonClicked, setJoinButtonClicked, userJob } =
    useJobSharedState();
  const fileInputRef = useRef(null);
  const mobileFileInputRef = useRef(null);
  const searchInputRef = useRef(null);
  const displayOrderValuesToCheck = [2, 3, 4, 8];
  const { jobStatus } = job;
  // let typingTimeout;
  //   useEffect(() => {
  //     if (jobStatus === "inprogress") {
  //       setreloed(true);
  //     } else {
  //       // Reset the reloed state to false when jobStatus changes to something other than "inprogress"
  //       setreloed(false);
  //     }
  //   }, [jobStatus]);
  // useEffect(()=>{
  //   if(reloed)
  //   {
  //   console.log("i am reloed");
  //   }
  // },[reloed])

  useEffect(() => {
    // console.log(
    //   "price",
    //   job.price,
    //   questionId,
    //   typeof questionId,
    //   displayOrder,
    //   typeof displayOrder
    // );
    if (job.price) {
      setHideRightSideBar(false);
    }
  }, [questionId, job, displayOrder]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClick = (event) => {
    if (mobileAnchorEl) {
      setMobileAnchorEl(null);
      return false;
    }

    setMobileAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const mobileOpen = Boolean(mobileAnchorEl);
  const ITEM_HEIGHT = 48;
  const validate = () => {
    return !(
      (displayOrderValuesToCheck.includes(parseInt(questionId)) &&
        jobStatus === "pending") ||
      jobStatus === "waiting" ||
      // eslint-disable-next-line no-mixed-operators
      jobStatus === "conflict" ||
      jobStatus === "refund" ||
      jobStatus === "completed" ||
      ((chat === null || (typeof chat === "string" && chat.trim() === "")) &&
        selectedFile.length === 0)
    );
  };
  const handelsendMeeting = () => {
    chatAPI
      .createmeeting({ jobId: jobId })
      .then((res) => {
        const data = res.data.message;
        socket.emit("sendMessage", { messageData: data });
        setJoinButtonClicked(true);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const handleFileChange = (e) => {
    setClickSend(true);
    if (role === 4 || job.jobStatus === "inprogress") {
      const maxSize = 100 * 1024 * 1024; // 100MB in bytes
      const files = Array.from(e.target.files);
      const validFiles = files.filter((file) => file.size <= maxSize);
      if (validFiles.length !== files.length) {
        toast.error(
          "Some files exceed the 100MB limit. They will not be uploaded."
        );
        e.target.value = null;
        fileInputRef.current = null;
        mobileFileInputRef.current = null;
      }
      setSelectedFile(validFiles);
    }
  };

  const handleSubmit = async (e) => {
    console.log("i am call");
    e.preventDefault();
    console.log(validate(), clickSend);
    if ((validate() || role === 4) && clickSend) {
      if (!disabled) {
        setClickSend(false);
        if (
          job.jobStatus === "pending" &&
          parseInt(job.id) === parseInt(jobId)
        ) {
          const messageData = {
            data: { displayOrder: displayOrder, reply: chat },
            jobId,
          };
          console.log("messageDaata", messageData);
          await socket.emit("sendMessage", { messageData });
          setIsTyping(true);
          setOneMessageChatloading(true);
          setQuestionLoading(true);
          await socket.emit("notificationCouter");
          if (questionId === 1) {
            setUpdateName(true);
          }
        } else if (selectedFile.length > 0) {
          const payload = {
            files: selectedFile,
            path: jobId,
            jobId,
          };
          chatAPI
            .sendFiles(payload)
            .then((res) => {
              const messageData = res.data.data;
              messageData.forEach(async (element) => {
                await socket.emit("sendMessage", { messageData: element });
                socket.emit("notificationCouter");
                setOneMessageChatloading(true);
              });
              setSelectedFile([]);
              fileInputRef.current = null;
              mobileFileInputRef.current = null;
            })
            .catch((err) => {
              console.log(err);
              fileInputRef.current = null;
              mobileFileInputRef.current = null;
              toast.error("Something went wrong!!");
            });
        } else {
          const messageData = {
            jobId,
            content: chat,
          };
          if (chat !== null && chat.trim() !== "") {
            await socket.emit("sendMessage", { messageData });
            socket.emit("notificationCouter");
            setOneMessageChatloading(true);
          }
        }
        setChat("");
        // setIsChanged(true);
        // setChatLoading(true);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      // Add a new line in the text area
      e.preventDefault(); // Prevent the default behavior (newline)
      const { selectionStart, selectionEnd } = e.target;
      const textBefore = chat.substring(0, selectionStart);
      const textAfter = chat.substring(selectionEnd);
      const newText = `${textBefore}\n${textAfter}`;
      console.log(newText);
      handleTextChange({ target: { value: newText } });
    } else if (e.key === "Enter") {
      console.log("i am there ");
      handleSubmit(e);
    }
  };

  const handleEmoji = () => {
    setClickSend(true);
    // handleMobileMenuClose();
    setShowEmojis(!showEmojis);
    console.log("emoji", showEmojis);
  };

  function convertToNaturalNumber(input) {
    const numberMatch = input.toString().match(/(-?\d+)/);
    if (numberMatch) {
      let result = parseInt(numberMatch[0], 10);
      result = Math.abs(result);
      if (result >= 0) {
        return result;
      }
    }
    return NaN;
  }
  useEffect(() => {
    if (Array.isArray(userJob.users)) {
      for (const userList of userJob.users) {
        if (userList.user.id !== LoginUser) {
          setUserId(userList.user.id);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userJob, jobId]);
  const startTyping = () => {
    socket.emit("typing", jobId, true, userId); // Send a typing event with `true`
  };
  const stopTyping = () => {
    socket.emit("typing", jobId, false, userId); // Send a typing event with `false`
  };
  const debouncedStopTyping = debounce(() => {
    stopTyping();
  }, 500);
  const handleTextChange = (e) => {
    setClickSend(true);
    startTyping();
    const newText = e.target.value;
    if (newText.includes("\n")) {
      console.log("handel change", newText);
      // If newline characters are present, set the text without modifications
      setChat(newText);
    } else {
      if (displayOrder === 7) {
        if (newText > 0 && newText < 1000) {
          const sanitizedValue = convertToNaturalNumber(newText);
          if (sanitizedValue != chat) {
            setChat(sanitizedValue);
          }
        } else {
          setChat("");
        }
        debouncedStopTyping();
      } else {
        if (newText !== chat) {
          setChat(newText);
        }
      }
      debouncedStopTyping();
    }
  };
  // Function to handle emoji clicks
  const handleEmojiChat = (emojiData) => {
    setClickSend(true);
    const emoji = emojiData.emoji;
    setChat(chat + emoji);
  };

  const handleClickOutside = (event) => {
    if (
      emojiButtonRef.current &&
      !emojiButtonRef.current.contains(event.target) &&
      !emojiPickerRef.current.contains(event.target) &&
      mobileEmojiBtnRef.current &&
      !mobileEmojiBtnRef.current.contains(event.target) &&
      !mobileEmojiBtnRef.current.contains(event.target)
    ) {
      // console.log("here");
      // Clicked outside the button, so close the emoji picker
      setShowEmojis(false);
    }
    if (
      mobileMenuContainerRef.current &&
      !mobileMenuContainerRef.current.contains(event.target) &&
      !mobileMenuContainerRef.current.contains(event.target)
    ) {
      setMobileAnchorEl(null);
    }
  };
  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setChat("");
    setSelectedFile([]);
  }, [jobId]);

  const handleMenuItemClick = (path) => {
    if (path === "create") {
      handelsendMeeting();
    } else {
      setPopUpVisible(true);
    }
    handleClose();
  };
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = e.dataTransfer.files;
    handleFileDrop(droppedFiles);
  };

  const handleFileDrop = (droppedFiles) => {
    setClickSend(true);
    setSelectedFile(droppedFiles);
  };

  const handleEmojiPickerHover = (event) => {
    // Check if the hovered element is the search input
    if (event.target === searchInputRef.current) {
      event.preventDefault();
    }
  };
  
  const handleFileInputKeyDown = (e) => {
    console.log("i am ther outside");
    if (e.key === "Enter") {
      console.log("i am ther");
      setClickSend(true)
    }
  };

  
  return (
    <>
      {popUpVisible ? (
        <SchedulePopUp
          popUpVisible={popUpVisible}
          setPopUpVisible={setPopUpVisible}
        />
      ) : (
        ""
      )}
      <Box
        className="chatfooter"
        sx={(theme) => ({ [theme.breakpoints.down("md")]: { mt: 0 } })}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ fontSize: "11px", color: "#5E5F69", paddingBottom: "3px" }}
          >
            Sharing contact details is prohibited, it may block your account.
          </Typography>
        </Box>

        {selectedFile.length > 0 ? (
          <Box
            sx={{ textAlign: "center", display: "flex", flexDirection: "row" }}
          >
            <Typography
              variant="h4"
              sx={{ fontSize: "11px", color: "#5E5F69" }}
            >
              {selectedFile.length} Files Selected
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Box
          sx={{ backgroundColor: "background.default" }}
          style={{
            maxWidth: "1000px",
            width: "100%",
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <TextField
                fullWidth
                // id="chat-footer-textfield"
                autoComplete="nope"
                placeholder="Type a message"
                value={chat}
                multiline
                maxRows={6}
                // 7 temporary 5
                type={displayOrder === 5 ? "number" : "text"}
                style={{ WebkitAppearance: "none" }}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                disabled={
                  ((role === 1 || role === 2) &&
                    (job.jobStatus === "waiting" ||
                      job.jobStatus === "refund" ||
                      job.jobStatus === "completed" ||
                      job.jobStatus === "conflict" ||
                      (questionId === 8 && jobStatus === "pending") ||
                      questionId === 2 ||
                      questionId === 3 || questionId === 4)) ||
                  (role === 4 &&
                    (job.jobStatus === "waiting" ||
                      job.jobStatus === "refund" ||
                      job.jobStatus === "completed"))
                }
                // sx={{ lineHeight: 1 }}
              />
              {/* MOBILE FOOTER BTNS */}
              <Box
                className={"footer_mobile_btns"}
                ref={mobileMenuContainerRef}
              >
                <Box sx={{ display: "flex", justifyItems: "space-between" }}>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    onKeyDown={handleKeyDown}
                    size="small"
                    color="primary"
                    variant="contained"
                    className="attachBtn"
                    // className="sendButton"
                    // disabled={chatLoading === true}
                  >
                    <div>
                      <SendIcon style={{ color: "#9D9EA8" }} />
                    </div>
                  </Button>
                  <IconButton
                    sx={(theme) => ({
                      minWidth: "40px",
                      height: "40px",
                      display: "none",
                      [theme.breakpoints.down("md")]: {
                        ml: "auto",
                        display: "block",
                      },
                    })}
                    aria-label="more"
                    id="long-button"
                    aria-controls={mobileOpen ? "long-menu" : undefined}
                    aria-expanded={mobileOpen ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleMobileMenuClick}
                  >
                    <MoreVertIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Box>
                {/* !MORE ICONS LIST START */}
                <Box
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #e3e3e3",
                    position: "fixed",
                    bottom: "76px",
                    right: "0.5em",
                    borderRadius: "10px 10px 0 0",
                    display: mobileAnchorEl ? "block" : "none",
                  }}
                >
                  <Box>
                    {selectedFile?.length === 0 ? (
                      <label
                        onClick={() => {
                          if (
                            !(
                              ((role === 1 || role === 2) &&
                                job.jobStatus !== "inprogress") ||
                              (role === 4 && job.jobStatus !== "conflict")
                            )
                          ) {
                            handleMobileMenuClose();
                          }
                        }}
                        htmlFor="formFileMultiple"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1em",
                          padding: "0.2em 0.8em",
                          borderBottom: "1px solid #e3e3e3",
                          opacity:
                            (role === 1 || role === 2) &&
                            job.jobStatus !== "inprogress"
                              ? "0.5"
                              : "1",
                        }}
                      >
                        <span>
                          <AttachFileIcon />
                        </span>
                        <p>Attach File</p>
                      </label>
                    ) : (
                      <button
                        style={{
                          alignItems: "center",
                          gap: "1em",
                          display: "flex",
                          width: "100%",
                          padding: "5px 5px 5px 20px",
                          borderBottom: "1px solid #e3e3e3",
                          opacity:
                            (role === 1 || role === 2) &&
                            job.jobStatus !== "inprogress"
                              ? "0.5"
                              : "1",
                        }}
                          onClick={() => {
                            setSelectedFile([]);
                          }}
                          onKeyDown={handleFileInputKeyDown}
                        >
                          <span>
                            <CancelIcon />
                          </span>
                          <p>Attach File</p>
                        </button>
                      
                    )}
                    <input
                      type="file"
                      ref={mobileFileInputRef}
                      id="formFileMultiple"
                      style={{ display: "none" }}
                      disabled={
                        ((role === 1 || role === 2) &&
                          job.jobStatus !== "inprogress") ||
                        (role == 4 && job.jobStatus !== "conflict")
                      }
                      multiple
                      accept=".txt, .pdf, .doc, .docx, .odt, .rtf, .xls, .xlsx, .csv, .ppt, .pptx, .odp, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .mp3, .wav, .aac, .flac, .mp4, .avi, .mkv, .mov, .wmv, .zip, .rar, .7z"
                      name="file"
                      onChange={handleFileChange}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    
                    
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                      padding: "0.2em 0.8em",
                      borderBottom: "1px solid #e3e3e3",
                    }}
                    ref={mobileEmojiBtnRef}
                    onClick={(e) => {
                      handleEmoji(e);
                      handleMobileMenuClose();
                    }}
                  >
                    <button>
                      <SentimentSatisfiedAltIcon />
                    </button>
                    <p>Emojis</p>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                      padding: "0.2em 0.8em",
                      borderBottom: "1px solid #e3e3e3",
                      opacity:
                        role === 4 ||
                        job.jobStatus !== "inprogress" ||
                        joinButtonClicked ||
                        lastMessage === "link"
                          ? "0.5"
                          : "1",
                    }}
                    onClick={(e) => {
                      if (
                        role === 4 ||
                        job.jobStatus !== "inprogress" ||
                        joinButtonClicked ||
                        lastMessage === "link"
                      ) {
                        return false;
                      }
                      handleMobileMenuClose();
                      handelsendMeeting();
                    }}
                  >
                    <button>
                      <CallIcon />
                    </button>
                    <p>Create A Meeting</p>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                      padding: "0.2em 0.8em",
                      borderBottom: "1px solid #e3e3e3",
                      opacity:
                        role === 4 ||
                        job.jobStatus !== "inprogress" ||
                        joinButtonClicked ||
                        lastMessage === "link"
                          ? "0.5"
                          : "1",
                    }}
                    onClick={() => {
                      if (
                        role === 4 ||
                        job.jobStatus !== "inprogress" ||
                        joinButtonClicked ||
                        lastMessage === "link"
                      ) {
                        return false;
                      }
                      handleMobileMenuClose();
                      setPopUpVisible(true);
                    }}
                  >
                    <button>
                      <CalendarMonthIcon />
                    </button>
                    <p>Schedule A Meeting</p>
                  </Box>
                </Box>
                {/* !MORE ICONS LIST ENDS */}
              </Box>

              {/* !DESKTOP FOOTER BTNS */}

              <Box
                style={{
                  display: "flex",
                  width: "200px",
                  gap: "8px",
                  marginLeft: "8px",
                }}
                className="footer_desktop_btns"
              >
                <button
                  disabled={
                    ((role === 1 || role === 2) &&
                      job.jobStatus !== "inprogress") ||
                    (role === 4 && job.jobStatus !== "conflict")
                  }
                  className="attachBtn"
                  style={{
                    transform: "rotate(45deg)",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {selectedFile?.length === 0 ? (
                    <label htmlFor="formFileMultiple">
                      <AttachFileIcon style={{ color: "#9D9EA8" }} />
                    </label>
                  ) : (
                    <button
                      onClick={() => {
                        setSelectedFile([]);
                      }}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <CancelIcon
                        style={{ color: "#9D9EA8", rotate: "45deg" }}
                      />
                    </button>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    id="formFileMultiple"
                    style={{ display: "none" }}
                    disabled={
                      ((role === 1 || role === 2) &&
                        job.jobStatus !== "inprogress") ||
                      (role === 4 && job.jobStatus !== "conflict")
                    }
                    multiple
                    accept=".txt, .pdf, .doc, .docx, .odt, .rtf, .xls, .xlsx, .csv, .ppt, .pptx, .odp, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .mp3, .wav, .aac, .flac, .mp4, .avi, .mkv, .mov, .wmv, .zip, .rar, .7z"
                    name="file"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onKeyDown={handleFileInputKeyDown} 
                  />
                </button>
                <button
                  ref={emojiButtonRef}
                  disabled={
                    ((role === 1 || role === 2) &&
                      (job.jobStatus === "refund" ||
                        job.jobStatus === "conflict" ||
                        job.jobStatus === "completed" ||
                        job.jobStatus === "waiting" ||
                        displayOrder === 8)) ||
                    (role === 4 &&
                      (job.jobStatus === "refund" ||
                        job.jobStatus === "completed" ||
                        job.jobStatus === "waiting"))
                  }
                  className="attachBtn"
                >
                  <div>
                    <SentimentSatisfiedAltIcon
                      style={{ color: "#9D9EA8" }}
                      onClick={(e) => {
                        handleEmoji();
                      }}
                    />
                  </div>
                </button>

                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  disabled={
                    role === 4 ||
                    job.jobStatus !== "inprogress" ||
                    joinButtonClicked ||
                    lastMessage === "link"
                  }
                  style={{
                    backgroundColor: "#EFF1F6",
                    height: "40px",
                    width: "40px",
                  }}
                  hidden={
                    job.jobStatus !== "inprogress" ||
                    joinButtonClicked ||
                    lastMessage === "link"
                  }
                  // className="callIcon"
                  // sx={{ marginLeft: "280%" }}
                >
                  <CallIcon style={{ color: "#9D9EA8" }} />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  className="call-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "15ch",
                    },
                  }}
                >
                  {buyerOptions.map((option) => (
                    <MenuItem
                      className="customMenuu"
                      key={option.id}
                      onClick={() => handleMenuItemClick(option.path)}
                    >
                      {option.icon}&nbsp;&nbsp;{option.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  size="small"
                  color="primary"
                  variant="contained"
                  className="attachBtn"
                  // className="sendButton"
                  disabled={chatLoading === true}
                >
                  <div>
                    <SendIcon style={{ color: "#9D9EA8" }} />
                  </div>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div
          ref={emojiPickerRef}
          onMouseOver={handleEmojiPickerHover}
          style={{ width: "100%", marginTop: "1em" }}
        >
          {showEmojis ? (
            <EmojiPicker
              width="100%"
              height="450px"
              emojiStyle="native"
              lazyLoadEmojis={true}
              onEmojiClick={(emojiData) => handleEmojiChat(emojiData)}
            />
          ) : (
            ""
          )}
        </div>
      </Box>
    </>
  );
}
