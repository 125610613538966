/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Avatar, Badge, Box, Button, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Profile from "../../Assets/user-default-icon.png";
import DotMenu from "../../components/Menu/Menu";
import { useSharedState } from "src/utils/SharedStateProvider";
import { Link } from "react-router-dom";
import UserStatus from "./UserStatus";
import dnd from "../../Assets/dnd.png";
import vacation from "../../Assets/vacation.png";
import working from "../../Assets/working.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { baseURLs } from "src/constants/api.constants";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { userAPI } from "src/services/userApis";
// import storageIcon from "../../Assets/storage.png";
// import storage from "../../Assets/storage.png";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useAuth } from "src/utils/auth-context";

export default function MyProfile() {
  const getName = () => localStorage.getItem("userName");
  const role = parseInt(localStorage.getItem("role"));
  const [status, setStatus] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState({});
  const [allStatus, setAllStatus] = React.useState([]);
  const profileImage = localStorage.getItem("profileImage");
  const [updateStatus, setUpdateStatus] = React.useState(false);
  const [showStatus, setShowStatus] = React.useState(false);
  const [statuses, setStatuses] = React.useState([]);
  const { notificationCouter } = useSharedState();
  const {
    jobId,
    userProfile,
    showNotifications,
    setShowNotifications,
    profileUpdated,
    setProfileUpdated,
    // walletBalance,
    setWalletBalance,
    walletUpdated,
    setWalletUpdated,
  } = useSharedState();
  const imageUrl =
    userProfile !== null && userProfile !== ""
      ? `${baseURLs.yemo}/${userProfile}`
      : profileImage !== undefined &&
        profileImage !== null &&
        profileImage !== "null"
      ? `${baseURLs.yemo}/${profileImage}`
      : Profile;
  React.useEffect(() => {
    getName();
    if (profileUpdated) {
      setProfileUpdated(false);
    }
  }, [profileUpdated]);
  useEffect(() => {
    setShowNotifications(false);
  }, [jobId]);

  const getUser = () => {
    userAPI
      .getUserById()
      .then((res) => {
        //setUser(res.data.body.data)
        setStatus(res.data.body.data?.statusId);
        setWalletBalance(res.data.body.data.wallet.balance);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  useEffect(() => {
    getUser();
  }, [jobId]);
  React.useEffect(() => {
    if (walletUpdated) {
      getUser();
      setWalletUpdated(false);
    }
  }, [walletUpdated]);

  const getAllStatus = () => {
    userAPI
      .getAllStatus()
      .then((res) => {
        setAllStatus(res.data.body.data);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const updateUserStatus = () => {
    userAPI
      .updateUserStatus({ statusId: status })
      .then((res) => {
        if (res.data.success === false && status === 2) {
          setShowStatus(false);
          setUpdateStatus(false);
          toast.error("Cannot change the Status, while jobs are in progress!!");
        } else {
          setShowStatus(false);
          setUpdateStatus(false);
          setUserStatus(getStatusById(status));
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };

  const handleShowStatus = () => {
    setShowStatus(!showStatus);
  };

  const statusIcons = [
    { name: "Working", icon: working },
    { name: "On Vacation", icon: vacation },
    { name: "DND", icon: dnd },
  ];

  useEffect(() => {
    const statusWithIcons = allStatus.map((status) => {
      const matchingIcon = statusIcons.find(
        (iconStatus) => iconStatus.name === status.name
      );
      return { ...status, icon: matchingIcon ? matchingIcon.icon : null };
    });
    setStatuses(statusWithIcons);
  }, [allStatus]);

  const getStatusById = (id) => {
    return statuses.find((status) => status.id === id) || null;
  };

  React.useEffect(() => {
    getAllStatus();
    // getUser();
    // setUserStatus(getStatusById(status));
  }, []);
  React.useEffect(() => {
    if (updateStatus) {
      updateUserStatus();
    }
  }, [updateStatus]);
  const { user } = useAuth();

  useEffect(() => {
    setStatus(user?.statusId);
    setUserStatus(getStatusById(user?.statusId));
  }, [statuses]);
  return (
    <>
      {showStatus ? (
        <UserStatus
          user={user}
          allStatus={statuses}
          setStatus={setStatus}
          setUpdateStatus={setUpdateStatus}
          setShowStatus={setShowStatus}
        />
      ) : (
        ""
      )}

      <Box
        sx={(theme) => ({
          my: 2,
          pl: 1,
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("md")]: { my: 1 },
        })}
        className="tour-myprofile"
      >
        <img
          src={imageUrl}
          height="50px"
          width="50px"
          style={{ borderRadius: 50 }}
          alt="Profile"
        />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{ fontSize: "16px", color: "#333333", fontWeight: "600" }}
            >
              {getName()}
            </Typography>
            <Box sx={{ display: "flex" }} className="tour-status">
              <Button
                onClick={() => {
                  handleShowStatus();
                }}
                style={{ padding: "6px 0" }}
              >
                {
                  <Avatar
                    src={
                      userStatus === null
                        ? statusIcons[0].icon
                        : userStatus.icon
                    }
                    alt="Avatar"
                    style={{
                      height: "13px",
                      width: "13px",
                      marginRight: "5px",
                    }}
                  />
                }
                <Typography
                  variant="body1"
                  style={{
                    marginLeft: "0px",
                    fontSize: "12px",
                    textTransform: "none",
                  }}
                >
                  {userStatus?.name || statusIcons[0].name}
                </Typography>
                <KeyboardArrowDownIcon
                  style={{ color: "#5E5F69", fontSize: "1.1rem" }}
                />
              </Button>
            </Box>
          </Box>
          <Box
            className="notificationButton"
            sx={{ display: "flex", alignItems: "center", gap:"10px", paddingRight:"10px" }}
          >
            {role === 1 ? (
              <Box
                sx={{
                  paddingLeft: "0",
                  paddingTop: "0px",
                }}
                className="tour-wallet"
              >
                {/* <Link
                  to="/dashboard/carddetails"
                  style={{ display: "flex", textDecoration: "none" }}
                >
                  <AccountBalanceWalletIcon
                    sx={{
                      color: "#018385",
                      paddingRight: "5px",
                      fontSize: "26px",
                    }}
                  />
                  <Typography
                    variant="body"
                    style={{
                      marginLeft: "0px",
                      fontSize: "12px",
                      textTransform: "none",
                      fontWeight: "bolder",
                      color: "#018385",
                      paddingTop: "5px",
                    }}
                  >
                    $ {parseFloat(walletBalance.toFixed(2))}
                  </Typography>
                </Link> */}
              </Box>
            ) : (
              ""
            )}
            {/* <Link
              component={Button}
              to="file"
              style={{ marginLeft: "14px", marginRight: "14px" }}
            >
              <img
                src={storageIcon}
                alt="storageIcon"
                height="25px"
                width="25px"
              />
            </Link> */}
            <Link
              onClick={() => {
                setShowNotifications(!showNotifications);
              }}
            >
              <Badge badgeContent={notificationCouter} color="error">
                <NotificationsNoneIcon
                  className="NotificationIcon"
                  style={{ color: "#6A7477" }}
                />
              </Badge>
            </Link>
            <DotMenu />
          </Box>
        </Box>
      </Box>
    </>
  );
}
