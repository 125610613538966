import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import InfoIcon from "../../Assets/info.png";
import DisputeIcon from "../../Assets/dispute.png";
import RefundIcon from "../../Assets/refund.png";
import "./InfoAlerts.css";
import { useJobSharedState } from "src/utils/jobStateProvider";

const InfoAlerts = () => {
  const { userJob } = useJobSharedState();

  return (
    <Stack className="InfoAlert">
      <>
        {userJob.jobStatus === "waiting" ? (
          <Alert severity="warning">Waiting</Alert>
        ) : userJob.jobStatus === "inprogress" ? (
          <Alert severity="success">
            <img src={InfoIcon} alt="Inprogress"/>
            In-Progress
          </Alert>
        ) : userJob.jobStatus === "completed" ? (
          <Alert severity="success">
            <img src={InfoIcon} alt="Completed"/> Completed
          </Alert>
        ) : userJob.jobStatus === "conflict" ? (
          <Alert severity="error">
            <img src={DisputeIcon} alt="DisputeIcon"/> In-Conflict
          </Alert>
        ) : userJob.jobStatus === "refund" ? (
          <Alert severity="info">
            <img src={RefundIcon} alt="RefundIcon"/> Refund
          </Alert>
        ) : userJob.jobStatus === "pending" ? (
          <Alert severity="info">
            <img src={RefundIcon} alt="In-Draft"/>
            In-Draft
          </Alert>
        ) : (
          ""
        )}
      </>
    </Stack>
  );
};

export default InfoAlerts;
