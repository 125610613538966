/* eslint-disable no-unused-vars */
import * as React from "react";
import "./ActionButtons.css";

const AcceptRejectButtons = () => {
  //  const { jobId, setAcceptInvite } = useSharedState();
 // const { setRejectJob, setAcceptReject, setSuperviserAccept } = useJobSharedState();

  // const handleRequest = (status) => {
  //   if (status === "accepted") {
  //     setSuperviserAccept(true);
  //     // jobAPI
  //     //   .acceptOrRejectRequest({ jobId: jobId, status: status })
  //     //   .then((res) => {
  //     //     if (res.data.success === true) {
  //     //       const updatedUserJob = {
  //     //         ...userJob,
  //     //         jobStatus: "inprogress",
  //     //       };
  //     //       updateJob(updatedUserJob);
  //     //       setButton(true);
  //     //       const messageData = {
  //     //         jobId: jobId,
  //     //         message: `${userName} accepted your contract!!`,
  //     //       };
  //     //       socket.emit("yemoMessage", { messageData });
  //     //       socket.emit("rightSideBar", messageData);
  //     //     }
  //     //     setAcceptReject(true);
  //     //     setAcceptInvite(true);
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log("errooor",err);;
  //     //     toast.error("Something went wrong!!");
  //     //   });
  //   } else {
  //     if (status === "rejected") {
  //       setButton(true);
  //       setRejectJob(true);
  //       setAcceptReject(true);
  //     }
  //   }
  // };

  return (
    <>
      {/* {!button && userJob.jobStatus === "waiting" && role === 4 ? (
        <Box className="AcceptRejectButtons"> */}
          {/* <Button
            variant="contained"
            className="LoginButton"
            sx={{ py: 1, px: 2, m: 2, width: "70%" }}
            onClick={() => {
              handleRequest("accepted");
            }}
          >
            Accept
          </Button>

          <Button
            className="rejectButton"
            variant="contained"
            sx={{ py: 1, px: 2, m: 2, width: "70%" }}
            onClick={() => handleRequest("rejected")}
          >
            Decline
          </Button> */}  
        {/* </Box>
      ) : (
        ""
      )} */}
    </>
  );
};

export default AcceptRejectButtons;
