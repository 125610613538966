const Joi = require("joi");

export const rejectReasonSchema = Joi.object({
  title: Joi.string()
    .max(100)
    .label("description")
    .required()
    .messages({
      "string.empty": `Title is required`,
      "string.max": "More than 100 characters are not allowed",
    }),
  description: Joi.string().max(100).label("description").required().messages({
    "string.max": "More than 100 characters are not allowed",
    "string.empty": "Write something for better understanding",
  }),
});
