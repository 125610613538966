import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatTime } from "src/utils/helper";
import LoginFooter from "../LoginFooter/LoginFooter";
import LoginHeader from "../LoginHeader/LoginHeader";
import LoginBox from "../../Assets/LoginBox.png";
import "./forgotPassword.css";
import { toast } from "react-toastify";
import { useSharedState } from "src/utils/SharedStateProvider";
import { MuiOtpInput } from "mui-one-time-password-input"; // Import the OTPInput component
// import useMediaQuery from '@mui/material/useMediaQuery';
import { userAPI } from "src/services/userApis";

export const ForgotPasswordVerification = () => {
  const { setLoading } = useSharedState();
  const location = useLocation();
  const [disabled, setDisabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(30);
  const [domainName, setDomainName] = useState({
    character: "",
    domain: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [otpValues, setOTPValues] = useState(""); // Define otpValues state
  const [verification, setVerification] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const maskCharacters = (inputStr) => {
    if (inputStr.length <= 2) {
        return inputStr;
    } else {
        return inputStr.substring(0, 2) + 'x'.repeat(inputStr.length - 2);
    }
}
  useEffect(() => {
    const email = sessionStorage.getItem("email") || "example@gmail.com";
    setDomainName({character: maskCharacters(email.split("@")[0]) ,domain:email.split("@")[1]});
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    const stringExistsInUrl = currentUrl.includes("/verification");
    const forgotPasswordExists = currentUrl.includes(
      "/forgotPasswordVerification"
    );
    if (stringExistsInUrl) {
      setVerification(true);
    } else if (forgotPasswordExists) {
      setVerification(false);
    }
  }, []);

  // const matches = useMediaQuery('(min-width:600px)');

  // const otpGap = () => {
  //   if(matches){
  //     return 2;
  //   }else{
  //     return 1;
  //   }
  // }

  const handelResendOTP = (event) => {
    event.preventDefault();
    setLoading(true);
    const value = {
      email: sessionStorage.getItem("email"),
    };
    userAPI
      .resendOtp(value)
      .then((res) => {
        setLoading(false);
        toast.success(res.body.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (otpValues.length === 6 && !isNaN(otpValues)) {
      // Check if otpValues is 6 characters long and contains only numeric characters
      const formData = {
        otp: parseInt(otpValues),
        email: sessionStorage.getItem("email"),
        signUp: false,
      };
      if (location.pathname === "/verification") {
        formData.signUp = true;
      }
      userAPI
        .verification(formData)
        .then((res) => {
          if (res.success === true) {
            toast.success(res.body.message);
            if (location.pathname === "/verification") {
              localStorage.clear();
              const data = res.body;
              localStorage.setItem("tour",true);
              localStorage.setItem("authProviderToken", data.data.token);
              localStorage.setItem("userId", data.data.user.id);
              localStorage.setItem("role", data.data.user.roleId);
              localStorage.setItem("userName", data.data.user.name);
              setLoading(false);
              navigate("/dashboard",{replace:true});
            } else {
              setLoading(false);
              navigate("/passwordRecovery",{replace:true});
            }
          } else {
            setLoading(false);
            toast.error(res.body.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!!");
        });
    } else {
      setLoading(false);
      toast.error("Invalid input in OTP");
    }
  };

  const handleChange = (value) => {
    // Filter out non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");

    // Update the OTP value with the numeric part
    setOTPValues(numericValue);

    // Check if the input contains non-numeric characters
    if (value !== numericValue) {
      setError("Please enter a valid OTP");
      setDisabled(true); // Disable submit button
    } else if (numericValue.length === 6) {
      setError(""); // Clear the error message if input is valid
      setDisabled(false); // Enable submit button
    } else {
      setError(""); // Clear the error message if input is valid
      setDisabled(true); // Disable submit button
    }
  };
  return (
    <>
      <Box
        className="LoginPage"
        sx={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
      >
        <LoginHeader />
        <Box className="Signup">
          <Grid
            container
            sx={{
              background: "white",
              borderRadius: "20px",
              maxWidth: "1145px",
              margin: "0 auto",
            }}
            className="SignUpBox"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                p: 2,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
              className="verificationBox"
            >
              <Box>
                <Typography variant="h3" className="title-verification">
                  Verification
                </Typography>
                <Typography variant="p" className="desc-verification">
                  Give it a quick check so we can keep this YeMo journey going
                  smoothly.
                </Typography>
              </Box>

              <Box
                sx={(theme) => ({
                  mt: 5,
                  [theme.breakpoints.down("md")]: { display: "none" },
                })}
              >
                <img src={LoginBox} alt="login" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ p: 2 }}>
              <Box>
                <Typography
                  variant="h5"
                  sx={(theme) => ({
                    fontWeight: 800,
                    [theme.breakpoints.down("md")]: { display: "none" },
                  })}
                >
                  Join the Verified Elite
                </Typography>
                <Typography
                  sx={(theme) => ({
                    mt: 2,
                    wordBreak: "break-word",
                    fontSize: "14px",
                    mb: 5,
                    [theme.breakpoints.down("md")]: { mt: 0, mb: 3 },
                  })}
                >
                  Hey! There's a little hello from us in your inbox at {domainName.character}@
                  {domainName.domain}. Enter the verification code below to verify your
                  email id.
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box sx={{ my: 3 }}>
                  {/* Replace custom OTP input fields with OTPInput */}
                  {/* <MuiOtpInput
                    length={6}
                    gap={otpGap()}
                    value={otpValues}
                    onChange={handleChange}
                    inputClassName="otpinput"
                  /> */}
                  <MuiOtpInput
                    value={otpValues}
                    id="otp_component"
                    length={6}
                    onChange={handleChange}
                    // inputProps={{ type: "tel" }}
                    TextFieldsProps={{
                      inputProps: {
                        type: "number",
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      },
                      size: "medium",
                    }}
                  />

                  {error && <p className="error-message">{error}</p>}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="resendCnt"
                >
                  <Typography>
                    <Button variant="text" disabled={remainingTime > 0} onClick={handelResendOTP}>
                      Resend
                    </Button>
                    {remainingTime > 0 ? <>{formatTime(remainingTime)} sec</> : ""}
                    
                  </Typography>
                  <Link to={verification ? "/signup": "/forgotpassword"} underline="none">
                    Change Email
                  </Link>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  className="LoginButton"
                  sx={{ width: "100%", mt: 4 }}
                  disabled={disabled}
                >
                  {verification ? "Create Account" : "Verify"}
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>
        <LoginFooter />
      </Box>
    </>
  );
};
