/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import heic2any from "heic2any";
import { toast } from "react-toastify";
import { Button, Dialog, DialogContent } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Profile from "../../Assets/user-default-icon.png";
import "./ProfilePage.css";
import { baseURLs } from "src/constants/api.constants";
import { useSharedState } from "src/utils/SharedStateProvider";
const ProfileCrop = ({
  setIsEditingProfileImage,
  setIsLoading,
  isLoading,
  formData,
  setFormData,
}) => {
  const [imgUrl, setImgUrl] = useState("");
  const {userProfile} = useSharedState();
  // const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25
  });
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(1);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const fileInputRef = useRef(null);
  
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    fileInputRef.current.value = null;
    setOpen(false);
    setImgUrl("");
    setImgSrc("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      profileImage: userProfile,
    }));
  };
  const postNewProfile = async (file) => {
    const compressedImage = file;
    setFormData((prevFormData) => ({
      ...prevFormData,
      profileImage: compressedImage,
    }));
    setImgSrc("");
  };

  useEffect(() => {
    return setIsEditingProfileImage(false);
  }, [crop]);
  // !NEW FUNCTIONS

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          x: mediaWidth / 9,
          y: mediaWidth / 9,
          width: mediaWidth,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onSelectFile =  (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImgSrc(URL.createObjectURL(file));
      if (file.size > 1048576 * 20) {
        toast.error("Only Accept less than 10MB.");
        setCompletedCrop(undefined);
        setImgSrc("");
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      if (file.type === "image/heic" || file.type === "") {
        try {
          const convertedImageBlob = heic2any({
            blob: file,
            toType: "image/png",
          });
          const convertedFile = new File([convertedImageBlob], "image.png", {
            type: "image/png",
          });

          reader.readAsDataURL(convertedFile);
          onOpen();
        } catch (error) {
          console.error("Error converting HEIC image:", error);
          toast.error(
            "Error processing image. Please try again with a different image."
          );
          setCompletedCrop(undefined);
          setImgSrc("");
        }
      } else {
        // For non-HEIC images, proceed as usual
        reader.readAsDataURL(file);
        onOpen();
      }
    }
  };

  const onImageLoad = (e) => {
    setImage(e);
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop((width * 9) / 10, (height * 9) / 10, aspect));
    }
    setIsEditingProfileImage(true);
    // setCrop({
    //   x: 10,
    //   y: 10,
    //   width: 200,
    //   height: 200,
    //   unit: "px",
    // });
  };

  const blobToFile = (blob, fileType) => {
    const timestamp = new Date().toISOString().replace(/:/g, "-");
    const fileName = `${timestamp}.${fileType}`;
    const file = new File([blob], fileName, { type: `image/${fileType}` });
    return file;
  };

  const onDownloadCropClick = async () => {
    if (!image) throw new Error("Image not selected");

    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.target.naturalWidth / image.target.width;
      const scaleY = image.target.naturalHeight / image.target.height;

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;

      const ctx = canvas.getContext("2d");
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image.target,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(
        (blob) => {
          const fileType = "png"; // Replace with your desired file type
          const fileObject = blobToFile(blob, fileType);
          const imgUrl = URL.createObjectURL(fileObject);
          setImgUrl(imgUrl);
          postNewProfile(fileObject);
        },
        "image/png",
        1
      );
    } catch (err) {
      console.log(err);
    }
  };
console.log("ref",fileInputRef);
  return (
    <>
      {/* <label
        htmlFor="image-upload"
        style={{
          height: "85px",
          width: "85px",
          overflow: "hidden",
          borderRadius: "50%",
        }}
      >
        <Button
          variant="outlined"
          component="span"
          style={{
            border: "none",
            borderRadius: 50,
            padding: 0,
          }}
        >
          <img
            src={
              //   formData.profileImage === null || formData.profileImage === ""
              //     ? Profile
              //     : formData.profileImage instanceof File
              //     ? URL.createObjectURL(formData.profileImage)
              //     : `${baseURLs.yemo}/${formData.profileImage}`
              formData.profileImage === null || formData.profileImage === ""
                ? Profile
                : imgUrl ||
                  imgSrc ||
                  `${baseURLs.yemo}/${formData.profileImage}`
            }
            alt="Profile"
            style={{ borderRadius: 50 }}
            className="size-82"
          />
          <AddAPhotoIcon
            style={{
              translate: "-1em 1em",
              color: "slategrey",
              fontSize: "20px",
              zIndex: "10",
            }}
          />
        </Button>
      </label> */}
      <div>
        <label htmlFor="image-upload" className="user_profile_label">
          <div className="user_profile_img">
            <img
              src={
                formData.profileImage === null || formData.profileImage === ""
                  ? Profile
                  : imgUrl ||
                    imgSrc ||
                    `${baseURLs.yemo}/${formData.profileImage}`
              }
              style={{ width: "100%", height: "100%" }}
              alt="profile-img"
            />
          </div>
          <AddAPhotoIcon
            style={{
              color: "slategrey",
              position: "absolute",
              bottom: "0.2em",
              right: "0.2em",
              fontSize: "20px",
              zIndex: "10",
            }}
          />
        </label>
        <input
        ref={fileInputRef}
          onChange={onSelectFile}
          id="image-upload"
          accept=".svg, .jpg, .jpeg, .png"
          type="file"
          style={{
            display: "none",
            background: "white",
            color: "black",
            placeItems: "center",
          }}
          name="profile-img"
        />
      </div>
      {imgSrc.length > 0 ? (
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogContent
            sx={{
              maxWidth: "100%",
              width: "auto",
              height: "auto",
              maxHeight: "80%",
            }}
          >
            <div className="profile_img_main_container">
              <ReactCrop
                crop={crop}
                onComplete={(c) => setCompletedCrop(c)}
                onChange={(c, pc) => setCrop(c)}
                aspect={1} // Update the aspect ratio as needed
                keepSelection={true}
                circularCrop={true}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={
                    imgSrc !== ""
                      ? imgSrc
                      : formData.profileImage === null ||
                        formData.profileImage === ""
                      ? Profile
                      : `${baseURLs.yemo}/${formData.profileImage}`
                  }
                  onLoad={onImageLoad}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100vh",
                    width: "auto",
                    height: "auto",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </ReactCrop>

              <div className="profile_img_btn_container">
                <Button
                  className="LoginButton"
                  sx={{ color: "white" }}
                  onClick={onClose}
                  id="cancel_btn"
                >
                  Cancel
                </Button>

                {!!completedCrop && (
                  <>
                    <div>
                      <Button
                        onClick={() => {
                          onDownloadCropClick();
                          onClose();
                        }}
                        sx={{ color: "white" }}
                        className="LoginButton"
                      >
                        Done
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileCrop;
