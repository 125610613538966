import React, { useEffect, useState } from "react";
import "../../style/style.css";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import "./Signup.css";
import { Link, useNavigate } from "react-router-dom";
import LoginHeader from "../LoginHeader/LoginHeader";
import LoginFooter from "../LoginFooter/LoginFooter";
import LoginBox from "../../Assets/LoginBox.png";
import { toast } from "react-toastify";
import { userSchema } from "src/validationSchema/userSchema";
import { useSharedState } from "src/utils/SharedStateProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userAPI } from "src/services/userApis";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export const Signup = () => {
  const { setLoading } = useSharedState();
  const [showPassword, setShowPassword] = useState(false);
  const role = parseInt(localStorage.getItem("role") || 1);
  const [value, setValue] = useState(role);
  const [formData, setFormData] = useState({
    name: "",
    // phone: "",
    email: "",
    password: "",
    roleId: value,
  });
  const [isChecked, setIsChecked] = useState(false);
  const [touchedFields, setTouchedFields] = useState([]);
  const [errors, setErrors] = useState({});
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const validateForm = () => {
    const { error } = userSchema.validate(formData, { abortEarly: false });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      // if (touchedFields.includes(error.path[0])) {
      newErrors[error.path[0]] = error.message;
      // }
    });
    setErrors(newErrors);
    return false;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    if (!touchedFields.includes(name)) {
      setTouchedFields([...touchedFields, name]);
    }
    validateForm();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm()) {
      userAPI
        .post(formData)
        .then(async (res) => {
          if (res.body.data.alreadyUser) {
            toast.error(res.body.message);
            setLoading(false);
            navigate("/signin");
          } else if (res.body.data.notVerified) {
            toast.error(res.body.message);
            sessionStorage.setItem("email", formData.email);
            setLoading(false);
            navigate("/verification");
          } else if (res.success === true) {
            toast.success(res.body.message);
            sessionStorage.setItem("email", formData.email);
            setLoading(false);
            navigate("/verification");
          } else {
            setLoading(false);
            toast.error(res.body.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            // toast.error("Something went wrong!!");
          }
        });
    } else {
      setLoading(false);
    }
  };
  // const [selectedDate, handleDateChange] = useState(new Date());
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    localStorage.removeItem("authProviderToken");
  }, []);
  const userRole = role ? role : 1; // Replace this with the actual user's role (1 or 2)

  useEffect(() => {
    // Set the default tab based on the user's role
    setValue(userRole === 2 ? 2 : 1);
  }, [userRole]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setFormData((prevFormData) => ({ ...prevFormData, roleId: newValue }));
  };

  const hasCapitalLetter = (inputString) => {
    const regex = /[A-Z]/;
    return regex.test(inputString);
  };

  const hasLowerCaseAndMinLength = (inputString) => {
    return /[a-z]/.test(inputString) && inputString.length >= 8;
  }

  const hasSpecialCharacter = (inputString) => {
    return /[^a-zA-Z0-9]/.test(inputString);
  }
  return (
    <Box
      className="LoginPage"
      sx={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
    >
      <LoginHeader />
      <Box className="Signup">
        <Grid
          container
          sx={{
            background: "white",
            borderRadius: "20px",
            maxWidth: "1145px",
            margin: "0 auto",
          }}
          className="SignUpBox"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              p: 2,
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            className="title-new"
          >
            <Box>
              <Typography variant="h3" className="font-80 mob-xs">
                New Here?
              </Typography>
              <Typography
                variant="p"
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: { display: "none" },
                })}
              >
                No Problem! <span>Peek to your right</span>, you'll see an
                option to create an account.
              </Typography>
            </Box>

            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: { display: "none" },
              })}
            >
              <img src={LoginBox} alt="loginbox" />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ p: 2 }}
            className="title-create"
          >
            <Typography variant="h5">Create an Account</Typography>
            <br />
            <Tabs
              name="roleId"
              value={value}
              onChange={handleTabChange}
              aria-label="icon position tabs example"
              className="sellerButton"
              indicatorColor="none"
              sx={{
                "& .MuiTabs-flexContainer": {
                  transition: " all 0.4s ease-in-out",
                },
              }}
            >
              <Tab
                className={value === 2 ? "LoginButton" : ""}
                value={2}
                sx={{
                  padding: value === 1 ? "0px 39px" : "",
                  "&.Mui-selected": { color: "white" },
                }}
                label="Offering Services?"
              />
              <Tab
                className={value === 1 ? "LoginButton" : ""}
                value-={1}
                sx={{
                  padding: value === 2 ? "0px 39px" : "",
                  "&.Mui-selected": { color: "white" },
                }}
                label="Looking for Services?"
              />
            </Tabs>
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Full Name*"
                error={
                  // touchedFields.includes("name") ||
                  !!errors.name
                }
                helperText={
                  // touchedFields.includes("name") ?
                  errors.name
                  // : ""
                }
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
                onBlur={handleBlur}
              />
              <TextField
                type="email"
                name="email"
                label="Email id*"
                error={
                  //  touchedFields.includes("email") || !!
                  errors.email
                }
                helperText={
                  // touchedFields.includes("email") ?
                  errors.email
                  // : ""
                }
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
                onBlur={handleBlur}
                inputProps={{ inputMode: "email" }}
              />
              {/* <TextField
                label="Mobile number"
                type="tel"
                name="phone"
                error={touchedFields.includes("phone") && !!errors.phone}
                helperText={touchedFields.includes("phone") ? errors.phone : ''}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
                onBlur={handleBlur}
              /> */}
              <TextField
                name="password"
                label="Password*"
                type={showPassword ? "text" : "password"}
                error={
                  // touchedFields.includes("password") &&
                  !!errors.password
                }
                helperText={
                  // touchedFields.includes("password") ?
                  errors.password
                  // : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                      >
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff sx={{ color: "#0F485B" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box className="passwordColumnBox">
                <Box className="passwordRowBox">
                  {hasCapitalLetter(formData.password) ? (
                    <CheckCircleIcon  className="checkIcon"/>
                  ) : (
                    <CancelIcon className="cancelIcon"/>
                  )}
                 <Typography className="passwordTypo">
                    One Capital Letter
                  </Typography>
                </Box>
                <Box className="passwordRowBox">
                  {hasLowerCaseAndMinLength(formData.password) ? (
                    <CheckCircleIcon className="checkIcon"/>
                  ) : (
                    <CancelIcon className="cancelIcon"/>
                  )}
                  <Typography className="passwordTypo">
                    One Small Letter & having minimum 8 characters
                  </Typography>
                </Box>
                <Box className="passwordRowBox">
                  {hasSpecialCharacter(formData.password) ? (
                    <CheckCircleIcon className="checkIcon"/>
                  ) : (
                    <CancelIcon className="cancelIcon"/>
                  )}
                 <Typography className="passwordTypo">
                    One Special Character
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ my: 1 }}>
                <Checkbox
                  checked={isChecked}
                  name="terms"
                  onChange={handleCheckboxChange}
                  {...label}
                />
                I accept the
                <Link
                  to="/TermsAndConditions"
                  target="_blank"
                  style={{
                    paddingLeft: "2px",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Terms and Conditions
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                className="LoginButton"
                sx={{ width: "100%" }}
                disabled={!isChecked}
              >
                Create Account
              </Button>
              <Box sx={{ mt: 2 }} className="newHere">
                <Typography variant="p">
                  Already have an Account? &nbsp;
                  <Link
                    to="/SignIn"
                    underline="none"
                    sx={{ color: "#333333 !important" }}
                  >
                    Login
                  </Link>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <LoginFooter />
    </Box>
  );
};
