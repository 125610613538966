export const handleApiError = async (apiCall) => {
  try {
    const response = await apiCall();
    return response;
  } catch (error) {
    // Handle errors globally
    console.log(error);
    if (error.response && error.response.status === 500) {
      // Unauthorized, handle logout or redirect to login page
      // localStorage.clear();
      //   window.location.href = '/signin';
    }
    throw error; // Re-throw the error for further handling in the component
  }
};
