import * as React from "react";
import IconButton from "@mui/material/IconButton";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import { List, ListItem, ListItemText, Popover } from "@mui/material";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { Link } from "react-router-dom";

const DotMenuHorizontal = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { showLeaveJobForm, setShowLeaveJobForm, userJob } =
    useJobSharedState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    if (
      userJob.jobStatus === "inprogress" ||
      userJob.jobStatus === "completed"
    ) {
      // Add your delete logic here

      setShowLeaveJobForm(!showLeaveJobForm);
    }
    handleClose(); // Close the menu when the delete option is clicked
  };

  return (
    <>
      <IconButton aria-label="dot-menu" onClick={handleClick}>
        <MoreHorizSharpIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          <ListItem component={Link} onClick={handleDeleteClick}>
            <ListItemText primary="Leave Job" style={{ color: "black" }} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default DotMenuHorizontal;
