import { Box, Button, TextField, Typography } from '@mui/material';
import Joi from 'joi';
import React, { useState } from 'react'

export default function CustomerDetailsForm({ handleClick }) {
    const [contactDetails, setContactDetails] = useState({
        name: '',
        address: '',
        vat: '',
        contact: '',
        phone: ''
    })
    const [errors, setErrors] = useState({});

    const schema = Joi.object({
        name: Joi.string().min(2).max(100).required().label('Name of Company'),
        address: Joi.string().min(5).max(200).required().label('Address'),
        vat: Joi.string().min(2).max(20).required().label('VAT'),
        contact: Joi.string().min(2).max(50).required().label('Contact Person'),
        phone: Joi.string().min(10).max(15).required().label('Phone'),
    });    

    const formatContactDetails = (details) => {
        return `Company name: ${details.name}
                Address: ${details.address}
                Vat: ${details.vat}
                Contact Person: ${details.contact}
                Phone: ${details.phone}`;
    };

    const validate = () => {
        const { error } = schema.validate(contactDetails, { abortEarly: false });
        if (!error) return null;

        const validationErrors = {};
        error.details.forEach((detail) => {
            validationErrors[detail.path[0]] = detail.message;
        });
        return validationErrors;
    };

    const handleSubmit = async () => {
        const validationErrors = validate();
        setErrors(validationErrors || {});
        if (validationErrors) return;
        const data = { details: contactDetails, reply: formatContactDetails(contactDetails) }
        await handleClick(data)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    return (
        <div>
            <Box className='resourceTable'>
                <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center", gap: "10px", m: 1 }}>
                    <Typography className='resource-heading'>
                        Contact Details
                    </Typography>
                    <TextField
                        name='name'
                        label='Name of Company'
                        value={contactDetails.name}
                        fullWidth
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        name='address'
                        label='Address'
                        multiline
                        rows={3}
                        fullWidth
                        value={contactDetails.address}
                        onChange={handleChange}
                        error={!!errors.address}
                        helperText={errors.address}
                    />
                    <TextField
                        name='vat'
                        label='VAT'
                        fullWidth
                        value={contactDetails.vat}
                        onChange={handleChange}
                        error={!!errors.vat}
                        helperText={errors.vat}
                    />
                    <TextField
                        name='contact'
                        label='Contact Person'
                        fullWidth
                        value={contactDetails.contact}
                        onChange={handleChange}
                        error={!!errors.contact}
                        helperText={errors.contact}
                    />
                    <TextField
                        name='phone'
                        label='Phone'
                        fullWidth
                        value={contactDetails.phone}
                        onChange={handleChange}
                        error={!!errors.phone}
                        helperText={errors.phone}
                    />
                    <Button variant="contained" className="LoginButton" fullWidth onClick={async ()=>{await handleSubmit()}}>
                        Submit
                    </Button>
                </Box>
            </Box></div>
    )
}
