import axios from "axios";
import {
  baseURLs,
  googleRoutes,
  userRoutes,
} from "src/constants/api.constants";
import { handleApiError } from "src/utils/apiError";
import { yemoApiQuery } from "src/utils/apiQuery";

export const userAPI = {
  get: async () =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.get(`${userRoutes.getUser}`);
      return data;
    }),
  getUserById: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${userRoutes.getUserById}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
  post: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(
        `${userRoutes.createUser}`,
        body
      );
      return data;
    }),
  logIn: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(`${userRoutes.logIn}`, body);
      return data;
    }),
  forgotPassword: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(
        `${userRoutes.forgotPassword}`,
        body
      );
      return data;
    }),
  resetPassword: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.put(
        `${userRoutes.resetPassword}`,
        body
      );
      return data;
    }),
  resendOtp: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(`${userRoutes.resendOTP}`, body);
      return data;
    }),
  verification: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(
        `${userRoutes.verification}`,
        body
      );
      return data;
    }),
  verifyOtpResetPassword: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(
        `${userRoutes.verifyOtpResetPassword}`,
        body
      );
      return data;
    }),
  updateTwoFactor: async (body) =>
    handleApiError(async () => {
      const { data } = await yemoApiQuery.post(
        `${userRoutes.verification}`,
        body
      );
      return data;
    }),
  getUserProfile: async () =>
    handleApiError(async () => {
      const profile = await axios.get(baseURLs.yemo + userRoutes.getProfile, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authProviderToken")}`,
        },
      });
      return profile;
    }),
  updateProfile: async (body) =>
    handleApiError(async () => {
      const profile = await axios.put(
        baseURLs.yemo + userRoutes.updateProfile,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return profile;
    }),
    updateTour: async () =>
    handleApiError(async () => {
      const profile = await axios.patch(
        baseURLs.yemo + userRoutes.updateTour,
        {tour:false},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return profile;
    }),
  resetPasswordProfile: async (body) =>
    handleApiError(async () => {
      const data = await axios.put(
        baseURLs.yemo + userRoutes.resetPasswordProfile,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return data;
    }),
  getAllStatus: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${userRoutes.getAllStatus}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
  updateUserStatus: async (data) =>
    handleApiError(async () => {
      const status = await axios.patch(
        `${baseURLs.yemo}${userRoutes.updateUserStatus}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  getGoogleToken: async (data) =>
    handleApiError(async () => {
      const status = await axios.post(
        `${baseURLs.yemo}${googleRoutes.getGoogleToken}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  getMicrosoftToken: async (data) =>
    handleApiError(async () => {
      const status = await axios.post(
        `${baseURLs.yemo}${googleRoutes.getMicrosoftToken}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  createCalenderEvent: async (data) =>
    handleApiError(async () => {
      const status = await axios.post(
        `${baseURLs.yemo}${googleRoutes.createCalenderEvent}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
  // updateCalenderEvent: async (data) => {
  //   const status = await axios.post(
  //     `${baseURLs.yemo}${endpoints.updateCalenderEvent}`,
  //     data,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("authProviderToken")}`,
  //       },
  //     }
  //   );
  //   return status;
  // },
  getCalendarEvents: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${googleRoutes.getCalenderEvents}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
  getMicrosoftEvents: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${googleRoutes.getMicrosoftEvents}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
  getNotifications: () =>
    handleApiError(async () => {
      const notifications = await axios.get(
        `${baseURLs.yemo}${userRoutes.getNotifications}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return notifications;
    }),
  markAsReadNotifications: () =>
    handleApiError(async () => {
      const markAsRead = await axios.put(
        `${baseURLs.yemo}${userRoutes.markAsReadNotifications}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return markAsRead;
    }),
  markAsReadNotificationById: (notificationId) =>
    handleApiError(async () => {
      const markAsRead = await axios.patch(
        `${baseURLs.yemo}${userRoutes.markAsReadNotificationById}/${notificationId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return markAsRead;
    }),
  getTransactions: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${userRoutes.getTransactions}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
    downloadInvoice: async (data) =>
    handleApiError(async () => {
      const status = await axios.post(
        `${baseURLs.yemo}${userRoutes.downloadInvoice}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return status;
    }),
    deleteUser: async (data) =>
    handleApiError(async () => {
      const deleteUser = await axios.patch(
        `${baseURLs.yemo}${userRoutes.deleteUser}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return deleteUser;
    }),
    decativateUser: async (data) =>
    handleApiError(async () => {
      const decativateUser = await axios.patch(
        `${baseURLs.yemo}${userRoutes.decativateUser}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return decativateUser;
    }),
    getAllSkills: async () =>
    handleApiError(async () => {
      const user = await axios.get(
        `${baseURLs.yemo}${userRoutes.getAllSkills}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return user;
    }),
    updateUserSkills: async (data) =>
    handleApiError(async () => {
      const decativateUser = await axios.put(
        `${baseURLs.yemo}${userRoutes.updateUserSkills}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return decativateUser;
    }),
};
