/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";

import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import "./PriceSummary.css";
import ProjectName from "./ProjectName";
import { useSharedState } from "src/utils/SharedStateProvider";
import { paymentAPI } from "src/services/api";
import { toast } from "react-toastify";
import { useJobSharedState } from "src/utils/jobStateProvider";

const PriceSummary = () => {
  const { jobId } = useSharedState();
  const { userJob } = useJobSharedState();
  const [paymentSummary, setPaymentSummary] = useState({});
  const [codeId, setCodeId] = useState();
  const [code, setCode] = useState({});

  useEffect(() => {
    if (userJob.codeId !== null) {
      setCodeId(userJob.codeId);
    }
    if (userJob.price && parseInt(userJob.id) === parseInt(jobId)) {
      paymentAPI
        .getJobPriceTab(jobId, { codeId })
        .then((res) => {
          setPaymentSummary(res.data.data.price);
          if (res.data.data.code) {
            setCode(res.data.data.code);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);
  return (
    <Box className="PriceSummary">
      <ProjectName />

      <Typography variant="subtitle1" fontWeight="bold">
        Payment Summary
      </Typography>
      {userJob.price ? (
        <TableContainer component={Paper}>
          <Table aria-label="payment summary">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <p>
                    <span>Base</span>

                    <span>
                      ${paymentSummary.basePrice?.toFixed(2)}
                    </span>
                  </p>
                  <p>
                    <span>Platform Fee</span>

                    <span>
                      ${paymentSummary.platformFee?.toFixed(2)}
                    </span>
                  </p>
                  <p>
                    <span>Tax</span>

                    <span>
                      ${paymentSummary.taxAmount?.toFixed(2)}
                    </span>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <p>
                    <span>Net Amount</span>

                    <span>
                      ${paymentSummary.netAmount?.toFixed(2)}
                    </span>
                  </p>
                </TableCell>
              </TableRow>
              {userJob.codeId !== null ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <p>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Promo Code <span>{code.name}</span>
                      </Typography>
                      <span>
                        ${paymentSummary.promoCodeDiscount?.toFixed(2)}
                      </span>
                    </p>
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              <TableRow>
                <TableCell>
                  <p>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Total Amount
                    </Typography>
                  </p>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    align="right"
                  >
                    ${paymentSummary.finalPrice?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
    </Box>
  );
};

export default PriceSummary;
