import React from "react";
import { Typography } from "@mui/material";
import NoCreatedJob from "../../Assets/NoCreatedJob.png";
export default function NoJob() {
  const role = parseInt(localStorage.getItem("role"));
  return (
    <div>
      <Typography variant="h6" sx={{ fontSize: "18px", marginBottom: "16px" }}>
        {role === 1
          ? "Hej! You’re all set and ready to roll. Keen to Start?"
          : "Hej! You’re all set and ready to roll."}
      </Typography>
      <img src={NoCreatedJob} alt="nocreatedjob" />
    </div>
  );
}
