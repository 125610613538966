/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@mui/material";

import "./MyCardList.css";
import BlueCard from "./BlueCard";
import YellowCard from "./YellowCard";
import { paymentAPI } from "src/services/api";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { usePaymentState } from "src/utils/PaymentStateProvider";

export default function MyCardsList({ getCards, savedCards, showCardButtons }) {
  const { setLoading } = useSharedState();
  const { customerId, setPaymentMethodId } = usePaymentState();
  // useEffect(() => {
  // setLoading(true);
  // console.log(paymentMethods);
  // paymentAPI
  //   .getPaymentMethodByCustomer()
  //   .then((res) => {
  //     ;
  //     console.log(res);
  //     if (res.data.success === true) {
  //       setPaymentMethods([]);
  //       if (res.data.data.paymentMethods.length > 0) {
  //         console.log(res.data.data.paymentMethods);
  //         res.data.data.paymentMethods.forEach(
  //           ({ billing_details, card, id }, index) => {
  //             setPaymentMethods((previousItem) => [
  //               ...previousItem,
  //               {
  //                 id: id,
  //                 cardName: billing_details.name,
  //                 last4: card.last4,
  //                 exp_month: card.exp_month,
  //                 exp_year: card.exp_year,
  //               },
  //             ]);
  //           }
  //         );
  //       }
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   })
  //   .catch((err) => {

  //     setLoading(false);
  //     toast.error("Something went wrong!!");
  //   });
  // }, [paymentMethodId]);

  const handleChange = async (newValue) => {
    //setLoading(true);
    await paymentAPI
      .setDefaultCard({ customerId, paymentMethodId: newValue })
      .then((res) => {
        if (res.data.success === true) {
          setPaymentMethodId(newValue);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!!");
      });
  };

  return (
    <>
      {/* {paymentMethods ?? ( */}
      <>
        <Box className="MyCardListContainer">
          {/* <Typography variant="h4">My Cards</Typography> */}
          {savedCards.length === 0 && getCards === false ? (
            <>Loading...Please wait.</>
          ) : savedCards.length === 0 && getCards === true ? (
            <>No cards found, please add cards!</>
          ) : (
            <Box className="MyCardList" sx={{width:"calc(100vw - 545px)"}}>
              {savedCards.map((item, index) =>
                index % 2 === 0 ? (
                  <BlueCard
                    key={item.id}
                    cardData={{ index, ...item }}
                    onChange={handleChange}
                    showCardButtons={showCardButtons}
                  />
                ) : (
                  <YellowCard
                    key={item.id}
                    cardData={{ index, ...item }}
                    onChange={handleChange}
                    showCardButtons={showCardButtons}
                  />
                )
              )}
            </Box>
          )}
        </Box>
      </>
    </>
  );
}
