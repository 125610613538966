// Blue cards
import bcVisa from "../Assets/blue-card-visa.png";
import bcAmericanExpress from "../Assets/blue-card-american-express.png";
import bcDinnersclub from "../Assets/blue-card-dinnersclub.png";
import bcJcb from "../Assets/blue-card-jcb.png";
import bcMaster from "../Assets/blue-card-master.png";
import bcDiscover from "../Assets/blue-card-discover.png";
import bcDefault from "../Assets/blue-card-dafault.png";

// Yello Cards
import ycVisa from "../Assets/yellow-card-visa.png";
import ycAmericanExpress from "../Assets/yellow-card-american-express.png";
import ycDinnersclub from "../Assets/yellow-card-dinnersclub.png";
import ycJcb from "../Assets/yellow-card-jcb.png";
import ycMaster from "../Assets/yellow-card-master.png";
import ycDiscover from "../Assets/yellow-card-discover.png";
//import ycDefault from "../Assets/yellow-card-dafault.png";

export const formatTime = (timeInSeconds) => {
  if (timeInSeconds <= 0) {
    return "00";
  }
  const seconds = timeInSeconds % 60;
  return `${seconds.toString().padStart(2, "0")}`;
};

function stringToColor(string, saturation = 70, brightness = 80) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Adjust saturation and brightness
  saturation = Math.min(Math.max(saturation, 0), 100);
  brightness = Math.min(Math.max(brightness, 0), 100);

  const color = `hsl(${hash % 360}, ${saturation}%, ${brightness}%)`;
  return color;
}

export function stringAvatar(name) {
  if (!name || typeof name !== "string") {
    // Handle cases where name is undefined, empty, or not a string
    return {
      sx: {
        bgcolor: "#000", // Default background color
      },
      children: "?", // Default children text
    };
  }

  const nameParts = name.split(" ");

  if (nameParts.length === 1) {
    const firstInitial = (name[0] || "").toUpperCase();
    const secondInitial = (name[1] || "").toUpperCase();

    return {
      sx: {
        bgcolor: stringToColor(name), // Pass 'name' to 'stringToColor' function
      },
      children: `${firstInitial}${secondInitial}`,
    };
  }

  const firstInitial = (nameParts[0][0] || "").toUpperCase();
  const secondInitial = (nameParts[1][0] || "").toUpperCase();

  return {
    sx: {
      bgcolor: stringToColor(name), // Pass 'name' to 'stringToColor' function
    },
    children: `${firstInitial}${secondInitial}`,
  };
}

export const cardBrand = (brand, color) => {
  const brandColors = {
    visa: { b: bcVisa, y: ycVisa },
    mastercard: { b: bcMaster, y: ycMaster },
    discover: { b: bcDiscover, y: ycDiscover },
    amex: { b: bcAmericanExpress, y: ycAmericanExpress },
    diners: { b: bcDinnersclub, y: ycDinnersclub },
    jcb: { b: bcJcb, y: ycJcb },
  };

  return brandColors[brand.toLowerCase()]?.[color.toLowerCase()] || bcDefault;
};

export const getFileExtension = (fileName) => {
  const parts = fileName.split(".");
  if (parts.length > 1) {
    return parts[parts.length - 1];
  }
  return ""; // No extension found for the file
};

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("authProviderToken", accessToken);
  } else {
    localStorage.removeItem("authProviderToken");
  }
};
