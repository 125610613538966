/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Image from "../../Assets/app-icon.png"; // Make sure to import your Image component
import { socket } from "./Message";
import { useSharedState } from "src/utils/SharedStateProvider";
const CoPilot = ({ examples, chat, setChat, clickSend, setClickSend }) => {
  const {jobId}= useSharedState();
  const getToken = () => localStorage.getItem("authProviderToken");
  const token = getToken();
  useEffect(()=>{
    socket.auth = { token };
    if(socket.connected)
    {
    console.log("Socket connecting...");
    socket.emit("joinRoom", parseInt(jobId));
    }
  },[])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      style={{
        width: "min(1200px,100%)",
        margin: "auto",
        marginTop: "4%",
      }}
    >
      {/* Header Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
          spaceY: 4,
          width: "80%",
          md: "66.66667%",
          lg: "50%",
        }}
      >
        <img
          src={Image}
          alt="Gateway Digital"
          className="co-pilot_icon_img"
          style={{ borderRadius: "50%", marginBottom: "0.5em" }}
        />
        <Typography
          variant="h5"
          fontWeight="bold"
          color="text.primary"
          textAlign="center"
        >
          Yemo AI Co-Pilot
        </Typography>
        <Typography variant="body" color="text.secondary" textAlign="center" px="15%" mt={1}>
          Co-Pilot: Unleash the Future of Job Creation. Seamlessly craft jobs
          with the precision of AI interactions, redefining how you shape your
          professional endeavors.
          <br />
          <Typography variant="body" color="text.secondary" textAlign="center" mt={1}>
          Powered by{" "}
          <a
            href="https://thegatewaycorp.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium underline underline-offset-4 transition-colors hover:text-black"
            style={{textDecoration:"none", color:"orange"}}
          >
            Gateway Group
          </a>
          </Typography>
        </Typography>
      </Box>

      {/* Buttons Grid */}
      <Grid container spacing={3} sx={{ width: "75%" }}>
        {examples.map((example, i) => (
          <Grid item key={example} xs={12} xl={6}>
            <Button
              variant="outlined"
              onClick={() => {
                setChat(example.content);
                setClickSend(true)
              }}
              className="Co-PilotText"
              sx={{
                paddingY: "16px",
                borderRadius: "15px",
                textDecoration: "none",
                border: "solid 1px #DDDDDD",
                background: "white",
                color: "grey",
                width: "100%", // Set a fixed width for the Button
                "&:hover": { color: "grey", border: "solid 1px grey" },
                fontFamily: "ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
              }}
            >
              {example.content.length > 50
                ? `${example.content.slice(0, 70)}...`
                : example.content}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoPilot;
