import * as React from "react";
import Box from "@mui/material/Box";
import logo from "../../Assets/512_-removebg-preview1.png";
import "./LoginHeader.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export default function LoginHeader({ isHome = false }) {
  return (
    <Box className="LoginHeader">
      <Link to="/">
        <img src={logo} alt="logo" height={28} />
      </Link>

      {!isHome && (
        <Box>
          <Link to={"/signin"}>
            <Button variant="text">Login</Button>
          </Link>
          <Link to={"/signup"}>
            <Button variant="contained" className="signup">
              Sign up
            </Button>
          </Link>
        </Box>
      )}
    </Box>
  );
}
