/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ElementWrapper from "src/components/payment/ElementWrapper";
import PaymentSummary from "src/components/payment/PaymentSummary";
import { paymentAPI } from "src/services/api";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { useSharedState } from "src/utils/SharedStateProvider";
import Loading from "./Loading";
import MyDefaultCard from "src/components/CardDetails/MyDefaultCard";
import { useJobSharedState } from "src/utils/jobStateProvider";

const PaymentComponent = () => {
  const [savedCards, setSavedCards] = useState([]);
  const [newLoading, setNewLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [jobPrice, setJobPrice] = useState();
  const {
    setLoading,
    setChatLoading,
    setPaymentLoadScroll,
    showCardComponent,
    setShowCardComponent,
    walletBalance,
  } = useSharedState();
  const { setCustomerId, paymentMethodId, setPaymentMethodId, showRechargeWallet, setShowRechargeWallet } =
    usePaymentState();
    const {userJob} = useJobSharedState();
  // setChatLoading(true);
  const fetchCustomerId = async () => {
    try {
      await paymentAPI
        .getPaymentMethodByCustomer()
        .then(async (res) => {
          if (res.data.success === true) {
            setCustomerId(
              res.data.data.customerId ? res.data.data.customerId : null
            );
            setSavedCards([]);
            if (res.data.data.paymentMethods.length > 0) {
              res.data.data.paymentMethods.forEach(
                ({ billing_details, card, id }, index) => {
                  setSavedCards((previousItem) => [
                    ...previousItem,
                    {
                      id: id,
                      cardName: billing_details.name,
                      last4: card.last4,
                      exp_month: card.exp_month,
                      exp_year: card.exp_year,
                      brand: card.brand,
                      isDefault:
                        res.data.data.defaultPaymentMethod === id
                          ? async () => {
                              await setPaymentMethodId(id);
                              return true;
                            }
                          : false,
                    },
                  ]);
                }
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // setChatLoading(false);
          toast.error("Something went wrong!!");
        })
        .finally(() => {
          setPaymentLoading(false);
          setNewLoading(false);
          setLoading(false);
          setChatLoading(false);
          setPaymentLoadScroll(true);
        });
    } catch (error) {
      setPaymentLoading(false);
      setChatLoading(false);
      setPaymentLoadScroll(true);
      toast.error("Something went wrong!!");
      console.log("Error fetching customer ID:", error);
    }
  };

  useEffect(()=>{
    if (parseFloat(walletBalance) < jobPrice && userJob.jobStatus === 'pending') {
      setShowRechargeWallet(true);
    }
    else{
      setShowRechargeWallet(false);
    }
    console.log(walletBalance < jobPrice);
  },[walletBalance,jobPrice])
  useEffect(() => {
    // setChatLoading(true);
    // Fetch customer ID from your server/database and update the state
    setShowForm(false);
    fetchCustomerId();
  }, [paymentMethodId]);

  if (newLoading) {
    return <Loading />;
  }
 console.log(walletBalance < jobPrice);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowForm(!showForm);
  };

  return (
    <>
      {" "}
      {paymentLoading ? (
        <Loading />
      ) : (
        <>
          {savedCards.length === 0 ? (
            <ElementWrapper />
          ) : (
            <>
            {walletBalance < jobPrice ?
              <Box className="MyCardContainer MyCardContainerChatArea" sx={{display:"none"}}>
              <MyDefaultCard
                savedCards={savedCards}
                handleSubmit={handleSubmit}
                showForm={showForm}
              />
              {showForm ? <ElementWrapper /> : ""}
            </Box>            
            : <></>
              
            }

              <Box sx={{ display:showRechargeWallet ? 'none' : ''}}>
              <PaymentSummary paymentId={paymentMethodId}  setJobPrice={setJobPrice}/>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentComponent;
