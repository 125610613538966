import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { forgotPasswordSchema } from "../../validationSchema/forgotSchema";
import LoginFooter from "../LoginFooter/LoginFooter";
import LoginHeader from "../LoginHeader/LoginHeader";
import { toast } from "react-toastify";
import { useSharedState } from "src/utils/SharedStateProvider";
import { userAPI } from "src/services/userApis";

export const PasswordRecovery = () => {
  const { setLoading } = useSharedState();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    password: "",
    email: sessionStorage.getItem("email"),
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [touchedFields, setTouchedFields] = useState([]);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const validateForm = () => {
    const { error } = forgotPasswordSchema.validate(value, {
      abortEarly: false,
    });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValue((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    validateForm();
  };
  const handleBlur = (event) => {
    const { name } = event.target;
    if (!touchedFields.includes(name)) {
      setTouchedFields([...touchedFields, name]);
    }
    validateForm();
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === value.password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm() && passwordsMatch) {
      userAPI
        .resetPassword(value)
        .then((res) => {
          setLoading(false);
          if (res.success === true) {
            toast.success(res.body.message);
            navigate("/resetpasswordsuccess");
          } else {
            toast.error(res.body.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      <Box
        className="LoginPage"
        sx={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
      >
        <LoginHeader />
        <Box className="SignIn">
          <Grid
            container
            sx={{
              background: "white",
              borderRadius: "20px",
              maxWidth: "564px",
              margin: "0 auto",
            }}
            className="ForgotPasswordBox"
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                p: 2,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", textAlign: "center" }}
                >
                  Password Recovery
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", textAlign: "center" }}
                >
                  Time to set a new password
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="New Password*"
                    error={
                      touchedFields.includes("password") && !!errors.password
                    }
                    helperText={
                      touchedFields.includes("password") ? errors.password : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mt: 2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff sx={{ color: "#0F485B" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    label="Retype New Password*"
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                    value={confirmPassword}
                    sx={{ mt: 2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff sx={{ color: "#0F485B" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormGroup>
                {!passwordsMatch && (
                  <p className="error-message">Passwords do not match.</p>
                )}
                {/* {passwordsMatch && <p>Passwords match.</p>} */}
                <Button
                  type="submit"
                  variant="contained"
                  className="LoginButton"
                  sx={{ width: "100%", mt: 2 }}
                >
                  Reset Password
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>
        <LoginFooter />
      </Box>
    </>
  );
};
