/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./PrivacyTerms.css";
import { toast } from "react-toastify";
import { adminApi } from "src/services/api";
import { useSharedState } from "src/utils/SharedStateProvider";
import MobileProfileBackBtn from "./MobileProfileBackBtn";

export default function PrivacyTerms() {
  const [value, setValue] = useState("1");
  const [privacy, setPrivacy] = useState({});
  const [terms, setTerms] = useState({});
  const { setSelectedComponent } = useSharedState();
  useEffect(() => {
    getPrivacyTerms("privacy")
      .then((res) => {
        setPrivacy(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
    getPrivacyTerms("terms")
      .then((res) => {
        setTerms(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const privacyTabs = [
    { label: "Privacy Policy", value: "1" },
    { label: "Terms & Conditions", value: "2" },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPrivacyTerms = (name) => {
    return adminApi.getStaticData({ name });
  };

  useEffect(() => {
    setSelectedComponent("Privacy & Terms");
  }, []);
  return (
    <Box className="profilepage">
      {/* <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" }}>
        <Link to="..">
          <Button sx={{ minWidth: "40px", height: "40px" }}>
            <ChevronLeftIcon />
          </Button>
        </Link>
        Privacy, Terms & Conditions
      </Box> */}
      <MobileProfileBackBtn />
      <Divider />
      <TabContext value={value}>
        <Box sx={{ borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {privacyTabs.map((obj) => (
              <Tab label={obj.label} value={obj.value} key={obj.value} />
            ))}
          </TabList>
        </Box>
        <TabPanel value="1" className="TabContent">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              {
                <Box
                  dangerouslySetInnerHTML={{ __html: privacy.data }}
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: { fontSize: "14px" },
                  })}
                />
              }
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2" className="TabContent">
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              {
                <Box
                  dangerouslySetInnerHTML={{ __html: terms.data }}
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: { fontSize: "14px" },
                  })}
                />
              }
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
      {/* <Divider /> */}
    </Box>
  );
}
