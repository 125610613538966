import * as React from "react";
import { Box } from "@mui/system";
import "./ActionButtons.css";
import { useJobSharedState } from "src/utils/jobStateProvider";
import InfoAlerts from "./InfoAlerts";
import DeleteConfirmationPopup from "../DeleteConfirmationPopUp/DeleteConfirmationPopUp";
import { Link } from "react-router-dom";

const ActionButtons = ({ isEditing, setIsEditing, value, save, setSave }) => {
  const { userJob } = useJobSharedState();
  const role = parseInt(localStorage.getItem("role"));
  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  const handleSaveTrue = () => {
    setSave(!save);
  };
  return (
    <div>
      <Box className="ActionButtonsCnt">
        {role === 1 && value === "1" ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {userJob.jobStatus !== "pending" || userJob.submittedContract ? (
                <Box sx={{ textAlign: "right" }}>
                  <Link href="#">Edit</Link>
                </Box>
              ) : isEditing ? (
                <Box sx={{ textAlign: "right" }}>
                  <Link href="#" onClick={handleSaveTrue}>
                    save
                  </Link>
                </Box>
              ) : (
                <Box sx={{ textAlign: "right" }}>
                  <Link href="#" onClick={handleEditToggle}>
                    Edit
                  </Link>
                </Box>
              )}

              <Box sx={{ marginBottom: "10px", textAlign: "right" }}>
                <DeleteConfirmationPopup />
              </Box>
            </Box>
          </>
        ) : (
          ""
        )}

        {/* {parseInt(role) === 1 ? (
          <Box>{userJob.submittedContract === false && userJob.price ? <PopUp /> : ""}</Box>
        ) : (
          <Box>
            {!rejectJob && userJob.jobStatus === "waiting" ? (
              <AcceptRejectButtons />
            ) : (
              ""
            )}
          </Box>
        )}

        {userJob.jobStatus === "inprogress" &&
        role === 2 &&
        userJob.markAsComplete === false && showMarkAsComplete ? (
          <ProjectCompleteConfirmation setShowMarkAsComplete={setShowMarkAsComplete}/>
        ) : (
          ""
        )}
        {userJob.jobStatus === "inprogress" &&
        role === 1 &&
        userJob.markAsComplete  ? (
          <ProjectComplete setShowMarkAsComplete={setShowMarkAsComplete} />
        ) : (
          ""
        )} */}
        <InfoAlerts></InfoAlerts>

        <Box
      >
        <Link
         to="/PrivacyPolicy"
          underline="none"
          target="_blank"
          style={{textDecoration:"none"}}
          sx={{ color: "#999999", fontSize: "14px", pr: 2, }}
        >
          Policies
        </Link>
        <Link
          to="/TermsAndConditions"
          underline="none"
          target="_blank"
          style={{textDecoration:"none"}}
          sx={{ color: "#999999", fontSize: "14px", pr: 2 }}
        >
          Terms
        </Link>
        <Link
          to="/ContactUs"
          underline="none"
          target="_blank"
          style={{textDecoration:"none"}}
          sx={{ color: "#999999", fontSize: "14px" }}
        >
          Contact Us
        </Link>
      </Box>
      </Box>
    </div>
  );
};

export default ActionButtons;
