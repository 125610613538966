/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import "./Main.css";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { useSharedState } from "src/utils/SharedStateProvider";
import AviIcon from "../../Assets/avi-icon.png";
import { baseURLs } from "src/constants/api.constants";
import LinkIconRecording from "../../Assets/link-icon.svg";
import "./MeetingRecording.css";

import LinkIcon from "../../Assets/link-variant.svg";
// import MeetingIcon from "../../Assets/link-variant.png";
import "./MeetingLinks.css";
import { Link } from "react-router-dom";
import { socket } from "./Message";
import { useEffect } from "react";
import { getFileExtension } from "src/utils/helper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import moment from "moment";
export default function RightMessage({
  msg,
  subCategories,
  categories,
  handleClick,
  filteredMessages,
}) {
  const { userJob, meetingStatus, setJoinButtonClicked, setMeetingStatus } =
    useJobSharedState();
  const { jobId  ,highlightedMessage ,highlightedMessageRef} = useSharedState();
  const userId = parseInt(localStorage.getItem("userId"));
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  useEffect(()=>{
    if(highlightedMessageRef.current)
    {
      highlightedMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  },[highlightedMessage])

 const isMessageHighlighted = filteredMessages&&filteredMessages.some(filteredMsg => filteredMsg.id === msg.id);

  React.useEffect(() => {
    if (userJob.category) {
      setSelectedCategory(userJob.category);
    } else {
      setSelectedCategory(null);
    }
    if (userJob.subCategory) {
      setSelectedSubCategory(userJob.subCategory);
    } else {
      setSelectedSubCategory(null); // Fix the function call here
    }
  }, [jobId, userJob]);
  const handleSetCategory = (name) => {
    setSelectedCategory(name);
  };
  const handleSetSubCategory = (name) => {
    setSelectedSubCategory(name);
  };
  const handelJointMeeting = (link) => {
    window.open(link, "_blank");

    // Check if the interval has already started
    const regex = /meetingID=([^&]+)/; // Match anything that's not an ampersand (&)
    const match = regex.exec(link);

    if (match) {
      const meetingId = match[1];

      if (meetingId && !meetingStatus) {
        const data = {
          meetingId,
          jobId,
        };

        // Listen for the "getmeetingStatus" socket event
        socket.on("getmeetingStatus", (data) => {
          setMeetingStatus(data.data);

          if (data.data === true) {
            setJoinButtonClicked(false);
            // If the meeting is over, clear the interval and remove the event listener
            clearInterval(interval);
            socket.off("getmeetingStatus");
            socket.off("meetingStatus");
          }
        });

        // Start a setInterval to periodically check meeting status
        const interval = setInterval(() => {
          socket.emit("meetingStatus", data);
          if (meetingStatus) {
            setJoinButtonClicked(false);
            clearInterval(interval); // Stop the interval
          }
        }, 15000); // 5 minutes in milliseconds
      }
    }
  };
  useEffect(() => {
    if (meetingStatus) {
      setJoinButtonClicked(false);
    }
  }, [meetingStatus]);  
  return (
    <>
      {msg !== undefined ? (
        <Box>
          <Box className="rightMessage" ref ={highlightedMessage === msg.id ? highlightedMessageRef : null}>
            {msg.type === "text" ? (
              <Typography variant="p" style={{ whiteSpace: "pre-wrap", backgroundColor: isMessageHighlighted ? "yellow" : ""
                // background: highlightedMessage === msg.id ? "yellow" : ""
               }}>
                {msg.content}
              </Typography>
            ) : msg.type === "options" &&
              msg.content === "Select a subCategory for your job" ? (
              <>
                <Typography variant="p">{msg.content}</Typography>
                <Box className="chipGroup">
                  {subCategories.map((subCategory) => (
                    <Chip
                      className={
                        subCategory.name === selectedSubCategory
                          ? "MuiChip-root-selected"
                          : ""
                      }
                      key={subCategory.id}
                      label={subCategory.name}
                      onClick={() => {
                        handleSetSubCategory(subCategory.name);
                        handleClick(subCategory.id);
                      }}
                      component="a"
                      href="#basic-chip"
                      variant="outlined"
                      clickable
                      sx={{ background: "#eee"}}
                    />
                  ))}
                </Box>
              </>
            ) : msg.type === "options" &&
              msg.content === "Select a category for your job" ? (
              <>
                <Typography variant="p">{msg.content}</Typography>
                <Box className="chipGroup">
                  {categories.map((category) => (
                    <Chip
                      className={
                        selectedCategory === category.name
                          ? "MuiChip-root-selected"
                          : ""
                      }
                      key={category.id}
                      label={category.name}
                      onClick={() => {
                        handleSetCategory(category.name);
                        handleClick(category.id);
                      }}
                      component="a"
                      href="#basic-chip"
                      variant="outlined"
                      clickable
                      sx={{ background: "#eee"}}
                    />
                  ))}
                </Box>
              </>
            ) : msg.type === "reject" ? (
              <>
                {msg.content.split("title").map((line, lineIndex) => (
                  <Typography variant="p" key={line}>
                    {lineIndex === 0 ? (
                      line
                    ) : line.includes(": -") ? (
                      <span>
                        title: -{line.split(": -")[1].split("decription")[0]}
                        <br />
                        description: -{line.split(": -")[1]}
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                ))}
              </>
            ) : msg.type === "file" ? (
              <>
                <Box className="rightMessage" >
                  
                  <Typography variant="p">
                    <Link style={{color:"black", textDecoration:"none", display:'flex', alignItems:'center'}} target="_blank" to={`${baseURLs.yemo}/${msg.file}`} >
                    {msg.file === "" ? (
                      <img src={AviIcon} alt="aviIcon" />
                    ) : (
                      <a
                        className="img-icon"
                        href={`${baseURLs.yemo}/${msg.file}`}
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        <img
                          src={AviIcon}
                          alt="aviIcon"
                          height="40px"
                          width="30px"
                        />
                        <p className="img-icon-text">
                          {getFileExtension(msg.content)}
                        </p>
                      </a>
                    )}
                    <span>{msg.content}</span>
                    </Link>
                  </Typography>
                </Box>
              </>
            ) : msg.type === "link" ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box className="MeetingLinks">
                  <Box className="LinkTxt">
                    <Avatar>
                      <img src={LinkIcon} alt="link" />
                    </Avatar>
                    <Typography variant="p">Meeting Link</Typography>
                  </Box>
                  <Button
                    variant="contained"
                    className="join-btn"
                    onClick={() => {
                      handelJointMeeting(msg.file);
                    }}
                  >
                    Join Now
                  </Button>
                </Box>
              </Box>
            ) : msg.type === "recording" ? (
              <Box sx={{display: "flex", justifyContent: "flex-end", borderRadius:"10px"}}>
                <Box className="MeetingRecording">
                  <Link
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      window.open(msg.file, "_blank");
                    }}
                  >
                    <Box className="LinkTxt">
                      <Avatar>
                        <img src={LinkIconRecording} alt="LinkIconRecording" />
                      </Avatar>
                      <Box>
                        <Typography variant="p">Meeting Recording</Typography>
                        <Typography
                          variant="span"
                        >
                          {msg.content}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                </Box>
            ) : msg.type === "meeting" ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box className="Meeting">
                  <Box className="MeetingLinkTxt" sx={{ paddingBottom: "2%" }}>
                  <Avatar>
                      <img src={LinkIcon} alt="link" />
                    </Avatar>
                    <Typography variant="p" sx={{ color: "white" }}>
                      {msg.meetingData[0]?.title}
                    </Typography>
                  </Box>
                  <Box className="MeetingLinkTxt">
                    <AccessTimeIcon />
                    &nbsp;
                    {msg.meetingData[0]?.duration} &nbsp;&nbsp;&nbsp;
                    {msg.meetingData[0]?.start ? (
                      <>
                        <EventAvailableIcon />
                        {moment(msg.meetingData[0]?.start).format(
                          "MM-DD-YYYY | hh:mma"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    className="join-btn"
                    disabled={!msg.file}
                    onClick={() => {
                      if(msg.file !== null){
                        if (parseInt(msg.userId) === parseInt(userId)) {
                          handelJointMeeting(msg.file);
                        } else {
                          handelJointMeeting(msg.content);
                        }
                      }
                    }}
                  >
                    Join Now
                  </Button>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
