/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useRef, useState } from "react";
import { Box, Chip, Divider } from "@mui/material";
import "./Main.css";
import ChatFooter from "./ChatFooter";
import { useSharedState } from "src/utils/SharedStateProvider";
import io from "socket.io-client";
import { useJobSharedState } from "../../utils/jobStateProvider";
import LeftMessage from "./LeftMessage";
import RightMessage from "./RightMessage";
import AppAlert from "./AppAlert";
import RejectReasonForm from "../RightSidebar/RejectReasonForm";
import PaymentComponent from "./Payment";
import Loading from "./Loading";
import LeaveJobReasonForm from "../RightSidebar/LeaveJobReasonForm";
import RaiseDispute from "./RaiseDispute";
import { baseURLs } from "src/constants/api.constants";
import logo from "../../Assets/app-icon.png";
import FeedbackForm from "./Feedback";
import MessageProfile from "./MessageProfile";
import RightMessageProfile from "./RightMessageProfile";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import RechargeWallet from "./RechargeWallet";
import { toast } from "react-toastify";
import RejectAlert from "../RightSidebar/RejectAlert";
import Buttons from "./Buttons";
import DeleteConfirmationPopup from "../DeleteConfirmationPopUp/DeleteConfirmationPopUp";
import { adminApi } from "src/services/api";
import { chatAPI } from "src/services/jobApis";
import CoPilot from "./CoPilot";
import { useNavigate } from "react-router-dom";
//import GoogleLoginComponent from "./GoogleLogin";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ResourceTable from "./ResourceTable";
import CustomerDetailsForm from "./CustomerDetailsForm";
const getToken = () => localStorage.getItem("authProviderToken");
const token = getToken();

export const socket = io(baseURLs.ip, {
  autoConnect: true,
  auth: {
    token: token,
  },
});

const checkDate = (inputDateStr) => {
  // Convert input date string to Date object
  const inputDate = new Date(inputDateStr);
  inputDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

  // Get today's date
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  // Check if it's today
  if (inputDate.getTime() === todayDate.getTime()) {
    return "Today";
  }

  // Get yesterday's date
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(todayDate.getDate() - 1);

  // Check if it's yesterday
  if (inputDate.getTime() === yesterdayDate.getTime()) {
    return "Yesterday";
  }

  // If neither today nor yesterday, you can return the original date or any other label as needed
  return inputDateStr;
};

function renderMessageComponent(msg, job, questionId) {
  if (msg.type === "success") {
    return <AppAlert content={msg.content} severity="success" />;
  } else if (msg.type === "dispute") {
    return <AppAlert content={msg.content} severity="error" />;
  } else if (msg.type === "notes") {
    return <AppAlert content={msg.content} severity="warning" />;
  } else if (msg.type === "payment") {
    return (
      <>
        <LeftMessage job={job} key={msg.id} msg={msg} questionId={questionId} />
      </>
    );
  } else if (msg.type === "date") {
    return (
      <Divider className="DateDivider">
        <Chip label={checkDate(msg.content)} sx={{ background: "#eee" }} />
      </Divider>
    );
  } else {
    // Default case
    return null;
  }
}

export default function Message() {
  const [isTyping, setIsTyping] = useState(false);
  const getToken = () => localStorage.getItem("authProviderToken");
  const token = getToken();
  const [displayOrder, setDisplayOrder] = useState(0);
  const [questionId, setQuestionId] = useState(0);
  const [messages, setMessages] = useState({});
  const [clickSend, setClickSend] = useState(false);
  const {
    jobId,
    chatLoading,
    setChatLoading,
    paymentLoadScroll,
    setPaymentLoadScroll,
    confirmPayment,
    setConfirmPayment,
    setGetJobs,
    setLastMessage,
    feedbackSubmit,
    setFeedbackSubmit,
    setUpdatedRightSideBar,
    highlightedMessageRef,
    setHighlightedMessage,
    showChatHeader,
    setShowChatHeader,
    jobIds,
    gotJobs,
    setGotJobs,
  } = useSharedState();
  const { showRechargeWallet, setShowRechargeWallet } = usePaymentState();
  const [job, setJob] = useState({});
  const [jobTyping, setJobTyping] = useState(false);
  const userId = localStorage.getItem("userId");
  // const [ isChanged,setIsChanged] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const { updateJob, userJob, dispute, setUserJob } = useJobSharedState();
  //console.log("i am userJob",userJob);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const {
    rejectJob,
    showLeaveJobForm,
    showRejectReasonForm,
    disputeSuccess,
    setDisputeSuccess,
    submitContract,
    setSubmitContract,
  } = useJobSharedState();
  const role = parseInt(localStorage.getItem("role"));
  const isVisibleRef = useRef(true);
  const chatContainerRef = useRef(null);
  const {
    setNotificationCouter,
    openDelete,
    hideRightSideBar,
    setHideRightSideBar,
    search,
    searchMessage,
    setHighlightedIndex,
    setTotalFound,
    filteredMessages,
    setFilteredMessages,
    setShowSiderBar,
  } = useSharedState();


  const [lastMessage1, setLastMessage1] = useState("");
  const [oneMessageChatloading, setOneMessageChatloading] = useState(false);
  const [chat, setChat] = useState("");
  const [update, setUpdate] = useState(false);
  // const [areThereMessages,setAreThereMessages] = useState(true);
  const [questionLoading, setQuestionLoading] = useState(false);
  useEffect(() => {
    const handleFocus = () => {
      isVisibleRef.current = true;
    };
    const handleBlur = () => {
      isVisibleRef.current = false;
    };
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [chatLoading]);
  const fetchPreviousMessages = (jobId) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [jobId]: [],
    }));
    if (jobId !== null) {
      chatAPI
        .getMessagesByJobId({ jobId: jobId })
        .then(async (res) => {
          if (res.data.success === true) {
            const response = res.data.data;
            setJob(res.data.data.job);
            await updateJob(res.data.data.job);
            if (response.job.categoryId) {
              getSubCategories(response.job.categoryId);
            }
            const fetchedMessages = response.messages.map((msg) => ({
              id: msg.id,
              content: msg.content,
              userId: msg.userId,
              created_at: msg.createdAt,
              read: msg.markAsRead,
              isActive: msg.isActive,
              type: msg.type,
              name: msg.user.name,
              profileImage: msg.user.profileImage,
              file: msg.file,
              meetingData: msg.meetingData,
              meetingSchedulers: msg.meetingSchedulers,
              messageFiles: msg.messageFiles,
            }));
            setMessages((prevMessages) => ({
              ...prevMessages,
              [jobId]: fetchedMessages,
            }));
            // console.log("fetchedmessages", fetchedMessages);
            // if(fetchedMessages.length = 0){
            //   setAreThereMessages(false)
            // }
            // else{
            //   setAreThereMessages(true)
            // }
          }

          setChatLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    }
  };
  const fetchedJob = async () => {
    if (jobId) {
      await socket.emit("updateJob", jobId);
    }
  };

  useEffect(() => {
    setQuestionId(0);
    setDisplayOrder(0);
    setJob({});
    if (jobId !== job.id) {
      fetchedJob();
    }
  }, [jobId]);

  const getCategories = async () => {
    adminApi
      .getAllCategories()
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!");
      });
  };
  const getSubCategories = async (categoryId) => {
    adminApi
      .getSubCategories(categoryId)
      .then((res) => {
        setSubCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!");
      });
  };
  const handleClick = async (id) => {
    if (displayOrder === 2 || displayOrder === 3 || displayOrder === 4 || displayOrder === 10) {
      adminApi
        .askQuestionYemoAI({ reply: id, displayOrder }, jobId)
        .then((res) => {
          setQuestionId(res.data.data?.question[0].id);
          setDisplayOrder(res.data.data?.question[0].displayOrder);
          fetchPreviousMessages(jobId);
          fetchedJob();
          setChatLoading(true);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        });
    }
  };
  useEffect(() => {
    if (categories.length === 0) {
      getCategories();
    }
  }, []);
  useEffect(() => {
    setDisabled(false);
    if (jobId) {
      fetchedJob();
      fetchPreviousMessages(jobId);
      adminApi
        .askQuestionYemoAI({}, jobId)
        .then((res) => {
          if (res.data.data !== "") {
            if (
              res.data.data?.created ||
              res.data.data?.question[0].displayOrder === 8
            ) {
              setDisabled(true);
            } else {
              setQuestionId(res.data.data?.question[0].id);
              setDisplayOrder(res.data.data?.question[0].displayOrder);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);
  // const handleRoomJoined = (JobId) => {
  //   console.log("you are connect to that room", JobId);
  // };
  useEffect(() => {
    fetchedJob();
    if (jobId) {
      socket.emit("notificationCouter");
      socket.emit("joinRoom", parseInt(jobId));
      const handleRoomJoined = async (JobId) => {
        console.log("you are connect to that room", JobId);
      };
      socket.on("roomJoined", handleRoomJoined);
      return () => {
        socket.off("roomJoined", handleRoomJoined);
      };
    }
  }, [jobId]);

  const openWebsite = () => {
    window.open(`https://yemo.ai/dashboard/chat/${jobId}`);
  };

  const receiveMessageHandler = async ({
    messageData,
    messageData1,
    lastMessage,
    message,
  }) => {
    // Create a new message object if messageData1 contains new message data
    if (messageData1) {
      function mergeProperty(property, data1, data2) {
        return data1 && data1.hasOwnProperty(property)
          ? data1[property]
          : data2[property];
      }

      const newMessage = {
        created_at: mergeProperty("createdAt", messageData1.data, messageData1),
        userId: mergeProperty("userId", messageData1.data, messageData1),
        content: mergeProperty("content", messageData1.data, messageData1),
        isActive: mergeProperty("isActive", messageData1.data, messageData1),
        type: mergeProperty("type", messageData1.data, messageData1),
        id: mergeProperty("id", messageData1.data, messageData1),
        read: mergeProperty("markAsRead", messageData1.data, messageData1),
        file: mergeProperty("file", messageData1.data, messageData1),
        name: mergeProperty("name", messageData1.user, messageData1),
        profileImage: mergeProperty(
          "profileImage",
          messageData1.user,
          messageData1
        ),
      };
      // Merge the new message with existing messages
      setMessages((prevMessages) => {
        const jobIdMessages = prevMessages[jobId] || []; // Get existing messages or initialize as an empty array
        const updatedMessages = [...jobIdMessages, newMessage];
        return {
          ...prevMessages,
          [jobId]: updatedMessages,
        };
      });

      setOneMessageChatloading(false);
      setIsTyping(false);
      // Display a notification if the window is not in focus
      if (!isVisibleRef.current) {
        if (job.name)
          new Notification(job.name, {
            body: messageData1.data.content,
            icon: logo,
          }).onclick = openWebsite;
      }
    }

    if (lastMessage || messageData) {
      fetchPreviousMessages(jobId);
      setUpdatedRightSideBar(true);
      setChatLoading(false);
      setUpdate(true);
    }
  };
  useEffect(() => {
    if (update) {
      setUpdatedRightSideBar(true);
    }
  }, [update]);
  const sendmessageListener = ({ message, title }) => {
    new Notification(title, {
      body: message,
      icon: logo,
    }).onclick = openWebsite;
  };
  useEffect(() => {
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    if (token != null) {
      // if (socket.connected) {
      //   socket.disconnect();
      // }
      // socket.auth = { token };
      try {
        socket.connect();
        console.log("Socket connecting...");

        socket.on("sendmessage", sendmessageListener);
        socket.on("receiveMessage", receiveMessageHandler);
        socket.on("questionReceived", async (nextQuestion) => {
          console.log("nextQuestion", nextQuestion);

          if (nextQuestion.data !== "") {
            setQuestionId(nextQuestion.data?.question[0].id);
            setDisplayOrder(nextQuestion.data?.question[0].displayOrder);
            await fetchedJob();
          }
          setQuestionLoading(false);
        });
        socket.on("updatedJob", async (data) => {
          await updateJob(data);
          await setUserJob(data);
          await setJob(data);
        });
        socket.on("updateSideber", async (jobId) => {
          await fetchedJob();
          fetchPreviousMessages(jobId);
        });
        socket.on("userTyping", ({ data }) => {
          console.log("typing", data);
          setIsTyping(data.isTyping);
        });
        socket.on("userTypingJob", (data) => {
          setJobTyping(data.isTyping);
        });
        socket.on("notification", ({ data }) => {
          setNotificationCouter(data);
        });
        return () => {
          socket.off("receiveMessage", receiveMessageHandler);
          socket.off("sendmessage", sendmessageListener);
          socket.off("userTyping");
          socket.off("updatedJob");
          socket.off("updateSideber");
        };
      } catch (error) {
        console.error("Socket connection error:", error);
        // Handle the error as needed, such as displaying an error message to the user.
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, jobId]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      requestAnimationFrame(() => {
        container.scrollTop = container.scrollHeight;
      });
    }
  };

  useEffect(() => {
    if (submitContract) {
      setSubmitContract(false);
      setChatLoading(true);
    }
    scrollToBottom();
    if (paymentLoadScroll) {
      setPaymentLoadScroll(false);
    } // Scroll to bottom when messages change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    job,
    messages,
    rejectJob,
    showLeaveJobForm,
    showRejectReasonForm,
    submitContract,
    paymentLoadScroll,
  ]);

  const lastIndexUser1 = messages[jobId]?.reduceRight(
    (lastIndex, message, currentIndex) => {
      if (lastIndex === -1 && message.userId === 1) {
        return currentIndex;
      }
      return lastIndex;
    },
    -1
  );
  useEffect(() => {
    if (disputeSuccess === true) {
      console.log("dispute state");
      fetchPreviousMessages(jobId);
      fetchedJob();
      setDisputeSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disputeSuccess]);

  useEffect(() => {
    fetchedJob();
    scrollToBottom();
    if (feedbackSubmit) {
      setFeedbackSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackSubmit]);
  useEffect(() => {
    if (confirmPayment) {
      setConfirmPayment(false);
      setSubmitContract(false);
      console.log("conformPayment");
      fetchPreviousMessages(jobId);
      fetchedJob();
      setGetJobs(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPayment]);

  const getLastTextOrFileMessageContent = (messages) => {
    return (
      messages.reduceRight((lastContent, message) => {
        if (!lastContent) {
          return message.content;
        }
        return lastContent;
      }, null) || "No message"
    ); // Change this as needed for the default value.
  };

  const getlastmessagetype = (messages) => {
    return (
      messages.reduceRight((lastContent, message) => {
        if (!lastContent) {
          return message.type;
        }
        return lastContent;
      }, null) || "No message"
    ); // Change this as needed for the default value.
  };
  useEffect(() => {
    if (messages[jobId]) {
      const lastMessageContent = getLastTextOrFileMessageContent(
        messages[jobId]
      );
      setLastMessage(lastMessageContent);
      const lastmesagetype = getlastmessagetype(messages[jobId]);
      setLastMessage1(lastmesagetype);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages[jobId]]);
  const classNameBox = chatLoading
    ? "MessageArea overflow-y-hidden"
    : Array.isArray(messages[jobId]) &&
      messages[jobId].length === 0 &&
      job.coPilot
    ? "copilot-messagearea MessageArea"
    : "MessageArea";

  useEffect(() => {
    if (showRechargeWallet) {
      setShowRechargeWallet(false);
    }
  }, [jobId]);
  useEffect(() => {
    if (searchMessage) {
      if (typeof messages === "object" && messages !== null) {
        const jobMessages = messages[jobId];

        // Check if jobMessages is an array
        if (Array.isArray(jobMessages)) {
          const filteredMessages = jobMessages
            .map((message, index) => ({
              ...message,
              ref: createRef(),
            }))
            .filter(
              (message) =>
                message?.content?.includes(searchMessage) &&
                message.type === "text"
            );
          setTotalFound(filteredMessages.length);
          setHighlightedIndex(filteredMessages.length - 1);
          setHighlightedMessage(
            filteredMessages[filteredMessages.length - 1]?.id
          );
          setFilteredMessages(filteredMessages);
        }
      }
    } else {
      setFilteredMessages([]);
      setTotalFound(null);
      // setHighlightedIndex(null)
    }
  }, [searchMessage, messages, jobId]);

  //console.log(messages);

  const examples = [
    {
      content:
        "Seeking an experienced interpreter proficient in Business and Conference Interpreting to facilitate seamless communication during international corporate meetings. The ideal candidate will ensure linguistic clarity, cultural sensitivity, and an adept understanding of complex business discussions.",
    },
    {
      content:
        " Join our team as a Legal Document Translator, ensuring accurate translations of contracts and court documents. The role demands meticulous attention to legal terminology, maintaining confidentiality, and upholding the highest standards of precision.",
    },
    {
      content:
        "Seeking a creative translator for Marketing Materials to enhance global reach. This role involves translating advertising campaigns and brochures, ensuring the promotional content resonates effectively across diverse linguistic and cultural landscapes.",
    },
    {
      content:
        " Join our team as a Remote Video Interpreter, providing language support via secure video calls. This role requires technological proficiency, a commitment to clear communication, and adaptability in facilitating virtual interactions.",
    },
    {
      content:
        "We're hiring a dynamic translator specializing in Community Information to bridge linguistic gaps in our outreach efforts. The role involves translating newsletters, announcements, and community campaigns with precision and cultural relevance, fostering inclusive communication.",
    },
    {
      content:
        "Join our team as a Healthcare Document Translator, specializing in translating crucial medical records. This role demands expertise in medical terminology, ensuring accurate and confidential translations for seamless communication in healthcare settings.",
    },
  ];
  useEffect(() => {
    setOneMessageChatloading(false);
    setQuestionLoading(false);
    if (userJob) {
      console.log(userJob);
      // if(userJob.coPilot && userJob.name === 'yemo' && userJob.jobStatus === "pending"){
      //   setHideRightSideBar(true);
      //   setShowSiderBar(true);
      // }
    }
  }, [jobId]);

  const checkForShowChatHeader = () => {
    // console.log("messages",typeof messages[jobId] , messages[jobId], messages[jobId]?.length, areThereMessages);
    if (
      Array.isArray(messages[jobId]) &&
      messages[jobId].length === 0 &&
      job.coPilot
    ) {
      setShowChatHeader(false);
      setShowSiderBar(true);
      setHideRightSideBar(true);
    } else {
      if (!showChatHeader) {
        setShowChatHeader(true);
        setShowSiderBar(false);
        setHideRightSideBar(false);
      }
    }
  };

  useEffect(() => {
    checkForShowChatHeader();
  }, [messages, jobId]);

  useEffect(() => {
    if (jobIds && jobId && gotJobs) {
      console.log(
        "find",
        jobIds.find((id) => parseInt(id) === parseInt(jobId))
      );
      setGotJobs(false);
      if (jobIds.find((id) => parseInt(id) === parseInt(jobId))) {
        console.log("found");
      } else {
        console.log("notfound");
        // navigate("/dashboard")
      }
    }
  }, [jobIds, jobId]);

  // console.log("jobIds", jobIds);
  return (
    <>
      <Box
        className={classNameBox}
        ref={chatContainerRef}
        sx={{ overflowY: "scroll", position: "relative" }}
        style={{
          position: "relative",
          height: search ? "calc(100vh - 289px)" : "",
        }}
      >
        <button
          onClick={() => {
            setHideRightSideBar(false);
          }}
          className={
            hideRightSideBar
              ? "right_sidebar_open_btn show"
              : "right_sidebar_open_btn"
          }
        >
          <ChevronLeftIcon />
        </button>

        {chatLoading ? (
          <Loading />
        ) : (
          // <div className="dots-5"></div>
          <>
            {Array.isArray(messages[jobId]) &&
            messages[jobId].length === 0 &&
            job.coPilot ? (
              <CoPilot
                examples={examples}
                chat={chat}
                setChat={setChat}
                clickSend={clickSend}
                setClickSend={setClickSend}
              />
            ) : (
              Array.isArray(messages[jobId]) &&
              messages[jobId].length > 0 &&
              messages[jobId].map((msg, index, array) => {
                if (
                  parseInt(role) === 1 &&
                  (msg.type === "text" ||
                    msg.type === "options" ||
                    msg.type === "file" ||
                    msg.type === "link" ||
                    msg.type === "recording" ||
                    msg.type === "reject" ||
                    msg.type === "meeting")
                ) {
                  if (msg.userId === parseInt(userId)) {
                    return (
                      <div key={msg.id}>
                        {index === 1 ||
                        msg.userId !== array[index - 1]?.userId ? (
                          <RightMessageProfile msg={msg} />
                        ) : (
                          ""
                        )}
                        <RightMessage
                          msg={msg}
                          filteredMessages={filteredMessages}
                          highlightedMessageRef={highlightedMessageRef}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={msg.id}>
                        {index === 1 ||
                        msg.userId !== array[index - 1]?.userId ? (
                          <MessageProfile msg={msg} />
                        ) : (
                          ""
                        )}
                        <LeftMessage
                          job={job}
                          msg={msg}
                          categories={categories}
                          subCategories={subCategories}
                          handleClick={handleClick}
                          questionId={questionId}
                          filteredMessages={filteredMessages}
                          highlightedMessageRef={highlightedMessageRef}
                        />
                        {renderMessageComponent(msg, job, questionId)}
                      </div>
                    );
                  }
                } else if (
                  (parseInt(role) === 2 || parseInt(role) === 4) &&
                  (msg.type === "text" ||
                    msg.type === "options" ||
                    msg.type === "file" ||
                    msg.type === "link" ||
                    msg.type === "recording" ||
                    msg.type === "reject" ||
                    msg.type === "meeting")
                ) {
                  if (
                    job.jobStatus === "waiting" ||
                    job.jobStatus === "inprogress" ||
                    job.jobStatus === "completed" ||
                    job.jobStatus === "conflict" ||
                    job.jobStatus === "refund"
                  ) {
                    if (index <= lastIndexUser1) {
                      if (msg.userId === 1) {
                        return (
                          <div key={msg.id}>
                            {index === 0 ||
                            msg.userId !== array[index + 1]?.userId ? (
                              <MessageProfile msg={msg} />
                            ) : (
                              ""
                            )}
                            <LeftMessage
                              job={job}
                              key={msg.id}
                              msg={msg}
                              categories={categories}
                              subCategories={subCategories}
                              handleClick={handleClick}
                              displayOrder={displayOrder}
                              questionId={questionId}
                              filteredMessages={filteredMessages}
                              highlightedMessageRef={highlightedMessageRef}
                            />
                          </div>
                        );
                      } else {
                        if (
                          array[index - 1]?.userId === 1 &&
                          array[index + 1]?.userId === 1
                        ) {
                          return (
                            <div key={msg.id}>
                              {msg.userId !== array[index - 1]?.userId ? (
                                <RightMessageProfile msg={msg} />
                              ) : (
                                ""
                              )}
                              <RightMessage
                                msg={msg}
                                filteredMessages={filteredMessages}
                                highlightedMessageRef={highlightedMessageRef}
                              />
                            </div>
                          );
                        } else if (
                          array[index - 1]?.userId === parseInt(userId) &&
                          array[index + 1]?.userId === parseInt(userId)
                        ) {
                          return (
                            <div key={msg.id}>
                              {msg.userId !== array[index - 1]?.userId ? (
                                <MessageProfile msg={msg} />
                              ) : (
                                ""
                              )}
                              <LeftMessage
                                msg={msg}
                                job={job}
                                key={msg.id}
                                categories={categories}
                                subCategories={subCategories}
                                handleClick={handleClick}
                                questionId={questionId}
                                filteredMessages={filteredMessages}
                                highlightedMessageRef={highlightedMessageRef}
                              />
                            </div>
                          );
                        } else if (msg.userId !== parseInt(userId)) {
                          return (
                            <div key={msg.id}>
                              {" "}
                              {msg.userId !== array[index - 1]?.userId ? (
                                <MessageProfile msg={msg} />
                              ) : (
                                ""
                              )}
                              <LeftMessage
                                msg={msg}
                                job={job}
                                key={msg.id}
                                categories={categories}
                                subCategories={subCategories}
                                handleClick={handleClick}
                                questionId={questionId}
                                filteredMessages={filteredMessages}
                                highlightedMessageRef={highlightedMessageRef}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div key={msg.id}>
                              {" "}
                              {msg.userId !== array[index - 1]?.userId ? (
                                <RightMessageProfile msg={msg} />
                              ) : (
                                ""
                              )}
                              <RightMessage
                                key={msg.id}
                                msg={msg}
                                filteredMessages={filteredMessages}
                                highlightedMessageRef={highlightedMessageRef}
                              />
                            </div>
                          );
                        }
                      }
                    } else {
                      if (msg.userId === parseInt(userId)) {
                        return (
                          <div key={msg.id}>
                            {" "}
                            {msg.userId !== array[index - 1]?.userId ? (
                              <RightMessageProfile msg={msg} />
                            ) : (
                              ""
                            )}
                            <RightMessage
                              msg={msg}
                              filteredMessages={filteredMessages}
                              highlightedMessageRef={highlightedMessageRef}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div key={msg.id}>
                            {" "}
                            {msg.userId !== array[index - 1]?.userId ? (
                              <MessageProfile msg={msg} />
                            ) : (
                              ""
                            )}
                            <LeftMessage
                              job={job}
                              key={msg.id}
                              msg={msg}
                              categories={categories}
                              subCategories={subCategories}
                              handleClick={handleClick}
                              questionId={questionId}
                              filteredMessages={filteredMessages}
                              highlightedMessageRef={highlightedMessageRef}
                            />
                          </div>
                        );
                      }
                    }
                  } else {
                    return (
                      <div key={msg.id}>
                        {msg.userId !== array[index - 1]?.userId ? (
                          <RightMessageProfile msg={msg} />
                        ) : (
                          ""
                        )}
                        <RightMessage
                          msg={msg}
                          filteredMessages={filteredMessages}
                          highlightedMessageRef={highlightedMessageRef}
                        />
                      </div>
                    );
                  }
                } else {
                  return (
                    <div key={msg.id}>
                      {renderMessageComponent(msg, job, questionId)}
                    </div>
                  );
                }
              })
            )}
          </>
        )}
        <div ref={chatContainerRef}>
          {rejectJob ? <RejectReasonForm /> : ""}
          {showRejectReasonForm &&
          role === 1 &&
          job.markAsComplete &&
          job.jobStatus === "inprogress" ? (
            <RaiseDispute />
          ) : (
            ""
          )}
          {displayOrder === 4 ? <ResourceTable handleClick={handleClick}/>:""}
          {displayOrder === 10 ? <CustomerDetailsForm handleClick={handleClick}/>:""}
          {showLeaveJobForm && role === 2 ? <LeaveJobReasonForm /> : ""}
          {/* {job.jobStatus === "pending" && job.submittedContract === true ? (
            <PaymentComponent />
          ) : (
            ""
          )} */}
          {job.jobStatus === "completed" && role !== 4 ? <FeedbackForm /> : ""}
          {/* {showRechargeWallet && userJob.jobStatus === "pending" ? (
            <RechargeWallet />
          ) : (
            ""
          )} */}
          {isTyping && jobTyping ? <div className="dots-5"></div> : ""}
          {questionLoading ? <div className="dots-5"></div> : ""}
          {oneMessageChatloading ? <div className="dots-7"></div> : ""}
          {dispute ? (
            // && hideRightSideBar
            <RejectAlert />
          ) : (
            ""
          )}
          {openDelete ? (
            <Box sx={{ marginBottom: "10px", textAlign: "right" }}>
              <DeleteConfirmationPopup />
            </Box>
          ) : (
            ""
          )}
          {/* <GoogleLoginComponent /> */}
        </div>
      </Box>

      {(userJob.submittedContract === false && userJob.price) ||
      (!rejectJob && userJob.jobStatus === "waiting") ||
      (userJob.jobStatus === "inprogress" &&
        userJob.markAsComplete === false) ||
      (userJob.jobStatus === "inprogress" && userJob.markAsComplete) ? (
        <Box className="actions-container">
          <Box>
            <Box sx={{ borderRadius: "6px" }}>
              <Buttons />
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}

      <ChatFooter
        //   setIsChanged={setIsChanged}
        setDisplayOrder={setDisplayOrder}
        questionId={questionId}
        displayOrder={displayOrder}
        socket={socket}
        disabled={disabled}
        setDisabled={setDisabled}
        job={userJob}
        setChatLoading={setChatLoading}
        setIsTyping={setIsTyping}
        lastMessage={lastMessage1}
        setOneMessageChatloading={setOneMessageChatloading}
        chat={chat}
        setChat={setChat}
        setQuestionLoading={setQuestionLoading}
        clickSend={clickSend}
        setClickSend={setClickSend}
      />
    </>
  );
}
