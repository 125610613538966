import { emailObj } from "./loginSchema";
const Joi = require("joi");

const buyerUpdateProfileSchema = Joi.object({
  name: Joi.string().messages({ "string.empty": `Full Name is required` }),
  bio: Joi.string().max(100).label("Bio").required().messages({"string.empty": "Bio is required",}),
  //allow(null, "").default(null)
  ...emailObj,
  // phone: Joi.string()
  //   .pattern(/^\d{10}$/)
  //   .messages({
  //     "string.pattern.base": "Contact Number must have 10 digits.",
  //     "string.empty": "Contact Number is required",
  //   }),
  skillIds: Joi.array(),
  profileImage: Joi.custom((value, helpers) => {
    // Skip validation for profileImage field
    return value; // Return the value as-is without validation
  }).default(null), // You can provide a default value if needed
});

const sellerUpdateProfileSchema = Joi.object({
  name: Joi.string().messages({ "string.empty": `Full Name is required` }),
  bio: Joi.string().max(100).label("Bio").required().messages({"string.empty": "Bio is required",}),
  //allow(null, "").default(null)
  ...emailObj,
  // phone: Joi.string()
  //   .pattern(/^\d{10}$/)
  //   .messages({
  //     "string.pattern.base": "Contact Number must have 10 digits.",
  //     "string.empty": "Contact Number is required",
  //   }),
  skillIds: Joi.array()
    .min(1) // Minimum length of the array, change as needed
    .required()
    .messages({
      "array.min": "Skills are required", // Custom message for minimum length validation
      "array.empty": "Skills are required", // Custom message for empty array validation
    }),
  profileImage: Joi.custom((value, helpers) => {
    // Skip validation for profileImage field
    return value; // Return the value as-is without validation
  }).default(null), // You can provide a default value if needed
});
export { buyerUpdateProfileSchema,sellerUpdateProfileSchema};
