/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import HomeBox from "../../Assets/HomeBox.png";
import HomeBoxMobile from "../../Assets/HomeBox-mobile.svg";
import { Box, Button, Grid, Typography } from "@mui/material";

import LoginHeader from "../LoginHeader/LoginHeader";
import LoginFooter from "../LoginFooter/LoginFooter";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "src/utils/auth-context";
// import HomeBox from "../../Assets"
export const Home = () => {
  const handelSetRole = (value) => {
    localStorage.setItem("role", value);
  };
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <Box
      sx={{
        background: "white",
        minHeight: "100svh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LoginHeader isHome={true} />
      <Box className="SignIn landing">
        <Grid
          container
          sx={{
            background: "white",
            borderRadius: "20px",
            height: "100%",
            margin: "0 auto",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              flexDirection: "Column",
              className: "leftTextCol",
            }}
            className="LeftTextCol"
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: { textAlign: "center" },
              })}
            >
              <Typography
                variant="p"
                sx={{ fontWeight: "500" }}
                className="font-24"
              >
                Hey there! Welcome to YeMo.ai
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  wordBreak: "break-word",
                }}
                className="font-80"
              >
                Your cozy little corner for all things AI
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                mt: 2,
                [theme.breakpoints.down("md")]: { textAlign: "center" },
              })}
            >
              <Link to={"/signin"}>
                <Button
                  onClick={() => handelSetRole(2)}
                  variant="contained"
                  sx={{
                    mx: 1,
                    mb: 2,
                    width: "fit-content",
                    borderRadius: "20px",
                    background: "#FDF64A",
                    color: "black",
                    "&:hover": {
                      background: "#FFFA82",
                    },
                  }}
                >
                  Offering Services
                </Button>
              </Link>
              <Link to={"/signin"}>
                <Button
                  onClick={() => handelSetRole(1)}
                  variant="contained"
                  sx={{
                    mx: 1,
                    mb: 2,
                    width: "fit-content",
                    borderRadius: "20px",
                    background: "black",
                    color: "white",
                    "&:hover": {
                      background: "#333",
                    },
                  }}
                >
                  Looking for Services?
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={(theme) => ({
              p: 2,
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              [theme.breakpoints.down("md")]: {
                justifyContent: "center",
              },
            })}
            className="RightTextCol"
          >
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: { display: "none" },
              })}
            >
              <img src={HomeBox} alt="homebox" className="img-fluid" />
            </Box>
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up("md")]: { display: "none" },
                mt: 3,
              })}
            >
              <img src={HomeBoxMobile} alt="homebox" className="img-fluid" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LoginFooter />
    </Box>
  );
};
