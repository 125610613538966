/* eslint-disable no-unused-vars */
// Layout.js
import React from "react";
import { useAuth } from "src/utils/auth-context";
import "../../style/style.css";

import "../../components/Loader/Loader.css";
import LoaderImg from "../../components/Loader/yemo-page-loader.gif";
import { Backdrop } from "@mui/material";

const Layout = ({ children }) => {
  const { user, loading } = useAuth();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // Render a loading indicator or any other loading behavior
  return loading ? (
    <div>
      <Backdrop
        className="pageLoader"
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <img src={LoaderImg} alt="loader" />
      </Backdrop>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Layout;
