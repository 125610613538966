import * as React from "react";
import { Box, Button, Divider, Stack } from "@mui/material";
import logo from "../../Assets/512_-removebg-preview1.png";
import Beta from "../../Assets/BetaIMG.png";
import NavigationTabs from "./NavigationTabs";
import MyProfile from "./MyProfile";
import "./Sidebar.css";
import Footer from "./Footer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import { useSharedState } from "src/utils/SharedStateProvider";
// import { jobAPI } from "src/services/jobApis";
// import { toast } from "react-toastify";
// import AirProfileIcon from "../../Assets/AiProfile - Copy.png";

export default function Sidebar() {
  const role = parseInt(localStorage.getItem("role"));
  const [showCloseButton, setShowCloseButton] = React.useState(false);
  const {
    jobId,
    // isClicked,
    // setJobId,
    // setIsClicked,
    // setLoading,
    setShowSiderBar,
    showSiderBar,
  } = useSharedState();
  // const navigate = useNavigate();
  const sidebarRef = React.useRef(null);
  // const handleCreateJobCoPilot = () => {
  //   if (role === 1) {
  //     setLoading(true);
  //     jobAPI
  //       .createJob({ name: "yemo", coPilot: true })
  //       .then((res) => {
  //         setIsClicked(!isClicked);
  //         setJobId(res.data.data.id);
  //         setLoading(false);
  //         navigate(`/dashboard/chat/${res.data.data.id}`);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //         toast.error("Something went wrong!");
  //       });
  //   }
  // };
  React.useEffect(() => {
    if (showSiderBar && sidebarRef) {
      sidebarRef.current.classList.add("hide");
      const timeoutId = setTimeout(() => {
        sidebarRef.current.classList.remove("show");
        sidebarRef.current.style.display = "none";
      }, 400);
      return () => clearTimeout(timeoutId);
    } else {
      if (!sidebarRef) {
        return false;
      }
      sidebarRef.current.classList.add("show");
      sidebarRef.current.style.display = "block";
      const timeout = setTimeout(() => {
        sidebarRef.current.classList.remove("hide");
      }, 400);
      return () => clearTimeout(timeout);
    }
  }, [showSiderBar]);
  const url = window.location.href;
  React.useEffect(() => {
    if (jobId !== null && url.includes("dashboard/chat")) {
      setShowCloseButton(true);
    } else {
      setShowCloseButton(false);
    }
  }, [jobId, url]);
  return (
    <Stack
      direction="column"
      className="sidebar"
      sx={(theme) => ({ [theme.breakpoints.down("md")]: { display: "flex" } })}
      width={role === 4 ? "500px" : "402px"}
      ref={sidebarRef}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Link to="/dashboard">
          <img src={logo} alt="logo" height={29} />
          <img src={Beta} alt="beta logo" height="15px" width="38px" />
        </Link>
        {role === 1 ? (
          // <Button
          //   className="tour-co-pilot"
          //   sx={{
          //     backgroundColor: "#EEEEEE",
          //     borderRadius: "10px",
          //     padding: "10px",
          //     position: "absolute",
          //     right: "2em",

          //     // marginLeft: "13%",
          //   }}
          //   onClick={() => {
          //     // handleCreateJobCoPilot();
          //   }}
          //   disabled
          // >
          //   <img
          //     src={AirProfileIcon}
          //     alt="co-pilot"
          //     width="22px"
          //     height="22px"
          //     style={{ borderRadius: "50%", paddingRight: "5px" }}
          //   />
          //   <Typography
          //     variant="subtitle2"
          //     sx={{ fontWeight: "700", color: "#333333" }}
          //   >
          //     Your AI Co-Pilot
          //   </Typography>
          // </Button>
          ""
        ) : (
          ""
        )}

        {showCloseButton ? (
          <div className="backbutton">
            <Button
              sx={{ minWidth: "0px", padding: "0px" }}
              onClick={() => {
                setShowSiderBar(true);
              }}
            >
              <ChevronLeftIcon sx={{ color: "black" }} />
            </Button>
          </div>
        ) : (
          <></>
        )}
      </Box>
      {/* <Box
        sx={(theme) => ({
          borderRadius: "10px",
          background: "#F3F6F9",
          p: 1,
          mx: 1,
          mb: 2,
          [theme.breakpoints.down("md")]: { display: "none" },
        })}
        className="tour-co-pilot"
      >
        <Link
          style={{ textDecoration: "none", color: "black" }}
          // onClick={() => {
          //   handleCreateJobCoPilot();
          // }}
        >
          <Box sx={{ borderRadius: "10px", background: "white", p: 1 , backgroundColor:"red" }}>
            <Typography
              variant="p"
              sx={{
                fontSize: "12px",
                color: "#9D9EAB",
                fontWeight: "500",
                ml: 2,
              }}
            >
              Your AI Co-Pilot
            </Typography>

            <Box
              sx={(theme) => ({
                mt: 2,
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: { marginTop: "4px" },
              })}
            >
              <img src={AirProfileIcon} alt="aiprofile" className="size-60" />
              <Box sx={{ ml: 1 }}>
                <Typography
                  variant="h4"
                  sx={{ fontSize: "16px", color: "#333333", fontWeight: "600" }}
                >
                  Yemo
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: "14px", color: "#AAABB3" }}
                >
                  Welcome!!!
                </Typography>
              </Box>
            </Box>
          </Box>
        </Link>
      </Box> */}
      <Box>
        <NavigationTabs />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          background: "white",
        }}
        className="second-element"
      >
        <Divider />
        <MyProfile />
        <Divider
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: { display: "none" },
          })}
        />
        <Footer />
      </Box>
    </Stack>
  );
}
