import React, { createContext, useContext, useState } from "react";

const JobStateContext = createContext();

export const JobStateProvider = ({ children }) => {
  const [userJob, setUserJob] = useState({});
  const [rejectJob, setRejectJob] = useState(false);
  const [showLeaveJobForm, setShowLeaveJobForm] = useState(false);
  const [showRejectReasonForm, setShowRejectReasonForm] = useState(false);
  const [disputeSuccess, setDisputeSuccess] = useState(false);
  const [submitContract, setSubmitContract] = useState(false);
  const [acceptReject, setAcceptReject] = useState(false);
  const [dispute, setDispute] = useState(false);
  const [disputeEngineer, setDisputeEngineer] = useState(false);
  const [disputeYemo, setDisputeYemo] = useState(false);
  const [meetingStatus, setMeetingStatus] = useState(false);
  const [joinButtonClicked, setJoinButtonClicked] = useState(false);
  const [superviserAccept, setSuperviserAccept] = useState(false);
  const [userId, setUserId] = useState();

  const updateJob = async (newJobData) => {
    await setUserJob(newJobData);
  };
  return (
    <JobStateContext.Provider
      value={{
        userJob,
        setUserJob,
        updateJob,
        setRejectJob,
        rejectJob,
        showLeaveJobForm,
        setShowLeaveJobForm,
        showRejectReasonForm,
        setShowRejectReasonForm,
        disputeSuccess,
        setDisputeSuccess,
        submitContract,
        setSubmitContract,
        acceptReject,
        setAcceptReject,
        dispute,
        setDispute,
        disputeEngineer,
        setDisputeEngineer,
        disputeYemo,
        setDisputeYemo,
        meetingStatus,
        setMeetingStatus,
        joinButtonClicked,
        setJoinButtonClicked,
        superviserAccept,
        setSuperviserAccept,
        userId,
        setUserId,
      }}
    >
      {children}
    </JobStateContext.Provider>
  );
};

export const useJobSharedState = () => useContext(JobStateContext);
