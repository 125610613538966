import { ToastContainer } from "react-toastify";
import "./App.css";
import { idleTimeInMinute } from "./constants/api.constants";
import { PublicRoute } from "./routes/PublicRoutes";
import "./style/style.css";
import { useIdleTimer } from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import { SharedStateProvider } from "./utils/SharedStateProvider";
import Loader from "./components/Loader/Loader";
import { useEffect, useState } from "react";
import ConnectionError from "./pages/Error/ConnectionError";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./utils/authProvider";
import { useAuth } from "./utils/auth-context";
import Loading from "./pages/Main/Loading";
import Layout from "./pages/Main/Layout";
function App() {
  const { loading } = useAuth();
  useEffect(()=>{
   const tour =  localStorage.getItem("tour")
   if(!tour){
    localStorage.setItem("tour",true)
   } 
  },[])
  const token = () => localStorage.getItem("authProviderToken");
  const handleOnIdle = () => {
    if (token()) {
      alert(`You'r session has timed out, please login again!`);
      localStorage.clear();
      window.location.href = "/signin";
    } else {
      reset();
    }
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * idleTimeInMinute,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  function useOnlineStatus() {
    const [online, setOnline] = useState(true);

    useEffect(() => {
      const handleOnlineStatusChange = () => {
        setOnline(window.navigator.onLine);
      };

      window.addEventListener("online", handleOnlineStatusChange);
      window.addEventListener("offline", handleOnlineStatusChange);

      return () => {
        window.removeEventListener("online", handleOnlineStatusChange);
        window.removeEventListener("offline", handleOnlineStatusChange);
      };
    }, []);

    return online;
  }
  const online = useOnlineStatus();
  if (loading) {
    return <Loading />;
  }
  
  return (
    <AuthProvider>
      <Layout>
        <div className="App">
          {online ? (
            <GoogleOAuthProvider clientId="139459752182-agjgu006adbnr0u9d5edt4sa6cfukin5.apps.googleusercontent.com">
              <SharedStateProvider>
                <Loader />
                <ToastContainer />

                <PublicRoute />
              </SharedStateProvider>
            </GoogleOAuthProvider>
          ) : (
            <ConnectionError />
          )}
        </div>
      </Layout>
    </AuthProvider>
  );
}

export default App;
