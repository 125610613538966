import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import "./CalendarConnect.css";
import { useGoogleLogin } from "@react-oauth/google";
import { userAPI } from "src/services/userApis";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "src/utils/microsoftAuthConfig";
import { toast } from "react-toastify";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
const CalendarConnect = ({ calendars, setCalendars, setSkip, setNext }) => {
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: '#333333', // Your custom primary color
  //     },
  //     secondary: {
  //       main: '#DDDDDD', // Your custom secondary color
  //     },
  //   },
  // });

  const googleToken = (code) => {
    userAPI
      .getGoogleToken({ code })
      .then((res) => {
        if (calendars?.microsoft) {
          setCalendars({ microsoft: true, google: true, all: true });
          setNext(true);
        } else {
          setCalendars({ microsoft: false, google: true, all: false });
          setNext(true);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleToken(tokenResponse.code);
    },
    onError: (error) => console.log(error),
    flow: "auth-code",
    scope: "openid email profile https://www.googleapis.com/auth/calendar",
  });

  const { instance } = useMsal();
  // function getRefreshTokenKey(account) {
  //   const key = `msal.token.keys.${account.aud}`;
  //   return key;
  // }

  const saveTokenMicrosoft = (accessToken) => {
    userAPI
      .getMicrosoftToken({ accessToken })
      .then((res) => {
        if (calendars?.google) {
          setCalendars({ microsoft: true, google: true, all: true });
        } else {
          setCalendars({ microsoft: true, google: false, all: false });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance
        .loginPopup(loginRequest)
        .then((response) => {
          saveTokenMicrosoft(response.accessToken);
          // Check if the response contains the account
          const accounts = instance.getAllAccounts();
          if (accounts.length > 0) {
            //  const account = accounts[0];
            // const accountData = {
            //   homeAccountId: account.homeAccountId,
            //   environment: account.environment,
            //   aud: account.idTokenClaims.aud
            // }
            //   const key = getRefreshTokenKey(accountData);
            //  const newKey = JSON.parse(localStorage.getItem(key));
            //  const refreshToken = JSON.parse(localStorage.getItem(newKey.refreshToken[0]));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (loginType === "redirect") {
      instance
        .loginRedirect(loginRequest)
        .then((response) => {
          // Check if the response contains the account
          const accounts = instance.getAllAccounts();
          if (accounts.length > 0) {
            //  const account = accounts[0];
            //  const refreshToken = account.refreshToken;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // const handleLogout = (logoutType) => {
  //   if (logoutType === "popup") {
  //     instance.logoutPopup({
  //       postLogoutRedirectUri: "/",
  //       mainWindowRedirectUri: "/",
  //     });
  //   } else if (logoutType === "redirect") {
  //     instance.logoutRedirect({
  //       postLogoutRedirectUri: "/",
  //     });
  //   }
  // };
  return (
    <>
      <Box marginTop="5%"
      sx={(theme) => ({
        [theme.breakpoints.down("md")]: {
          marginTop:"35%"
        },
      })}
      padding={"0 5%"}>
        <Stack>
          <Typography variant="h2" mb="10px" className="typography-h2">
            Hej! Welcome to Yemo
          </Typography>
          <Typography variant="h3" mb="30px" className="typography-h3">
            We need some basic information to set you up
          </Typography>
          <Typography variant="p" mb="20px" className="typography-p">
            Connect Your calender to see your schedule and <br /> plan calls
            accordingly
          </Typography>
        </Stack>
        <Box
          mt="20px"
          sx={{ border: "2px solid #DDDDDD", borderRadius: "10px" }}
        >
          <Box>
            <Stack className="stack-container">
              <Box>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginRight: "20x" }}>
                    {/* <image
                      className="google-calendar"
                      src={googleCalendar}
                      alt="an image of google calender"
                      height="25px"
                      width="25px"
                    /> */}
                    <GoogleIcon color="primary" />
                  </Box>
                  <Typography variant="p" style={{ marginLeft: "10px" }}>
                    Google Calendar
                  </Typography>
                </Stack>
              </Box>
              {!calendars?.google ? (
                <Button
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "20px",
                    color: "#333333",
                    padding: "5px 12px",
                  }}
                  onClick={() => {
                    login();
                  }}
                >
                  Connect
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Box>

          {/* <Divider className='divider'/> */}
          <Box
            className="divider"
            mb="10px"
            sx={{ borderBottom: "1px solid #DDDDDD" }}
          ></Box>

          <Box>
            <Stack className="stack-container">
              <Box>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginRight: "20x" }}>
                    {/* <image
                      className="google-calendar"
                      src={outlookCalendar}
                      alt="an image of outlook calender"
                      height="25px"
                      width="25px"
                    /> */}
                    <MicrosoftIcon color="primary" />
                  </Box>

                  <Typography variant="p" style={{ marginLeft: "10px" }}>
                    Outlook Calendar
                  </Typography>
                </Stack>
              </Box>
              {!calendars?.microsoft ? (
                <Button
                  sx={{
                    border: "1px solid #DDDDDD",
                    borderRadius: "20px",
                    color: "#333333",
                    padding: "5px 12px",
                  }}
                  onClick={() => handleLogin("popup")}
                >
                  Connect
                </Button>
              ) : (
                // <Button
                //   sx={{
                //     border: "1px solid #DDDDDD",
                //     borderRadius: "20px",
                //     color: "#333333",
                //     padding: "5px 12px",
                //   }}
                //   onClick={() => handleLogout("popup")}
                // >
                //   Log Out
                // </Button>
                <></>
              )}
            </Stack>
          </Box>

          {/* <Box
            className="divider"
            mb="10px"
            sx={{ borderBottom: "1px solid #DDDDDD" }}
          ></Box>

          <Box>
            <Stack className="stack-container">
              <Box>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ marginRight: "25x" }}>
                    <AppleIcon color="silver" />
                  </Box>
                  <Typography variant="p" style={{ marginLeft: "10px" }}>
                    Apple Calendar
                  </Typography>
                </Stack>
              </Box>
              <Button
                sx={{
                  border: "1px solid #DDDDDD",
                  borderRadius: "20px",
                  color: "#333333",
                  padding: "5px 12px",
                }}
              >
                Connect
              </Button>
            </Stack>
          </Box> */}
        </Box>
        <Stack className="stack-2">
          {/* <Button onClick={()=>{setNext(true)}} className="btn-next">Next</Button> */}
          <Button
            onClick={() => {
              setNext(true);
            }}
            className="btn-skip"
          >
            Next
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default CalendarConnect;
