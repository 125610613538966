/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Drafts from "./Draft";
import All from "./All";
import Waiting from "./Waiting";
import InProgress from "./InProgress";
import Completed from "./Completed";
// import Invitation from "./Invitation";
import { useSharedState } from "src/utils/SharedStateProvider";
import { socket } from "../Main/Message";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  const {
    jobId,
    getJobs,
    setGetJobs,
    acceptReject,
    setAcceptReject,
    complete,
    setComplete,
    acceptInvite,
    setAcceptInvite,
    updateName,
    setUpdateName,
    markAsCompleteUpdate,
    setMarkAsCompleteUpdate,
    updatedRightSideBar,
    setUpdatedRightSideBar,
  } = useSharedState();
  const role = parseInt(localStorage.getItem("role"));
  const [all, setAll] = useState(false);
  const [draft, setDraft] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  //  const [invited, setInvited] = useState(false);
  const [gotNewJobs,setGotNewJobs] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    const handleWaitingJobs = (data) => {
      console.log("newJobs", data);
      setGotNewJobs(true);
    };

    socket.on("waitingJobs", handleWaitingJobs);
    return () => {
      socket.off("waitingJobs", handleWaitingJobs);
    };
  }, []); 
     

  const handleTabChange = (selectedValue) => {
    // Reset all states to false
    setAll(false);
    setDraft(false);
    setWaiting(false);
    setInProgress(false);
    setCompleted(false);
    // setInvited(false);

    // Update the selected state to true
    switch (selectedValue) {
      case "1":
        setAll(true);
        break;
      case "2":
        setDraft(true);
        break;
      case "3":
        setWaiting(true);
        break;
      case "4":
        setInProgress(true);
        break;
      case "5":
        setCompleted(true);
        break;
      // case "6":
      //   setInvited(true);
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleTabChange(value);
    if (getJobs) {
      setGetJobs(false);
    }
    if (acceptReject) {
      setAcceptReject(false);
    }
    if (complete) {
      setComplete(false);
    }
    if (acceptInvite) {
      setAcceptInvite(false);
    }
    if (updateName || updatedRightSideBar) {
      setUpdateName(false);
      setUpdatedRightSideBar(false);
    }
    if (markAsCompleteUpdate) {
      setMarkAsCompleteUpdate(false);
    }
    if (updatedRightSideBar) {
      setUpdatedRightSideBar(false);
    }
    if(gotNewJobs){
      setGotNewJobs(false)
    }
  }, [
    getJobs,
    acceptReject,
    complete,
    acceptInvite,
    updateName,
    markAsCompleteUpdate,
    updatedRightSideBar,
    gotNewJobs,
  ]);

  useEffect(() => {}, [jobId]);
  const buyerTabs = [
    { label: "All", value: "1" },
    { label: "Drafts", value: "2" },
    { label: "Waiting", value: "3" },
    { label: "In-Progress", value: "4" },
    { label: "Completed", value: "5" },
  ];
  const sellerTabs = [
    { label: "All", value: "1" },
    { label: "In-Progress", value: "4" },
    { label: "Completed", value: "5" },
  ];
  const supervisorTabs = [
    { label: "All", value: "1" },
    { label: "Waiting", value: "3" },
    { label: "In-Conflict", value: "4" },
    { label: "Completed", value: "5" },
  ];
  return (
    <Box
      className="tour-jobs-list"
      sx={(theme) => ({
        width: "100%",
        typography: "body1",
        [theme.breakpoints.down("md")]: { mt: 0 },
      })}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {role === 1
              ? buyerTabs.map((obj) => (
                  <Tab
                    label={obj.label}
                    value={obj.value}
                    key={obj.value}
                    className="buyerTab"
                  />
                ))
              : role === 2
              ? sellerTabs.map((obj) => (
                  <Tab
                    label={obj.label}
                    value={obj.value}
                    key={obj.value}
                    className="sellerTab"
                  />
                ))
              : role === 4
              ? supervisorTabs.map((obj) => (
                  <Tab
                    label={obj.label}
                    value={obj.value}
                    key={obj.value}
                    className="sellerTab"
                  />
                ))
              : ""}
          </TabList>
        </Box>
        <TabPanel value="1">
          <All all={all} setAll={setAll} />
        </TabPanel>
        <TabPanel value="2">
          <Drafts draft={draft} setDraft={setDraft} />
        </TabPanel>
        <TabPanel value="3">
          <Waiting waiting={waiting} setWaiting={setWaiting} />
        </TabPanel>
        <TabPanel value="4">
          <InProgress inProgress={inProgress} setInProgress={setInProgress} />
        </TabPanel>
        <TabPanel value="5">
          <Completed completed={completed} setCompleted={setCompleted} />
        </TabPanel>
        {/* <TabPanel value="6">
          <Invitation invited={invited} setInvited={setInvited} />
        </TabPanel> */}
      </TabContext>
    </Box>
  );
}
