/* eslint-disable no-unused-vars */
import Backdrop from "@mui/material/Backdrop";

import React from "react";
import "../../style/style.css";

import "./Loader.css";
import LoaderImg from "./yemo-page-loader.gif";
import { useSharedState } from "src/utils/SharedStateProvider";

const Loader = () => {
  const { loading } = useSharedState();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Backdrop
        className="pageLoader"
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <img src={LoaderImg} alt="loader"/>
      </Backdrop>
    </div>
  );
};

export default Loader;
