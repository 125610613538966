/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import sortAlphabeticalIcon from "../../Assets/sort-alphabetical-variant.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./globalFile.css";
import foldericon from "../../Assets/Vector.svg";
import sendIcon from "../../Assets/file-send.svg";
import moment from "moment";
import { toast } from "react-toastify";
import { useSharedState } from "src/utils/SharedStateProvider";
import { ChevronRight } from "@mui/icons-material";
import { socket } from "../Main/Message";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AviIcon from "../../Assets/avi-icon.png";
import { getFileExtension } from "src/utils/helper";
import { chatAPI } from "src/services/jobApis";
import { baseURLs } from "src/constants/api.constants";


const JobFiles = () => {
  const { id, ...folders } = useParams();
  const [files, setFiles] = useState([]);
  const { jobName } = useSharedState();
  const [updatePath, setUpdatePath] = useState([]);
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState("asc");
  const [newFolderName, setNewFolderName] = useState("");
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]); // Track selected files
  const [moveFile, setMoveFile] = useState(false);

  const handleCheckboxChange = (event, fileId) => {
    if (event.target.checked) {
      setSelectedFiles([...selectedFiles, fileId]);
    } else {
      const updatedFiles = selectedFiles.filter((id) => id !== fileId);
      setSelectedFiles(updatedFiles);
    }
  };

  const getUnselectedFiles = () => {
    return files.filter((file) => !selectedFiles.includes(file));
  };
  const handleMoveFiles = () => {
    setMoveFile(true);
    const unselectedFiles = getUnselectedFiles();

    setFiles(unselectedFiles);
  };
  let fullPath = `${id}`;
  let Path = null;
  // Add additional folder segments if they exist
  for (const folder in folders) {
    fullPath += `/${folders[folder]}`;
  }

  const pattern = /[a-zA-Z]/;
  const getFilesByJobId = () => {
    chatAPI
      .getFilesAndFolderByJobId({ path: fullPath })
      .then((res) => {
        setFiles(res.data.contents);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const columns = [
    { id: "id", label: "", width: 45 },
    { id: "Name", label: "Name", width: 700 },
    { id: "size", label: "File size", width: 100 },
    { id: "Date", label: "Date of Change", width: 100 },
  ];
  useEffect(() => {
    getFilesByJobId();
    if (pattern.test(fullPath)) {
      Path = `${jobName}/${fullPath}`;
      const updatePath = Path.split("/");
      setUpdatePath(updatePath);
    } else {
      Path = `${jobName}/${id}`;
      const updatePath = Path.split("/");
      setUpdatePath(updatePath);
    }
  }, [fullPath, jobName, id]);
  const handleRowClick = (data) => {
    console.log(data);
    if (data.type === "folder") {
      if (!data.isOpen) {
        data.isOpen = true;
        const newPath = `${fullPath}/${data.name}`;
        navigate(`/dashboard/file/jobFiles/${newPath}`);
      }
    } else {
      const downloadLink = document.createElement("a");
      downloadLink.href = `${baseURLs.yemo}/${data.path}`; // Replace with the actual file URL
      downloadLink.download = `${baseURLs.yemo}/${data.path}`;
      downloadLink.target = "_blank" // Set the desired file name
      downloadLink.click();
    }
  };
  const handlePathButtonClick = (buttonName) => {
    if (buttonName === jobName) {
      navigate(`/dashboard/file/jobFiles/${id}`);
    } else {
      const currentPath = window.location.pathname;
      const currentPathSegments = currentPath.split("/");
      let updatedPathSegments = [];
      let shouldStopMapping = false;
      for (const segment of currentPathSegments) {
        if (shouldStopMapping) {
          break;
        }
        updatedPathSegments.push(segment);
        if (segment === buttonName) {
          shouldStopMapping = true;
        }
      }
      const updatedPath = updatedPathSegments.join("/");
      navigate(updatedPath);
    }
  };

  const sortAlphabetically = () => {
    const sortedFiles = [...files];
    sortedFiles.sort((a, b) =>
      sortOrder === "asc"
        ? a?.originalName?.localeCompare(b.originalName)
        : b?.originalName?.localeCompare(a.originalName)
    );
    setFiles(sortedFiles);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle the sorting order
  };
  const fileInputRef = useRef(null);

  const handleUploadFile = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files;

    const payload = {
      files: selectedFile,
      jobId: id,
      path: fullPath,
    };

    chatAPI
      .sendFiles(payload)
      .then((res) => {
        const messageData = res.data.data;
        messageData.forEach(async (element) => {
          await socket.emit("sendMessage", { messageData: element });
          socket.emit("notificationCouter");
        });
        toast.success('File Uploaded successfully')
        getFilesByJobId();
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const addNewFolder = () => {
    setIsCreatingFolder(true);
  };
  const handleFolderNameChange = (event) => {
    setNewFolderName(event.target.value);
  };
  const createFolder = () => {
    setIsCreatingFolder(false);
    if (newFolderName.trim() === "") {
      toast.error("Folder name cannot be empty.");
      return;
    }
    chatAPI
      .createFolder(id, { folderName: newFolderName, path: fullPath })
      .then((res) => {
        if (res.data.success === true) {
          setNewFolderName("");
          setIsCreatingFolder(false);
          getFilesByJobId();
        } else {
          toast.warning(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong !!");
      });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      createFolder();
    }
  };
  const handlePastFile = () => {
    selectedFiles.forEach((element) => {
      const data = {
        folderPath: `uploads\\messages\\${fullPath}`,
        filename: element.name,
        filePath: element.path,
      };

      socket.emit("moveFile", data);
    });
    socket.on("movedFiled", (data) => {
      if (data.success === true) {
        getFilesByJobId();
        setMoveFile(false);
      }
    });
   
    setSelectedFiles([])
    toast.success('Move Files successfully')
  };
  return (
    <Box className="JobPagepage" sx={{ padding: "10px" }}>
      <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" }}>
        <Link to="..">
          <Button sx={{ minWidth: "40px", height: "40px" }}>
            <ChevronLeftIcon sx={{ color: "black" }} />
          </Button>
        </Link>
        My Files
        <Button
          sx={{ marginLeft: "auto" }}
          variant="contained"
          className="disable-hover-effect"
          onClick={addNewFolder}
        >
          Create Folder
        </Button>
        <input
          type="file"
          accept=".pdf, .doc, .docx, .txt" // Define the allowed file types
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileSelect}
          multiple
        />
        <Button
          sx={{ marginLeft: "10px" }}
          variant="contained"
          color="primary"
          className="disable-hover-effect"
          onClick={handleUploadFile}
        >
          Upload File
        </Button>
      </Box>

      <Divider />
      <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" }}>
        <Button
          onClick={() => {
            navigate("/dashboard/file");
          }}
        >
          <img src={foldericon} alt="foldericon" style={{ padding: "5px" }} />
        </Button>

        {updatePath.map((Path) => (
          <>
            {Path !== "" && !/^\d+$/.test(Path) ? (
              <Button
                onClick={() => handlePathButtonClick(Path)}
                endIcon={<ChevronRight />}
              >
                {Path}
              </Button>
            ) : null}
          </>
        ))}
        <Box className="filterIcons">
        {selectedFiles.length > 0 && !moveFile  && (
        <Button onClick={handleMoveFiles} className="LoginButton" sx={{color:"white"}}>
          <img src={sendIcon} alt="sendIcon" />
          Move file
        </Button>
      )}
      {moveFile && (
        <Button onClick={handlePastFile}>
          <ContentPasteIcon />
        </Button>
      )}
          |
          <Button onClick={sortAlphabetically}>
            <img src={sortAlphabeticalIcon} alt="sortAlphabeticalIcon" />
          </Button>
        </Box>
      </Box>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell style={{ width: 20 }}>
              <Checkbox/>
            </TableCell> */}
            {columns.map((column) => (
              <TableCell key={column.id} style={{ width: column.width }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <TableContainer
        sx={{ maxHeight: "calc(100vh - 366px)", overflowY: "auto" }}
      >
        <Table>
          <TableBody>
            {isCreatingFolder ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={foldericon}
                      alt="foldericon"
                      style={{ padding: "5px" }}
                    />
                    <TextField
                      placeholder="Enter folder name "
                      onChange={handleFolderNameChange}
                      value={newFolderName}
                      fullWidth
                      onKeyDown={handleKeyDown}
                    />
                  </Box>
                </TableCell>
                <TableCell colSpan={2}>
                  <Button
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    className="disable-hover-effect"
                    onClick={createFolder}
                  >
                    Create Folder
                  </Button>
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
            {files.map((row) => (
              <TableRow key={row.id}>
                <TableCell style={{ width: 20 }}>
                  {!moveFile && row.type === "file" ? (
                    <Checkbox
                      checked={selectedFiles.includes(row)}
                      onChange={(event) => handleCheckboxChange(event, row)}
                    />
                  ) : (
                    ""
                  )}
                </TableCell>

                {row.type === "file" ? (
                  <TableCell width="700px" onClick={() => handleRowClick(row)}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="img-icon"
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        <img
                          src={AviIcon}
                          alt="aviIcon"
                          height="26px"
                          width="26px"
                          style={{ padding: "5px" }}
                        />
                        <p className="file-icon">
                          {getFileExtension(row.originalName)}
                        </p>
                      </div>
                      {row.originalName}
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell width="700px" onClick={() => handleRowClick(row)}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={foldericon}
                        alt="foldericon"
                        style={{ padding: "5px" }}
                      />
                      {row.name}
                    </Box>
                  </TableCell>
                )}

                <TableCell width="100px" onClick={() => handleRowClick(row)}>
                  {((row.size || 24) / 1024).toFixed(2)}mb
                </TableCell>
                <TableCell width="100px" onClick={() => handleRowClick(row)}>
                  {moment(row.uploadTime).format("DD-MM-YYYY")}|
                  {moment(row.uploadTime).format("h:mmA")}
                </TableCell>
              </TableRow>
            ))}

            {files.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default JobFiles;
