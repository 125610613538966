/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ElementWrapper from "src/components/payment/ElementWrapper";
import Divider from "@mui/material/Divider";
import MyCardsList from "../../components/CardDetails/MyCardList";
import "./MyCardDetails.css";
import { Link } from "react-router-dom";
import { paymentAPI } from "src/services/api";
import { usePaymentState } from "src/utils/PaymentStateProvider";
import { toast } from "react-toastify";
import { useSharedState } from "src/utils/SharedStateProvider";

function MyCardsDetails() {
  // const { setLoading } = useSharedState();
  const { setCustomerId, paymentMethodId, setPaymentMethodId, showForm, setShowForm } =
    usePaymentState();
  const { walletBalance } = useSharedState();
  const [savedCards, setSavedCards] = useState([]);
  const [getCards, setGetCards] = useState(false);


  const fetchCustomerId = async () => {
    try {
      await paymentAPI
        .getPaymentMethodByCustomer()
        .then(async (res) => {
          if (res.data.success === true) {
            setCustomerId(
              res.data.data.customerId ? res.data.data.customerId : null
            );
            setSavedCards([]);
            if (res.data.data.paymentMethods.length > 0) {
              res.data.data.paymentMethods.forEach(
                ({ billing_details, card, id }, index) => {
                  setSavedCards((previousItem) => [
                    ...previousItem,
                    {
                      id: id,
                      cardName: billing_details.name,
                      last4: card.last4,
                      exp_month: card.exp_month,
                      exp_year: card.exp_year,
                      brand: card.brand,
                      isDefault:
                        res.data.data.defaultPaymentMethod === id
                          ? async () => {
                              await setPaymentMethodId(id);
                              return true;
                            }
                          : false,
                    },
                  ]);
                }
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // setChatLoading(false);

          toast.error("Something went wrong!!");
        })
        .finally(() => {
          setGetCards(true);
          // setNewLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setChatLoading(false);
      toast.error("Something went wrong!!");
    }
  };

  useEffect(() => {
    // setChatLoading(true);
    // Fetch customer ID from your server/database and update the state
    fetchCustomerId();
  }, [paymentMethodId]);

  return (
    <Box>
      <Box
        sx={{
          marginLeft: "2%",
          padding: "10px",
          backgroundColor: "#F0F0F0",
          width: "fit-content",
          borderRadius: "15px",
          marginBottom: "10px",
        }}
      >
        <Typography style={{ fontSize: "15px", color: "#5E5F69" }}>
          My Balance
        </Typography>
        <Typography style={{ fontSize: "32px" }}>
          ${walletBalance?.toFixed(2)}
        </Typography>
      </Box>

      <Divider />
      <Box>
        <Typography style={{ fontSize: "22px", marginLeft: "2%" }}>
          My Cards
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row" }}
          className="my_card_container"
        >
          <MyCardsList getCards={getCards} savedCards={savedCards} />
          <Box
           onClick={() => {
            setShowForm(true);
          }}
            sx={{
              border: "1px dashed grey",
              height: "200px",
              marginTop: "1%",
              borderRadius: "15px",
            }}
            className="add_card_btn"
          >
            <Link
              style={{
                textDecoration: "none",
                color: "black",
                // height: "100%",
                // width: "100%",
              }}
             
            >
              <Typography
                style={{
                  fontSize: "24px",
                }}
              >
                +
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Divider />

      {showForm ? (
        <Box sx={{ marginTop: "2%" }}>
          
          <ElementWrapper />
          
        </Box>
      ) : (
        //       <Box>
        //       <Typography style={{ fontSize: "22px", marginBottom: "1%", marginLeft:"2%" }}>
        //         My Transactions
        //       </Typography>
        //       <Box >
        //      <TranscationTable transactions={transactions}/>
        // </Box>
        //       </Box>
        ""
      )}
    </Box>
  );
}

export default MyCardsDetails;
