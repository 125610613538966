const Joi = require("joi");
const allowedExtensions = [".svg", ".jpg", ".jpeg", ".png", ".pdf"];

// Create a custom validation function for the file
const validateFile = (file, helpers) => {
  if (!file) {
    return "File is required";
  }
  const maxSizeInBytes = 3 * 1024 * 1024; // 3MB

  if (!file) {
    return helpers.error("any.required");
  }

  if (!allowedExtensions.some((ext) => file.name.toLowerCase().endsWith(ext))) {
    return helpers.error("custom.invalidFileType");
  }

  if (file.size > maxSizeInBytes) {
    return helpers.error("custom.fileSizeExceeded");
  }
  return file;
};

const fileSchema = Joi.custom(validateFile, "File validation").messages({
  "any.required": "File is required",
  "custom.invalidFileType": `Invalid file type. Allowed types: ${allowedExtensions}`,
  "custom.fileSizeExceeded": "File size exceeds the limit (3MB)",
});

const contactUsSchema = Joi.object({
  selectedOption: Joi.string()
    .messages({
      "string.empty": "Please select a feedback topic",
      "any.required": "Please select a feedback topic",
    })
    .required(),
  feedback: Joi.string().max(100).label("feedback").required().messages({
    "string.max": "More than 100 characters are not allowed",
    "string.empty": "Write something for better understanding",
  }),
  feedbackImage: Joi.array().items(fileSchema),
});

export default contactUsSchema;
