import { emailObj, passwordObj } from "./loginSchema";

const Joi = require("joi");

export const userSchema = Joi.object({
  name: Joi.string().messages({ "string.empty": `Full Name is required` }),
  ...emailObj,
  bio: Joi.string().empty(),
  // phone: Joi.string()
  //   .pattern(/^\d{10}$/)
  //   .required()
  //   .messages({
  //     "string.pattern.base": "Contact Number must have 10 digits.",
  //     "string.empty": "Contact Number is required",
  //   }),
  ...passwordObj,
  roleId: Joi.number(),
});
