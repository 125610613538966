import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { baseURLs } from "src/constants/api.constants";
import Profile from "../../Assets/user-default-icon.png";
import Yemologo from "../../Assets/app-icon.png";
export default function MessageProfile({ msg }) {
  return (
    <>
      {(msg.type === "text" ||
        msg.type === "options" ||
        msg.type === "reject" ||
        msg.type === "payment" ||
        msg.type === "file") && (
        <Box className="MessageDetail" sx={{ mt: 2 }}>
          <Box
            sx={{
              px: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              alt="profile"
              src={
                msg.userId === 1
                  ? Yemologo
                  : msg?.profileImage || msg.profileImage !== null
                  ? `${baseURLs.yemo}/${msg.profileImage}`
                  : Profile
              }
              height="25px"
              width="25px"
              style={{ borderRadius: 50 }}
            />
            <Typography
              variant="h4"
              sx={{
                fontSize: "12px",
                ml: 1,
                color: "#9D9EA8",
                fontWeight: "500",
              }}
            >
              {msg.name} {moment(msg.created_at).format("h:mmA")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
