/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { resetPasswordProfileSchema } from "src/validationSchema/forgotSchema";
import { userAPI } from "src/services/userApis";

export default function ResetPassword() {
  const { setLoading } = useSharedState();
  const [value, setValue] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const { setSelectedComponent } = useSharedState();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [touchedFields, setTouchedFields] = useState([]);
  const [errors, setErrors] = useState({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleToggleOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleToggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const validateForm = () => {
    const { error } = resetPasswordProfileSchema.validate(value, {
      abortEarly: false,
    });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValue((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleBlur = (event) => {
    const { name } = event.target;
    if (!touchedFields.includes(name)) {
      setTouchedFields([...touchedFields, name]);
    }
    validateForm();
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === value.newPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm() && passwordsMatch) {
      //
      userAPI
        .resetPasswordProfile(value)
        .then((res) => {
          setLoading(false);
          if (res.data.success === true) {
            setConfirmPassword("");
            setValue({
              oldPassword: "",
              newPassword: "",
            });
            toast.success(res.data.body.message);
          } else {
            setConfirmPassword("");
            setValue({
              oldPassword: "",
              newPassword: "",
            });
            toast.error(res.data.body.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Something went wrong!");
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    setSelectedComponent("Account Settings");
  }, []);
  return (
    <Box>
      {/* <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" , fontWeight: "700"}}>
          <Button sx={{ minWidth: "40px", height: "40px" }} >
            <ChevronLeftIcon />
          </Button>
          Change Password
      </Box> */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <TextField
                type={showOldPassword ? "text" : "password"}
                name="oldPassword"
                label="Old Password*"
                value={value.oldPassword}
                error={
                  //touchedFields.includes("oldPassword") &&
                  !!errors.oldPassword
                }
                helperText={
                  // touchedFields.includes("oldPassword")
                  // ?
                  errors.oldPassword
                  // : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleOldPassword}
                      >
                        {showOldPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff sx={{ color: "#0F485B" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
                label="New Password*"
                value={value.newPassword}
                error={
                  // touchedFields.includes("newPassword") &&
                  !!errors.newPassword
                }
                helperText={
                  // touchedFields.includes("newPassword")
                  //  ?
                  errors.newPassword
                  //  : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleNewPassword}
                      >
                        {showNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff sx={{ color: "#0F485B" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                // name="password"
                label="Retype New Password*"
                // error={!!errors.password}
                // helperText={errors.password}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff sx={{ color: "#0F485B" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
            {!passwordsMatch && (
              <p className="error-message">Passwords do not match.</p>
            )}
            <Button
              type="submit"
              variant="contained"
              className="LoginButton"
              sx={{
                mt: 2,
                width: "fit-content",
                borderRadius: "20px",
              }}
            >
              Save Password
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}
