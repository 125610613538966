/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./ProfilePage.css";
import Profile from "../../Assets/user-default-icon.png";
import { toast } from "react-toastify";
import {
  buyerUpdateProfileSchema,
  sellerUpdateProfileSchema,
} from "src/validationSchema/updateProfileSchema";
import { useSharedState } from "src/utils/SharedStateProvider";
import { userAPI } from "src/services/userApis";
import ReactSelect from "react-select";
import ProfileCrop from "./ProfileCrop";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import MobileProfileBackBtn from "./MobileProfileBackBtn";

export default function ProfilePage() {
  const {
    setLoading,
    setUserProfile,
    setProfileUpdated,
    setSelectedComponent,
  } = useSharedState();
  const [formData, setFormData] = useState({
    name: "",
    bio: "",
    //  phone: "",
    email: "",
    profileImage: "",
    skillIds: [],
  });
  const [user,setUser] = useState({
    name: "",
    bio: "",
    //  phone: "",
    email: "",
    profileImage: "",
    skillIds: [],
  })
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [skills, setSkills] = useState([]);
  const role = parseInt(localStorage.getItem("role"));
  useEffect(() => {
    if (role === 2) {
      userAPI
        .getAllSkills()
        .then((res) => {
          if (res.data.success) {
            const arr = [];
            res.data.body.data.forEach((element) => {
              arr.push({ value: element.id, label: element.name });
            });
            setSkills(arr);
          } else {
            toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        });
    }
  }, []);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const ids = selectedOptions.map((option) => parseInt(option.value));
    setFormData((prevFormData) => ({
      ...prevFormData,
      skillIds: ids,
    }));
  };

  const areUsersEqual = (user1, user2) => {
    return (
      user1.name === user2.name &&
      user1.bio === user2.bio &&
      user1.email === user2.email &&
      user1.profileImage === user2.profileImage &&
      JSON.stringify(user1.skillIds) === JSON.stringify(user2.skillIds)
    );
  }

  const getUserProfile = () => {
    userAPI
      .getUserProfile()
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.body.data.skills) {
            const arr = [];
            res.data.body.data.skills.forEach((element) => {
              arr.push({ value: element.id, label: element.name });
            });
            setSelectedOptions(arr);
          }
          setFormData({
            name: res.data.body.data.name,
            bio: res.data.body.data.bio || "",
            email: res.data.body.data.email,
            //   phone: res.data.body.data.phone,
            profileImage: res.data.body.data.profileImage || "",
            skillIds: res.data.body.data.skills.map((skill) =>
              parseInt(skill.id)
            ),
          });
          setUser({
            name: res.data.body.data.name,
            bio: res.data.body.data.bio || "",
            email: res.data.body.data.email,
            //   phone: res.data.body.data.phone,
            profileImage: res.data.body.data.profileImage || "",
            skillIds: res.data.body.data.skills.map((skill) =>
              parseInt(skill.id)
            ),
          });
          setName(res.data.body.data.name);
          setLoading(false);
          setUserProfile(res.data.body.data.profileImage);
          localStorage.setItem("profileImage", res.data.body.data.profileImage);
          localStorage.setItem("userName", res.data.body.data.name);
          setProfileUpdated(true);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Something went wrong!!");
      });
  };
  useEffect(() => {
    setLoading(true);
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    const { error } =
      role === 2
        ? sellerUpdateProfileSchema.validate(formData, {
            abortEarly: false,
          })
        : buyerUpdateProfileSchema.validate(formData, {
            abortEarly: false,
          });

    if (!error) {
      setErrors({});
      return true;
    }

    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });

    setErrors(newErrors);
    return false;
  };
  console.log(errors);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();


    if (validateForm()) {
      const updatedData = {
        ...formData,
        profileImage:
          formData.profileImage instanceof File
            ? formData.profileImage
            : formData.profileImage || Profile,
      };
      if(areUsersEqual(formData,user)){
        return false;
      }
      setLoading(true);
      userAPI
        .updateProfile(updatedData)
        .then(async (res) => {
          setLoading(false);
          if (res.data.success === true) {
            toast.success(res.data.body.message);
          } else {
            toast.error(res.data.body.message);
          }
          getUserProfile();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            // toast.error("Something went wrong!!");
          }
        });
      if (role === 2) {
        const ids = selectedOptions.map((option) => parseInt(option.value));
        userAPI
          .updateUserSkills({ skillIds: ids })
          .then((res) => {
            console.log(res.data.success);
          })
          .catch((err) => {
            toast.error("Something went wrong!");
            console.log(err);
          });
      }
    } else {
      setLoading(false);
    }
  };

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       profileImage: file,
  //     }));
  //   } else {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       profileImage: null,
  //     }));
  //   }
  // };
  const [isEditingProfileImage, setIsEditingProfileImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const characterCount = formData.bio.length;

  useEffect(() => {
    setSelectedComponent("My Profile");
  }, []);
  return (
    <>
      <Box className="profilepage" sx={{ paddingTop: "10px" }}>
        <MobileProfileBackBtn />
        <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" }}>
          {/* <label htmlFor="image-upload">
            <Button
              variant="outlined"
              component="span"
              style={{
                border: "none",
                borderRadius: 50,
                padding: 0,
              }}
            >
              <img
                src={
                  formData.profileImage === null || formData.profileImage === ""
                    ? Profile
                    : formData.profileImage instanceof File
                    ? URL.createObjectURL(formData.profileImage)
                    : `${baseURLs.yemo}/${formData.profileImage}`
                }
                alt="Profile"
                style={{ borderRadius: 50 }}
                className="size-82"
              />
              <AddAPhotoIcon style={{translate: "-1em 1em", color: "slategrey", fontSize: "20px"}}/>
            </Button>
          </label> */}
          {/* <input
            id="image-upload"
            type="file"
            accept=".svg, .jpg, .jpeg, .png"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          /> */}

          <ProfileCrop
            setIsEditingProfileImage={setIsEditingProfileImage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            formData={formData}
            setFormData={setFormData}
          />
          <Box
            sx={{
              ml: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{ fontSize: "16px", color: "#333333", fontWeight: "600" }}
              >
                {name}
              </Typography>
              <Typography
                variant="p"
                sx={(theme) => ({
                  fontSize: "14px",
                  color: "#AAABB3",
                  [theme.breakpoints.down("md")]: { fontSize: "12px" },
                })}
              >
                {formData.email}
              </Typography>
            </Box>
          </Box>
          {/* <ImageUpload onUpload={(files) => console.log(files)} /> */}
        </Box>
        <Divider />
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={6} sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Basic Information
              </Typography>
              <TextField
                name="name"
                placeholder="Full Name"
                label="Full Name*"
                value={formData.name}
                error={!!errors.name}
                helperText={errors.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                name="bio"
                label="Bio*"
                variant="outlined"
                value={formData.bio}
                error={!!errors.bio}
                onChange={handleChange}
                placeholder="i am a ceo of ABC company..."
                multiline
                fullWidth
                rows={3}
                sx={{ mt: 2 }}
                helperText={errors.bio}
              />

              <Typography variant="p" sx={{ ml: 1, fontSize: "12px" }}>
                ({characterCount}/100)
              </Typography>
              <TextField
                type="email"
                name="email"
                label="Email*"
                value={formData.email}
                placeholder="Email id"
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
                disabled
              />
              {/* <TextField
                placeholder="Mobile number"
                type="tel"
                name="phone"
                label="Mobile Number"
                value={formData.phone}
                error={!!errors.phone}
                helperText={errors.phone}
                onChange={handleChange}
                fullWidth
                sx={{ mt: 2 }}
              /> */}
              {role === 2 ? (
                <Box className="skillsBox" sx={{ mt: 2 }}>
                  <ReactSelect
                    isMulti
                    value={selectedOptions}
                    options={skills}
                    onChange={handleSelectChange}
                    fullWidth
                    placeholder="Skills*"
                    className={errors.skillIds ? "selectBorder" : ""}
                  />
                  {errors.skillIds ? (
                    <FormHelperText
                      style={{ color: "#d32f2f", paddingLeft: "10px" }}
                    >
                      Skills are required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}

              <Button
                type="submit"
                variant="contained"
                className="LoginButton"
                sx={{
                  mt: 2,
                  width: "fit-content",
                  borderRadius: "20px",
                }}
              >
                Save
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
