import React, { useRef, useEffect } from "react";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import "./UserStatus.css";
import { Link } from "react-router-dom";

const UserStatus = ({
  allStatus,
  setStatus,
  setUpdateStatus,
  setShowStatus,
}) => {
  const containerRef = useRef(null);

  const handleSelect = (id) => {
    setStatus(id);
    setUpdateStatus(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // Click occurred outside the component
        setShowStatus(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowStatus]);

  return (
    <Box className="UserStatus tour-status" ref={containerRef}>
      {allStatus.map((status, index) => (
        <Link key={status} onClick={(e) => handleSelect(status.id)}>
          {status.icon && <Avatar src={status.icon} alt="Avatar" />}
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            {status.name}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};

export default UserStatus;
