/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import "./Feedback.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";

const FeedbackForm = () => {
  const { jobId, setFeedbackSubmit } = useSharedState();
  const [questions, setQuestions] = React.useState([]);
  const [givenFeedback, setGivenFeedback] = React.useState([]);
  const [feedback, setFeedback] = React.useState({});
  const [formVisible, setFormVisible] = React.useState(false);
  const [givenVisibile, setGivenVisible] = React.useState(false);
  const [takenVisible, setTakenVisible] = React.useState(false);
  // Function to update a rating for a specific question
  const handleFeedbackChange = (questionId, feedback) => {
    // Convert the feedback value to a string
    const feedbackAsString = feedback.toString();

    setFeedback((prevFeedbacks) => ({
      ...prevFeedbacks,
      [questionId]: feedbackAsString,
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const [visible, setVisible] = React.useState(false);

  const allQuestionsRated = questions.every((question) =>
    feedback.hasOwnProperty(question.id)
  );

  const getFeedback = () => {
    jobAPI
      .getFeedback(jobId)
      .then((res) => {
        if (res.data.success) {
          setFormVisible(true);
          setGivenFeedback(res.data.data);
          setGivenVisible(true);
          setTakenVisible(true);
        } else if (res.data.given) {
          setFormVisible(false);
          setGivenFeedback([]);
          setVisible(false);
          setGivenFeedback(false);
          setTakenVisible(true);
          setFormVisible(false);
        } else if (res.data.taken) {
          setFormVisible(true);
          setGivenFeedback(false);
          setTakenVisible(false);
          setGivenFeedback([]);
          setVisible(true);
        } else {
          setFormVisible(true);
          setGivenFeedback(false);
          setTakenVisible(false);
          setGivenFeedback([]);
          setFeedback({});
          setVisible(true);
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const handleSubmit = () => {
    if (allQuestionsRated) {
      jobAPI
        .addFeedback(jobId, JSON.stringify(feedback))
        .then((res) => {
          setFeedbackSubmit(true);
          getFeedback();
          if (givenFeedback.length !== 0) {
            setTakenVisible(true);
            setGivenFeedback(true);
          } else {
            setFormVisible(false);
          }
          setVisible(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        });
    } else {
      toast.error("Please give all the ratings!");
    }
  };

  const getQuestions = () => {
    jobAPI
      .getFeedbackQuestions()
      .then((res) => {
        setQuestions(res.data.data);
        setFormVisible(true);
        setGivenVisible(false);
        setTakenVisible(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  React.useEffect(() => {
    getQuestions();
    getFeedback();
  }, [jobId]);

  return (
    <>
      {formVisible ? (
        <>
          <Box className="Feedback">
            <Typography variant="body1">Feedback </Typography>
            {givenVisibile && givenFeedback.length !== 0
              ? givenFeedback.map((question, index) => (
                  <Box key={question.id}>
                    <Box>
                      <Typography variant="p">
                        {" "}
                        <strong>
                          {index + 1}. {question.name}:{" "}
                        </strong>
                        {question.text}
                      </Typography>
                    </Box>
                    <Rating
                      name="simple-controlled"
                      value={question.feedback}
                    />
                  </Box>
                ))
              : ""}

            {!takenVisible && questions.length !== 0
              ? questions.map((question, index) => (
                  <Box key={question.id}>
                    <Box>
                      <Typography variant="p">
                        {" "}
                        <strong>
                          {index + 1}. {question.name}:{" "}
                        </strong>
                        {question.text}
                      </Typography>
                    </Box>
                    <Rating
                      name="simple-controlled"
                      value={feedback.questionId}
                      onChange={(event, newValue) => {
                        handleFeedbackChange(question.id, newValue);
                      }}
                    />
                  </Box>
                ))
              : ""}

            {!takenVisible ? (
              <Box sx={{ padding: "35px 0 0" }}>
                <Button
                  variant="contained"
                  className="LoginButton"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Submit Feedback
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box className="Feedback">
            Thank You! for taking the time to provide this valuable feedback.
            Your ratings help us ensure we continue to match you with the best
            engineers for your projects!
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default FeedbackForm;
