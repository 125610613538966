import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { stripePublishableKey } from "src/constants/api.constants";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(stripePublishableKey);
function ElementWrapper({setShowForm}) {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripeObject = await loadStripe(stripePublishableKey);
        setStripe(stripeObject);
      } catch (error) {}
    };

    initializeStripe();
  }, []);

  const options = {
    // passing the client secret obtained in step 3
    // clientSecret: secret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
    mode: "setup",
    currency: "usd",
  };

  return (
    <>
      <div>
        {stripe ? (
          <Elements stripe={stripe} options={options}>
            <div>
              <CheckoutForm />
            </div>

            {/* <StripePayment /> */}
          </Elements>
        ) : null}
      </div>
    </>
  );
}

export default ElementWrapper;
