/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Chat from "../Main/Chat";
import RightSidebar from "../RightSidebar/RightSidebar";
import { useParams } from "react-router-dom/dist";
import { useSharedState } from "src/utils/SharedStateProvider";
import { JobStateProvider } from "src/utils/jobStateProvider";
import { PaymentStateProvider } from "src/utils/PaymentStateProvider";
import { Box } from "@mui/material";

export const ChatPage = () => {
  const { setJobId } = useSharedState();
  const { id } = useParams();
  useEffect(() => {
    setJobId(id);
  }, [id]);
  return (
    <>
      <JobStateProvider>
        <PaymentStateProvider>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Chat />

            <RightSidebar />
          </Box>
        </PaymentStateProvider>
      </JobStateProvider>
    </>
  );
};
