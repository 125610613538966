import * as React from "react";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import "./AppAlert.css";

const colors = [
  {
    severity: "success",
    color: "#1EA35B",
  },
  {
    severity: "error",
    color: "#D65454",
  },
  {
    severity: "warning",
    color: "#C3A024",
  },
];
const getColorBySeverity = (severity) => {
  const colorObject = colors.find((item) => item.severity === severity);
  return colorObject ? colorObject.color : "";
};

const AppAlert = ({ content, severity }) => {
  return (
    <Stack className="AppAlert">
      <Alert
        icon={false}
        severity={severity}
        style={{ color: getColorBySeverity(severity) }}
      >
        {content}
      </Alert>

      {/* <Alert icon={false} severity="warning">
            You are now chatting with “Yemo”
          </Alert> */}
    </Stack>
  );
};

export default AppAlert;
