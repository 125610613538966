/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom/dist";
import { Notifications } from "../RightSidebar/Notifications";
import { useSharedState } from "src/utils/SharedStateProvider";
import ReactJoyride from "react-joyride";
import { socket } from "../Main/Message";

export const Dashboard = () => {
  const role = parseInt(localStorage.getItem("role"));
  const { showNotifications, showSiderBar } = useSharedState();
  const notificationsRef = useRef(null);
  const [runTour, setRunTour] = useState(localStorage.getItem("tour"));
  const location = useLocation();
  const pathNames = location.pathname.split("/");
  const getToken = () => localStorage.getItem("authProviderToken");
  const token = getToken();

  useEffect(() => {
    try {
      if (!socket.connected) {
        socket.auth = { token };
      }
    } catch (err) {
      console.log(err);
      socket.disconnect();
    }
  }, []);
  // console.log(
  //   "runtour",
  //   runTour,
  //   typeof localStorage.getItem("tour"),
  //   localStorage.getItem("tour")
  // );
  // useEffect(() => {
  //   const isFirstVisit = false
  //   if (isFirstVisit) {
  //     setRunTour(true);
  //   }
  // }, []);

  const sellerSteps = [
    {
      target: ".tour-jobs-list",
      content:
        "Explore your personalized Jobs List – a hub for managing, tracking, and overseeing all your active and completed projects.",
      disableBeacon: true,
    },
    {
      target: ".tour-myprofile",
      content: "Your can see your profile here",
    },
    {
      target: ".tour-status",
      content: "Your can change your status here",
    },
  ];
  const buyerSteps = [
    {
      target: ".tour-jobs-list",
      content:
        "Explore your personalized Jobs List – a hub for managing, tracking, and overseeing all your active and completed projects.",
    },
    {
      target: ".tour-wallet",
      content:
        "Track and manage your finances seamlessly with Wallet – monitor transactions, view balances, and handle all your financial interactions.",
    },
    {
      target: ".tour-create-job",
      content:
        "Jumpstart your projects with Create Job – a simple yet powerful tool allowing you to outline and launch tasks, connecting you directly with skilled prompt engineers for timely completion",
    },
    // Add more steps as needed
  ];

  return (
    <>
      <ReactJoyride
        steps={role === 1 ? buyerSteps : sellerSteps}
        run={runTour === "true"}
        continuous={true}
        showProgress={true}
        callback={(data) => {
          if (data.status === "finished") {
            setRunTour(false); // Set runTour to false after the tour is finished
            localStorage.setItem("tour", false);
          }
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          {showSiderBar ? "" : <Sidebar />}
          {/* <Sidebar/> */}
          <Box
            sx={{ flexDirection: "column", width: "100%" }}
            className={
              pathNames.length > 2
                ? "chat-main-container"
                : "chat-main-container hide"
            }
          >
            <Header />
            <Outlet />
          </Box>
        </Box>

        {showNotifications ? (
          <div ref={notificationsRef}>
            <Notifications />
          </div>
        ) : null}
      </Box>
    </>
  );
};
