/* eslint-disable react-hooks/exhaustive-deps */
// Files.js
import React, { useEffect, useState } from "react";
import "./Files.css";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { baseURLs } from "src/constants/api.constants";
import moment from "moment";
import AviIcon from "../../Assets/avi-icon.png";
import DownloadIcon from "@mui/icons-material/Download";
import { getFileExtension } from "src/utils/helper";
import { chatAPI } from "src/services/jobApis";

export const Files = () => {
  const { jobId } = useSharedState();
  // const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  // const [newFolderName, setNewFolderName] = useState("");
  const [files, setFiles] = useState([]);

  // Function to add the folder creation row
  // const addFolderCreationRow = () => {
  //   const folderRow = {
  //     id: "new-folder", // A unique ID for the folder row
  //     isFolderCreationRow: true, // Flag to identify this as the folder creation row
  //   };

  //   setFiles([...files, folderRow]); // Add the folder row to the files state
  //   setIsCreatingFolder(true); // Set create mode to true
  // };

  // Function to create the folder via API
  // const createFolder = () => {
  //   if (newFolderName.trim() === "") {
  //     toast.error("Folder name cannot be empty.");
  //     return;
  //   }
  //   jobAPI
  //     .createFolder(jobId, { folderName: newFolderName })
  //     .then((res) => {
  //       console.log("create folder", res);
  //       if (res.data.success === true) {
  //         setNewFolderName("");
  //         setIsCreatingFolder(false);
  //         getFilesByJobId();
  //       } else {
  //         toast.warning(res.data.error);
  //       }
  //     })
  //     .catch((err) => {

  //       console.log(err);
  //       toast.error("Something went wrong !!");
  //     });
  // };

  useEffect(() => {
    // Fetch the initial list of files by calling getFilesByJobId here
    getFilesByJobId();
  }, [jobId]);

  // Function to fetch files by job ID
  const getFilesByJobId = () => {
    chatAPI
      .getFilesByJobId({ jobId })
      .then((res) => {
        setFiles(res.data.data.messages);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const handleDownloadFile = (file) => {
    const fileUrl = `${baseURLs.yemo}/${file}`;

    // Create an anchor element
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl;
    anchor.target = "_blank";
    anchor.click();
  };
  console.log(files);
  return (
    <Box className="FileCnt">
      {files.map((item, i) => (
        <>
        <div key={item} className="file-item">
          <a
            className="img-icon"
            href={`${baseURLs.yemo}`}
            target="_blank"
            download
            rel="noreferrer"
          >
            <img
              src={AviIcon}
              alt="a vector icon of file"
              height="36px"
              width="36px"
            />
            <p className="file-img-text"> {getFileExtension(item.content)}</p>
          </a>
          <Stack direction="column">
            <Typography className="FileText">{item.content}</Typography>
            <Typography className="FileText">
              {moment(item.createdAt).format("DD-MM-YYYY|hh:mmA")}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ marginLeft: "auto" }}>
            <IconButton
              sx={{ padding: "0px" }}
              onClick={() => {
                handleDownloadFile(item.file);
              }}
            >
              <DownloadIcon />
            </IconButton>
            {/* <IconButton sx={{padding:"0px"}}>
            <img src={VerticalDots} alt="3 vertical dots"  />
          </IconButton> */}
          
          </Stack>
          
        </div>
        <Divider/>
        </>
      ))}
         
    </Box>
  );
};
