import axios from "axios";
import { baseURLs } from "../constants/api.constants";

const baseURL = baseURLs.yemo;
const baseInstance = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${() => localStorage.getItem("authProviderToken")}`,
  },
};

export const yemoApiQuery = axios.create({
  baseURL,
  ...baseInstance,
});
