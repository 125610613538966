import * as React from "react";
import { Box, Typography } from "@mui/material";
export default function Footer() {
  return (
    <>
      <Box
        sx={(theme) => ({
          my: 2,
          textAlign: "center",
          [theme.breakpoints.down("md")]: { display: "none" },
        })}
      >
        <Typography variant="p" sx={{ fontSize: "14px", color: "#9D9EA8" }}>
          YeMo.ai 2024 © All Rights Reserved. V0.0.1
        </Typography>
      </Box>
    </>
  );
}
