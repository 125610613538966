/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useReducer, useCallback } from "react";
import { AuthContext } from "./auth-context";
import { setSession } from "./helper";
import { userAPI } from "src/services/userApis";

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

//const STORAGE_KEY = 'authProviderToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("authProviderToken");
      if (accessToken) {
        setSession(accessToken);
        // Fetch user details
        const response = await userAPI.getUserById();

        const { data: user } = response.data.body;

        dispatch({
          type: "INITIAL",
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);
  useEffect(() => {
    initialize();
  }, []);

  // LOGIN
  const login = useCallback(async (userdata, accessToken) => {
    // const data = {
    //   email,
    //   password,
    // };

    // const response = await userAPI.login(data);

    // if (response.data.success === true) {
    //   const { token: accessToken, user } = response.data.data;

    setSession(accessToken);

    dispatch({
      type: "LOGIN",
      payload: {
        user: {
          ...userdata,
          accessToken,
        },
      },
    });
    // }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: "jwt",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      login,
      logout,
      initialize
    }),
    [initialize,login, logout, state.user, status]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
