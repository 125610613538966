/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MyCardsDetails from "./MyCardDetails";
import TranscationTable from "./TranscationTable";
import { useSharedState } from "src/utils/SharedStateProvider";
import MobileProfileBackBtn from "./MobileProfileBackBtn";

export default function Payment() {
  const { setSelectedComponent } = useSharedState();
  const [value, setValue] = useState("1");
  const privacyTabs = [
    { label: "Wallet & Cards", value: "1" },
    { label: "Invoices", value: "2" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setSelectedComponent("Wallet & Transactions");
  }, []);
  return (
    <div>
      <Box className="profilepage">
        <MobileProfileBackBtn />
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {privacyTabs.map((obj) => (
                  <Tab label={obj.label} value={obj.value} key={obj.value} />
                ))}
              </TabList>
            </Box>
            <TabPanel value="1" className="MyCardContainer">
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  {
                    <Box
                      sx={(theme) => ({
                        [theme.breakpoints.down("md")]: { fontSize: "14px" },
                      })}
                    >
                      <MyCardsDetails />
                    </Box>
                  }
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" className="MyCardContainer">
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  {
                    <Box
                      sx={(theme) => ({
                        [theme.breakpoints.down("md")]: { fontSize: "14px" },
                      })}
                    >
                      <TranscationTable />
                    </Box>
                  }
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </div>
  );
}
