import { ChevronLeft } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MobileProfileBackBtn = () => {
  const navigate = useNavigate();
  return (
    <Box className="mob_profile_btn" sx={{ padding: "0.5em 0" }}>
      <Button onClick={() => navigate("..")}>
        <ChevronLeft />
      </Button>
    </Box>
  );
};

export default MobileProfileBackBtn;
