import { emailFormat } from "src/constants/config";

const Joi = require("joi");

export const emailObj = {
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(emailFormat)
    .required()
    .messages({
      "string.pattern.base": "Invalid Email",
      "string.empty": "Email is required",
      "string.email": "Invalid Email Format",
    }),
};
export const passwordObj = {
  password: Joi.string()
    .pattern(
      /^(?=(?:[^A-Z]*[A-Z]){1})(?=(?:[^a-z]*[a-z]){2})(?=(?:[^\d]*\d))[A-Za-z\d!@#$%^&*]{8,}$/
    )
    .required()
    .messages({
      "string.pattern.base": `Enter a strong Password`,
      "string.empty": `Password is required`,
    }),
};

export const emailSchema = Joi.object({ ...emailObj });

export const loginUserSchema = Joi.object({
  ...emailObj,
  password: Joi.string().required().messages({
    "string.empty": `Password is required`,
  }),
});

export const resetPasswordSchema = Joi.object({
  ...passwordObj,
  confirmPassword: Joi.string()
    .valid(Joi.ref("password"))
    .required()
    .label("Confirm Password")
    .messages({ "any.only": "{{#label}} does not match the password" }),
});
