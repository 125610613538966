import React, { useState } from "react";
import { FormHelperText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import "./RejectReasonForm.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useNavigate } from "react-router-dom";
import { rejectReasonSchema } from "src/validationSchema/rejectReasonSchema";
import { socket } from "../Main/Message";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";

const RejectReasonForm = () => {
  const { jobId, setAcceptInvite } = useSharedState();
  const navigate = useNavigate();
  const getName = () => localStorage.getItem("userName");
  const [rejectData, setRejectData] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setRejectData((prevFormData) => ({ ...prevFormData, [name]: value }));
  // };
  const handleTextArea = (event) => {
    const { name, value } = event.target;
    if (value.length <= 100) {
      setRejectData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const validateForm = () => {
    const { error } = rejectReasonSchema.validate(rejectData, {
      abortEarly: false,
    });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };
  const handleLeaveJob = (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (jobId) {
        jobAPI
          .rejectJob(rejectData, jobId)
          .then((res) => {
            if (res.data.success === true) {
              setAcceptInvite(true);
              navigate("/dashboard");
              const messageData = {
                jobId: jobId,
                message: `${getName()} reject  your job!!`,
              };
              socket.emit("yemoMessage", { messageData });
              socket.emit("rightSideBar", messageData);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong!!");
          });
      }
    }
  };
  const characterCount = rejectData.description.length;
  return (
    <>
      <Box className="leftMessage">
        <Typography variant="p">I see you have rejected the job</Typography>
      </Box>

      <Box className="FormContainer">
        <Typography variant="body1">
          Let us know the reason you rejected the job
        </Typography>

        <Box>
          <TextField
            fullWidth
            label="Title*"
            id="fullWidth"
            name="title"
            value={rejectData.title}
            onChange={handleTextArea}
            error={!!errors.title}
          />
          <FormHelperText>{errors.title}</FormHelperText>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Tell us your reason*"
            multiline
            name="description"
            onChange={handleTextArea}
            maxRows={6}
            value={rejectData.description}
            error={!!errors.description}
          />
          <Typography variant="p" sx={{ ml: 1, fontSize: "12px" }}>
            ({characterCount}/100)
          </Typography>
          <FormHelperText>{errors.description}</FormHelperText>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              className="LoginButton"
              onClick={handleLeaveJob}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default RejectReasonForm;
