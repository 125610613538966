import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";

export default function Loading() {
  return (
    <Stack direction="row" spacing={2} className="LoadingBar">
      <LoadingButton loading variant="outlined" size="large">
        {/* Submit */}
      </LoadingButton>
    </Stack>
  );
}
