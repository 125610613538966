import React, { useEffect, useState } from "react";
import { Box, Typography, Button, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./Contact.css";
import "../Main/ContactForm.css";
import { toast } from "react-toastify";
import logo from "../../Assets/512_-removebg-preview1.png";
import contactbox from "../../Assets/contact-box.png";
import contactUsSchemaHome from "src/validationSchema/contactUsSchemaHome";
import { useSharedState } from "src/utils/SharedStateProvider";
import { jobAPI } from "src/services/jobApis";

const Contact = () => {
  const { setLoading } = useSharedState();
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [feedback, setFeedback] = useState({
    email: "",
    feedback: "",
    selectedOption: "",
    feedbackImage: [],
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFeedback((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleTextArea = (event) => {
    const { name, value } = event.target;
    if (value.length <= 100) {
      setFeedback((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const getFeedBackTypes = () => {
    jobAPI
      .getFeedbackTypes()
      .then((res) => {
        setOptions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!!");
      });
  };
  useEffect(() => {
    getFeedBackTypes();
  }, []);
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    // const file = event.target.files[0];
    if (files.length > 0) {
      setFeedback((prevFormData) => ({
        ...prevFormData,
        feedbackImage: files,
      }));
    } else {
      setFeedback((prevFormData) => ({
        ...prevFormData,
        feedbackImage: null,
      }));
    }
  };
  const validateForm = () => {
    const { error } = contactUsSchemaHome.validate(feedback, {
      abortEarly: false,
    });
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };
  const submitFeedback = (event) => {
    event.preventDefault();
    setLoading(true);
    if (validateForm()) {
      jobAPI
        .getPlatFormFeedBackHome(feedback)
        .then((res) => {
          toast.success(res.data.message);
          setFeedback({
            email: "",
            feedback: "",
            selectedOption: "",
            feedbackImage: [],
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const characterCount = feedback.feedback.length;
  return (
    <>
      <Box className="contactCnt">
        <Box className="ContactFormContainer">
          <Box className="ContactLeft">
            <Link to="/">
              <img src={logo} alt="logo" height={28} />
            </Link>
            <Typography
              variant="body1"
              className="title-getInTouch"
              sx={{ lineHeight: 1 }}
            >
              Get in Touch
            </Typography>
            <img
              src={contactbox}
              alt="logo"
              className="contactImg img-fluid d-md-none"
            />
          </Box>
          <Box className="ContactRight">
            <Box className="ContactForm">
              <Typography variant="body1" sx={{ lineHeight: 1, mb: 2 }}>
                Send us your query and we’ll get back to you
              </Typography>
              <form onSubmit={submitFeedback}>
                <Box className="textFieldContactForm">
                  <TextField
                    placeholder="Email"
                    label="Email*"
                    name="email"
                    value={feedback.email}
                    error={!!errors.email}
                    onChange={handleChange}
                    fullWidth
                    helperText={errors.email}
                    sx={{ paddingBottom: "7px" }}
                  />
                  <FormControl
                    fullWidth
                    className="selectList"
                    sx={{ paddingTop: "20px" }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ paddingTop: "20px" }}
                    >
                      Select feedback topic*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="selectedOption"
                      label="Select feedback topic*"
                      onChange={handleChange}
                      value={feedback.selectedOption}
                      error={!!errors.selectedOption}
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          className="customSelectBox"
                          value={option.id.toString()}
                        >
                          {option.type}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {errors.selectedOption}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <TextField
                  id="outlined-multiline-static"
                  label="Anything else you’d like to share?*"
                  multiline
                  rows={4}
                  name="feedback"
                  value={feedback.feedback}
                  onChange={handleTextArea}
                  error={!!errors.feedback}
                  className="contactHome"
                  helperText={errors.feedback ?? `(${characterCount}/100)`}
                  placeholder="I have feedback on..."
                />
                <label
                  htmlFor="formFileMultiple"
                  style={{ cursor: "pointer", display: "contents" }}
                >
                  <Box
                    className="UploadCnt"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <UploadFileIcon />
                    Click to upload or drag and drop
                    <Typography variant="body1">
                      SVG, JPG, JPEG, PNG & PDF(max. 3MB)
                    </Typography>
                    <input
                      type="file"
                      multiple
                      id="formFileMultiple"
                      style={{ display: "none" }}
                      name="feedbackImage"
                      accept=".svg, .jpg, .jpeg, .png, .pdf"
                      onChange={handleImageUpload}
                    />
                    {feedback.feedbackImage.length > 0 && (
                      <div>
                        <p>Selected files:</p>
                        <ul>
                          {feedback.feedbackImage.map((file, index) => (
                            <div key={file}>{file.name}</div>
                          ))}
                        </ul>
                      </div>
                    )}
                    {errors.feedbackImage && (
                      <div style={{ color: "#d32f2f" }}>
                        {errors.feedbackImage}
                      </div>
                    )}
                  </Box>
                </label>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
        <Box
          className="YemoFooter contact"
          sx={(theme) => ({
            backgroundColor: "white",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              textAlign: "center",
            },
          })}
        >
          <Typography
            variant="p"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                width: "100%",
                order: "2",
                mt: 1,
                fontSize: "12px",
                flexDirection: "column",
              },
            })}
          >
            YeMo.ai 2023 © All Rights Reserved
          </Typography>
          <Box
            className="footerLinks"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: { width: "100%" },
            })}
          >
            <Link target="_blank" to="/PrivacyPolicy">
              Policies
            </Link>
            <Link target="_blank" to="/TermsAndConditions">
              Terms
            </Link>
            <Link to="">Contact us</Link>
          </Box>
        </Box>
      </Box>
    </>
    // <>
    //   <Box className="LoginHeader" sx={{ backgroundColor: "white" }}>
    //     <Link to="/">
    //       <img src={logo} alt="logo" height={28} />
    //     </Link>
    //   </Box>

    //   <Box className="contactUsForm">
    //     <Typography variant="body2">
    //       Email us at <Link href="#">support@yemo.ai</Link>
    //     </Typography>

    //     <Typography variant="body1">Give us a Feedback </Typography>

    //     <Box>
    //       <TextField
    //         placeholder="Email"
    //         label="Email"
    //         value={feedback.email}
    //         onChange={handleChange}
    //         fullWidth
    //         sx={{ mt: 2 }}
    //       />
    //       <FormControl fullWidth className="selectList">
    //         <InputLabel id="demo-simple-select-label">
    //           Please select feedback topic
    //         </InputLabel>
    //         <Select
    //           labelId="demo-simple-select-label"
    //           id="demo-simple-select"
    //           label="please select feedback topic"
    //           name="selectedOption"
    //           onChange={handleChange}
    //           anchorEl={anchorEl}
    //         >
    //           {options.map((option, index) => (
    //             <MenuItem className="customSelectBox" value={option.id}>
    //               {option.type}
    //             </MenuItem>
    //           ))}
    //         </Select>
    //       </FormControl>
    //     </Box>

    //     <TextField
    //       id="outlined-multiline-static"
    //       label="Anything else you’d like to share?"
    //       multiline
    //       rows={4}
    //       name="feedback"
    //       value={feedback.feedback}
    //       onChange={handleChange}
    //       placeholder="i have feedback on..."
    //       helperText="0/100"
    //     />

    //     <label htmlFor="formFileMultiple" style={{ cursor: "pointer" }}>
    //       <Box
    //         className="UploadCnt"
    //         style={{ display: "flex", flexDirection: "column" }}
    //       >
    //         <UploadFileIcon />
    //         Click to upload or drag and drop
    //         <Typography variant="body1">
    //           SVG, PNG, JPG, or GIF (max. 3MB)
    //         </Typography>
    //         <input
    //           type="file"
    //           id="formFileMultiple"
    //           style={{ display: "none" }}
    //           name="feedbackImage"
    //           onChange={handleImageUpload}
    //         />
    //       </Box>
    //     </label>
    //     <Button
    //       variant="contained"
    //       onClick={() => {
    //         submitFeedback();
    //       }}
    //     >
    //       Submit Feedback
    //     </Button>
    //   </Box>
    //   <Box sx={{ backgroundColor: "white", paddingBottom: 3 }}>
    //     <Divider />
    //     <Typography
    //       variant="p"
    //       sx={{
    //         color: "#999999",
    //         fontSize: "14px",
    //         paddingLeft: 3,
    //         paddingBottom: 1,
    //       }}
    //     >
    //       YeMo.ai 2023 © All Rights Reserved
    //     </Typography>
    //   </Box>
    // </>
  );
};

export default Contact;
