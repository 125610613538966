import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import LoginFooter from "../LoginFooter/LoginFooter";
import LoginHeader from "../LoginHeader/LoginHeader";

export const ResetPasswordSuccess = () => {
  return (
    <>
      <Box
        className="LoginPage"
        sx={{ minHeight: "100svh", display: "flex", flexDirection: "column" }}
      >
        <LoginHeader />
        <Box className="SignIn">
          <Grid
            container
            sx={{
              background: "white",
              borderRadius: "20px",
              maxWidth: "564px",
              margin: "0 auto",
            }}
            className="ForgotPasswordBox"
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                p: 2,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", textAlign: "center" }}
                >
                  Reset Password
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", textAlign: "center" }}
                >
                  Ta-da! Your password's reset.
                </Typography>
              </Box>
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <Typography
                  variant="p"
                  sx={(theme) => ({
                    lineHeight: "1.3",
                    [theme.breakpoints.down("md")]: { fontSize: "14px" },
                  })}
                >
                  Go ahead, sign-in with your shiny new key.
                  <br />
                  Welcome back!
                </Typography>
              </Box>
              <Link to={"/signin"}>
                <Button
                  variant="contained"
                  className="LoginButton"
                  sx={{ width: "100%", mt: 2 }}
                >
                  Login
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <LoginFooter />
      </Box>
    </>
  );
};
