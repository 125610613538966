import React from 'react'

export default function JobInvoice({data}) {
  return (
    <div className='pdf-job'>
        <header className="pdf-header"></header>
    <main className="pdf-main">
      <div className="footer-div">
        <div>
          <h2>Tax Invoice</h2>
          <p><strong>Invoice No:</strong>{data?.invoiceNo}</p>
          <p><strong>Order No.:</strong>{data?.orderNo}</p>
        </div>
        <div>
          <p><strong>Invoice Date:</strong>{data?.date}</p>
          <p><strong>Order Date:</strong>{data?.date}</p>
          <p><strong>Nature of transaction:</strong>Online</p>
        </div>
      </div>
      <hr className="pdf-hr"/>
      <div className="footer-div">
        <div>
          <h3>Customer Details</h3>
          <p>Name: {data?.name} <br />
            Email: {data?.email} <br />
          </p>
        </div>
        <div>
          <h3>Billing Address</h3>
          <p>Degnevenget 26, 3600 Snostrup <br /> Frederikssund, Denmark</p>
        </div> 
      </div>
      <table className="pdf-table">
        <tr>
          <th className="pdf-th">#</th>
          <th className="pdf-th">Item Name</th>
          <th className="pdf-th">Sub-Total</th>
          <th className="pdf-th">Discount</th>
          <th className="pdf-th">Tax</th>
          <th className="pdf-th">Platform Fee</th>
          <th className="pdf-th">Total Amount</th>
        </tr>
        <tr>
          <td className="pdf-td">1</td>
          <td className="pdf-td">{data?.jobName}</td>
          <td className="pdf-td">$ {data?.baseAmount}</td>
          <td className="pdf-td">$ {data?.discount}</td>
          <td className="pdf-td">$ {data?.tax}</td>
          <td className="pdf-td">$ {data?.platformFee}</td>
          <td className="pdf-td">$ {data?.total}</td>
        </tr>
        <tr className="subtotal-row">
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <th className="pdf-th">Subtotal</th>
          <th className="pdf-th">$ {data?.total}</th>
        </tr>
        <tr className="subtotal-row">
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <td className="pdf-td"></td>
          <th className="pdf-th">Total</th>
          <th className="pdf-th">$ {data?.total}</th>
        </tr>
      </table>
    </main>
    <p>Thank you for doing business with us. Have a good day!</p>
    <footer className="pdf-footer">
      <div className="footer-div">
        <p>
          8th Floor, Corporate House, Bodakdev, Ahmedabad - 380054. <br />
          Phone: +91 79 2685 2554
        </p>
        <h1 className="pdf-h1">YeMo</h1>
      </div>
      <hr className="pdf-hr"/>
      <p>
        If you have any questions or queries feel free to reach out to us at
        support@yemo.com
      </p>
    </footer>
    </div>
  )
}
