import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DotMenuHorizontal from "./DotMenuHorizontal";
import "./ProjectName.css";
import { useJobSharedState } from "src/utils/jobStateProvider";

const ProjectName = () => {
  const { userJob } = useJobSharedState();
  const role = localStorage.getItem("role");
  return (
    <div className="ProjectName">
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
        }}
      >
        <ListItem>
          <ListItemText primary="Project name" secondary={userJob.name} />
          {role === "2" ? <DotMenuHorizontal /> : ""}
        </ListItem>
      </List>
    </div>
  );
};

export default ProjectName;
