/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { socket } from "../Main/Message";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";

export default function ProjectComplete({ setShowMarkAsComplete }) {
  const {
    jobId,
    setComplete,
    isPopupVisible,
    setIsPopupVisible,
    setMarkAsCompleteUpdate,
  } = useSharedState();
  const {
    userJob,
    updateJob,
    setShowRejectReasonForm,
    setDispute,
    disputeEngineer,
    setDisputeEngineer,
    disputeYemo,
    setDisputeYemo,
  } = useJobSharedState();
  const userName = localStorage.getItem("userName");
  const handelMarkAsComplete = (data) => {
    jobAPI
      .markJobAsComplete(jobId, { markAsComplete: data })
      .then((res) => {
        if (res.data.success === true) {
          const updatedUserJob = {
            ...userJob,
            jobStatus: "completed",
          };
          updateJob(updatedUserJob);
          setComplete(true);
          const messageData = {
            jobId: jobId,
            message: `${userName} accept the request for completion`,
          };
          socket.emit("rightSideBar", messageData);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!!");
      });
  };

  const rejectRequestForCompletion = () => {
    jobAPI
      .rejectRequestForCompletion(jobId)
      .then((res) => {
        setIsPopupVisible(false);
        setDisputeEngineer(false);
        setDispute(false);
        const updatedUserJob = {
          ...userJob,
          markAsComplete: false,
        };
        updateJob(updatedUserJob);
        setShowMarkAsComplete(true);
        const messageData = {
          jobId: jobId,
        };
        socket.emit("yemoMessage", { messageData });

        // socket.emit("rightSideBar", jobId)
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };
  const handleConfirmation = () => {
    setDispute(true);
    setIsPopupVisible(false);
  };
  const handleShowDisputeForm = () => {
    setIsPopupVisible(false);
    setDispute(false);
    setDisputeYemo(false);
    setShowRejectReasonForm(true);
  };

  useEffect(() => {
    if (disputeEngineer) {
      rejectRequestForCompletion();
    }
  }, [disputeEngineer]);
  useEffect(() => {
    if (disputeYemo) {
      handleShowDisputeForm();
    }
  }, [disputeYemo]);
  return (
    <>
      {isPopupVisible ? (
        <Box
          className="projectCompletionBox"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Typography sx={{ marginBottom: 0 }}>
              Request for Project Completion
            </Typography>
          </div>
          <Box className="AcceptRejectButtons" sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              className="LoginButton"
              onClick={() => {
                handelMarkAsComplete(true);
                setMarkAsCompleteUpdate(true);
              }}
              sx={{ py: 1, px: 2 }}
            >
              Accept
            </Button>
            <Button
              variant="contained"
              className="rejectButton"
              color="secondary"
              onClick={() => handleConfirmation()}
              sx={{ py: 1, px: 2 }}
            >
              Conflict
            </Button>
          </Box>
        </Box>
      ) : (
        // :
        //   disputeSuccess === false ?
        //   <RejectAlert rejectRequestForCompletion={rejectRequestForCompletion} handleShowDisputeForm={handleShowDisputeForm}/>
        ""
      )}
    </>
  );
}
