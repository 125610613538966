import React, { createContext, useContext, useState } from "react";

const SharedStateContext = createContext();

export const SharedStateProvider = ({ children }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [chatLoading, setChatLoading] = useState("");
  const [paymentLoadScroll, setPaymentLoadScroll] = useState(false);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [getJobs, setGetJobs] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [lastMessage, setLastMessage] = useState("");
  const [sendLastMessage, setSendLastMessage] = useState(false);
  const [complete, setComplete] = useState(false);
  const [acceptInvite, setAcceptInvite] = useState(false);
  const [feedbackSubmit, setFeedbackSubmit] = useState(false);
  const [notificationCouter, setNotificationCouter] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletUpdated, setWalletUpdated] = useState(false);
  const [rechargeDone, setRechargeDone] = useState(false);
  const [updateName, setUpdateName] = useState(false);
  const [markAsCompleteUpdate, setMarkAsCompleteUpdate] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [updatedRightSideBar, setUpdatedRightSideBar] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [jobName, setJobName] = useState("");
  const [showCardComponent, setShowCardComponent] = useState(false);
  const [showSiderBar, setShowSiderBar] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [hideRightSideBar, setHideRightSideBar] = useState(false);
  const [ searchMessage, setSearchMessage] = useState('')
  const [search, setSearch] = useState(false);
  const [openMenu,setOpenMenu] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [totalFound,setTotalFound] = useState();
  const highlightedMessageRef = React.useRef(null);
  const [filteredMessages  ,setFilteredMessages] = useState([]);
  const [highlightedMessage, setHighlightedMessage] = useState()   
  const [showChatHeader, setShowChatHeader] = useState(true);
  const [jobIds,setJobIds] = useState([]);
  const [gotJobs,setGotJobs] = useState(false);
  return (
    <SharedStateContext.Provider
      value={{
        isClicked,
        setIsClicked,
        jobId,
        setJobId,
        loading,
        setLoading,
        userProfile,
        setUserProfile,
        chatLoading,
        setChatLoading,
        showNotifications,
        setShowNotifications,
        paymentLoadScroll,
        setPaymentLoadScroll,
        confirmPayment,
        setConfirmPayment,
        getJobs,
        setGetJobs,
        profileUpdated,
        setProfileUpdated,
        lastMessage,
        setLastMessage,
        sendLastMessage,
        setSendLastMessage,
        complete,
        setComplete,
        acceptInvite,
        setAcceptInvite,
        feedbackSubmit,
        setFeedbackSubmit,
        notificationCouter,
        setNotificationCouter,
        isPopupVisible,
        setIsPopupVisible,
        walletBalance,
        setWalletBalance,
        walletUpdated,
        setWalletUpdated,
        rechargeDone,
        setRechargeDone,
        updateName,
        setUpdateName,
        markAsCompleteUpdate,
        setMarkAsCompleteUpdate,
        showRightSidebar,
        setShowRightSidebar,
        updatedRightSideBar,
        setUpdatedRightSideBar,
        isEditing,
        setIsEditing,
        openDelete,
        setOpenDelete,
        jobName,
        setJobName,
        showCardComponent,
        setShowCardComponent,
        showSiderBar,
        setShowSiderBar,
        selectedComponent,
        setSelectedComponent,
        hideRightSideBar,
        setHideRightSideBar,
        searchMessage, 
        setSearchMessage,
        search, setSearch,
        openMenu,setOpenMenu,
        highlightedIndex, setHighlightedIndex,
        totalFound,setTotalFound,
        highlightedMessageRef,
        filteredMessages  ,setFilteredMessages,
        highlightedMessage, setHighlightedMessage,
        showChatHeader, setShowChatHeader,
        jobIds,setJobIds,
        gotJobs,setGotJobs
      }}
    >
      {children}
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => useContext(SharedStateContext);
