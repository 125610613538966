/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import ListItemButton from "@mui/material/ListItemButton";
import "./Sidebar-JobList.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { stringAvatar } from "src/utils/helper";
import { toast } from "react-toastify";
import { chatAPI, jobAPI } from "src/services/jobApis";
import { supervisorApi } from "src/services/api";
import { Chip } from "@mui/material";

export default function Completed({ completed, setCompleted }) {
  const { setJobId, jobId, lastMessage, setGotJobs,setJobIds } = useSharedState();
  const [clicked, setClicked] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [sortedJobs, setSortedJobs] = useState([]);
  const { id } = useParams("id") || 0;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const role = parseInt(localStorage.getItem("role"));
  const getCompletedJobs = async () => {
    if (role === 4) {
      supervisorApi
        .getAllCompletedJob()
        .then((res) => {
          setJobs(res.data.data);
          const ids = res.data.data.map((job)=>job.id);
          setJobIds(ids);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    } else {
      jobAPI
        .getCompletedJobs()
        .then((res) => {
          setJobs(res.data.data);
          const ids = res.data.data.map((job)=>job.id);
          setJobIds(ids); 
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    }
  };

  const updateCount = (jobs, jobIdToUpdate) =>
    jobs.map((job) =>
      job.id === jobIdToUpdate
        ? {
            ...job,
            messages: [
              {
                ...job.messages[0],
                unreadMessageCount: 0,
              },
            ],
          }
        : job
    );
  const markAsReadMessages = (jobId) => {
    const objectWithJobId = sortedJobs.find(
      (item) => parseInt(item.id) === parseInt(jobId)
    );
    if (
      objectWithJobId.messages[0]?.userId !== parseInt(userId) &&
      objectWithJobId.messages[0]?.userId !== 1
    ) {
      chatAPI
        .markAsRead(jobId)
        .then((res) => {
          setClicked(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    } else {
      const updatedJobs = updateCount(sortedJobs, parseInt(jobId));
      setJobs(updatedJobs);
    }
  };
  useEffect(() => {
    getCompletedJobs();
    if (completed) {
      setCompleted(false);
    }
  }, [clicked, completed]);

  const handleClick = (id) => {
    setJobId(id);
    navigate(`/dashboard/chat/${id}`);
  };

  useEffect(() => {
    // Sort the jobs whenever the "jobs" array changes
    const sortedJobs = [...jobs].sort((a, b) => {
      const timeA = a.createdAt || a.updatedAt;
      const timeB = b.createdAt || b.updatedAt;
      return new Date(timeB) - new Date(timeA);
    });
    setSortedJobs(sortedJobs);
    setGotJobs(true);
  }, [jobs]);

  const updateJobStatus = (jobs, jobIdToUpdate, newLastMessage) =>
    jobs.map((job) =>
      job.id === jobIdToUpdate
        ? {
            ...job,
            messages: [{ ...job.messages[0], content: newLastMessage }],
          }
        : job
    );

  useEffect(() => {
    const updatedJobs = updateJobStatus(jobs, jobId, lastMessage);
    setJobs(updatedJobs);
  }, [lastMessage]);

  // useEffect(()=>{
  //   const ids = sortedJobs.map((job)=>job.id);
  //   setJobIds(ids);
  // },[sortedJobs])
  return (
    <div>
      {sortedJobs?.length === 0
        ? " "
        : sortedJobs.map((job) => (
            <List
              key={job.id}
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem
                alignItems="flex-start"
                className={`completed ${
                  job.id === parseInt(id) ? "active-job" : ""
                }`}
              >
                <ListItemButton
                  onClick={() => {
                    handleClick(job.id);
                    markAsReadMessages(job.id);
                    setClicked(true);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar {...stringAvatar(job.name)} />
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      job.messages?.length === 0
                        ? " "
                        : moment(job.messages[0].createdAt).format("h:mmA")
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          className={`chip ${job.jobStatus}`}
                          component="span"
                        >
                          {job.jobStatus === "refund" ? "Refund" : "Completed"}
                        </Typography>
                        <Typography
                          sx={{ display: "inline", margin: "10px" }}
                          className={`chip`}
                          component="span"
                        >
                          {job.jobUid}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          className="title"
                          component="span"
                        >
                          {job.name}
                        </Typography>

                        <Typography
                          sx={{ display: "inline" }}
                          className="desc"
                          component="span"
                        >
                          {job.messages?.length === 0
                            ? " "
                            : job.messages[0].content?.length > 20 // Adjust '20' as needed
                            ? `${job.messages[0].content.substring(0, 30)}...`
                            : job.messages[0].content}
                        </Typography>

                        {job.unreadMessageCount !== 0 ? (
                          <Typography
                            sx={{ display: "inline" }}
                            className="badge"
                            component="span"
                          >
                            {job.unreadMessageCount}
                          </Typography>
                        ) : (
                          ""
                        )}
                        <br />
                        {role === 4 ? (
                          <table>
                            <tr>
                              <td>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    paddingRight: "20px",
                                  }}
                                >
                                  Duration
                                </Typography>
                              </td>
                              <td>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    paddingRight: "20px",
                                  }}
                                >
                                  Price
                                </Typography>
                              </td>
                              <td>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    paddingRight: "20px",
                                  }}
                                >
                                  Complexity
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {job.duration}
                                </Typography>
                              </td>
                              <td>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {job.price}$
                                </Typography>
                              </td>
                              <td>
                              {job.complexity === 'high' ?
                              <Typography
                                sx={{ display: "inline", paddingRight: "20px" }}
                              >
                                <Chip
                                  variant="outlined"
                                  sx={{ color: "red" , backgroundColor:"#FFCFCE"}}
                                  label="High"
                                />
                              </Typography>
                     :''}
                      {job.complexity === 'medium' ?
                              <Typography
                                sx={{ display: "inline", paddingRight: "20px" }}
                              >
                                <Chip
                                  variant="outlined"
                                  sx={{ color: "#F1B032", backgroundColor:"#FFF0D3" }}
                                  label="Medium"
                                />
                              </Typography>
                    :''}
                     {job.complexity === 'low' ?
                              
                                <Typography
                                sx={{ display: "inline", paddingRight: "20px" }}
                              >
                                <Chip
                                  variant="outlined"
                                  sx={{ color: "#4DD965", backgroundColor:"#D3FFDB" }}
                                  label="Low"
                                />
                              </Typography>
                              :""}
                              </td>
                            </tr>
                          </table>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          ))}
    </div>
  );
}
