import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import ProfileImg from "../../Assets/user-default-icon.png";
import "./SeeProfile.css";
import { useJobSharedState } from "src/utils/jobStateProvider";
import moment from "moment";
import { baseURLs } from "src/constants/api.constants";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";

const SeeProfile = () => {
  const { userJob } = useJobSharedState();

  const LoginUser = parseInt(localStorage.getItem("userId"));
  const [userProfile, setUserProfile] = useState(null);
  const role = parseInt(localStorage.getItem("role"));
  const { userId, jobId } = useJobSharedState();

  useEffect(() => {
    if (role === 4) {
      if (!userId || userId === null) {
        const user = userJob.users.find((entry) => entry.user.roleId === 2);
        if (user) {
          jobAPI
            .getChatProfile(user.user.id)
            .then((res) => {
              setUserProfile(res.data.body.data);
            })
            .catch((err) => {
              console.log(err);

              toast.error("Something went wrong!!");
            });
        }
      } else {
        jobAPI
          .getChatProfile(userId)
          .then((res) => {
            setUserProfile(res.data.body.data);
          })
          .catch((err) => {
            console.log(err);

            toast.error("Something went wrong!!");
          });
      }
    } else {
      if (userJob.users && Symbol.iterator in Object(userJob.users)) {
        if (userJob.users !== undefined) {
          for (const user of userJob.users) {
            if (user.user.id !== LoginUser) {
              jobAPI
                .getChatProfile(user.user.id)
                .then((res) => {
                  setUserProfile(res.data.body.data);
                })
                .catch((error) => {
                  console.error("Error fetching user profile:", error);
                  toast.error("Something went wrong!!");
                });
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userJob, jobId]);
  // console.log(userId);

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  const getRelativeTime = (dateTimeString) => {
    const now = moment();
    const inputDateTime = moment(dateTimeString);
    const duration = moment.duration(now.diff(inputDateTime));

    const days = duration.asDays();
    const hours = duration.asHours();
    const minutes = duration.asMinutes();

    if (days >= 1) {
      return `${Math.floor(days)} days ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} hrs ago`;
    } else if (minutes >= 1) {
      return `${Math.floor(minutes)} mins ago`;
    } else {
      return "--";
    }
  };
  return (
    <>
      <Box className="SeeProfileCnt">
        <Box className="pBox ratings" align="center">
          <img
            src={
              userProfile.user?.profileImage
                ? `${baseURLs.yemo}/${userProfile.user.profileImage}`
                : ProfileImg
            }
            alt="ProfileImg"
            height="82px"
            width="82px"
            style={{ borderRadius: 50 }}
          />

          <Typography variant="name">
            {userProfile.user.name || null}
          </Typography>

          <Box
            sx={{
              width: 232,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="text-feedback"
              value={userProfile.avgFeedback}
              readOnly
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
            <Box sx={{ ml: 2 }}>
              {userProfile.avgFeedback ? userProfile.avgFeedback : 0} Ratings (
              {userProfile.distinctReviews} Reviews)
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="pBox">
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>From</TableCell>
                  <TableCell>India</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Member Since</TableCell>
                  <TableCell>
                    {moment(userProfile.user.createdAt).format("MMMM YYYY")}
                  </TableCell>
                </TableRow>
                {userProfile.user.roleId === 2 ? (
                  <TableRow>
                    <TableCell>Last Delivery</TableCell>
                    <TableCell>
                      {getRelativeTime(
                        userProfile.lastDelivery[0]?.job.updatedAt
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>Projects Completed</TableCell>
                    <TableCell>{userProfile.projectsCompleted}</TableCell>
                  </TableRow>
                )}
                {role === 4 ? (
                  <TableRow>
                    <TableCell>Active Projects</TableCell>
                    <TableCell>
                      <Chip
                        variant="filled"
                        label={`${userProfile.user?.jobs?.length} Projects Active`}
                        className="activeProject"
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Divider />
        {userProfile.user.roleId === 2 && userProfile.skills.length !== 0 ? (
          <Box className="pBox">
            <Typography variant="title">Skills</Typography>
            <Stack direction="row">
              {userProfile.skills.map((item, i) => (
                <Chip key={item} label={item.name} />
              ))}
            </Stack>
          </Box>
        ) : null}

        <Divider />
        {userProfile.user.bio !== null ? (
          <Box className="pBox">
            <Typography variant="title">BIO</Typography>
            <Typography variant="span">{userProfile.user.bio}</Typography>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default SeeProfile;
