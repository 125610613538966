/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import Profile from "../../Assets/user-default-icon.png";
import AiProfile from "../../Assets/app-icon.png";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { baseURLs } from "src/constants/api.constants";
import { useSharedState } from "src/utils/SharedStateProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ProjectDetailsIcon from "../../Assets/icon-project-details.svg";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoAlerts from "../RightSidebar/InfoAlerts";
import { stringAvatar } from "src/utils/helper";
import SearchIcon from "@mui/icons-material/Search";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
export default function ChatHeader() {
  const { userJob } = useJobSharedState();
  const LoginUser = parseInt(localStorage.getItem("userId"));
  const [userProfile, setUserProfile] = useState(null);
  const { jobId } = useSharedState();
  const {
    showRightSidebar,
    setShowRightSidebar,
    setIsEditing,
    setOpenDelete,
    searchMessage,
    setSearchMessage,
    search,
    setSearch,
    highlightedIndex,
    setHighlightedIndex,
    totalFound,
    filteredMessages,
    setHighlightedMessage,
    setFilteredMessages,
    setTotalFound,
  } = useSharedState();
  const { setShowLeaveJobForm, showLeaveJobForm } = useJobSharedState();
  const role = parseInt(localStorage.getItem("role"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setUserProfile(null);
    if (Array.isArray(userJob.users)) {
      for (const userList of userJob.users) {
        if (userList.user.id !== LoginUser) {
          setUserProfile(userList.user);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userJob, jobId]);
  useEffect(() => {
    setSearch(false);
    setSearchMessage("");
    setTotalFound(null)
  }, [jobId]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditProject = () => {
    setShowRightSidebar(true);
    setIsEditing(true);
    handleClose();
  };
  const handleDeleteProject = () => {
    setOpenDelete(true);
    handleClose();
  };
  const handleLeaveJob = () => {
    if (
      userJob.jobStatus === "inprogress" ||
      userJob.jobStatus === "completed"
    ) {
      setShowLeaveJobForm(!showLeaveJobForm);
    }
    handleClose();
  };
  const handleClearInput = () => {
    setSearchMessage("");
    setTotalFound(null)
    
  };
  const handleNavigate = () => {
    let newIndex = highlightedIndex + 1;
    if (newIndex < 0) newIndex = totalFound - 1;
    if (newIndex >= totalFound) newIndex = 0;
    setHighlightedIndex(newIndex);
    setHighlightedMessage(null);
    setHighlightedMessage(filteredMessages[newIndex].id);
  };
  const downButton = () => {
    let newIndex = highlightedIndex - 1;
    if (newIndex < 0) newIndex = totalFound - 1;
    if (newIndex >= totalFound) newIndex = 0;
    setHighlightedIndex(newIndex);
    setHighlightedMessage(null);
    setHighlightedMessage(filteredMessages[newIndex].id);
  };


  return (
    <>
      <Box
        className="chatheader"
        sx={(theme) => ({ mt: 1, [theme.breakpoints.down("md")]: { mt: 0 } })}
      >
        <Box
          className="chatHeaderInnerContainer"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: { display: "none" },
            })}
          >
            <Link to="..">
              <Button sx={{ minWidth: "40px", height: "40px" }}>
                <ChevronLeftIcon />
              </Button>
            </Link>
          </Box>
          {role === 4 ? (
            <Avatar {...stringAvatar(userJob.name, jobId)} />
          ) : (
            <img
              src={
                userProfile?.profileImage
                  ? `${baseURLs.yemo}/${userProfile.profileImage}`
                  : userJob.jobStatus === 'pending' ? AiProfile : Profile
              }
              alt="aiprofile"
              height="35px"
              width="35px"
              style={{ borderRadius: 50 }}
            />
          )}
          <Box sx={{ ml: 1 }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "16px", color: "#333333", fontWeight: "600" }}
            >
              {userProfile?.name || "Yemo"}
            </Typography>
            <Box
              sx={(theme) => ({
                display: "none",
                [theme.breakpoints.down("md")]: { display: "block" },
              })}
            >
              <InfoAlerts></InfoAlerts>
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.down("md")]: { ml: "auto", display: "flex" },
            })}
          >
            <Button
              sx={{ minWidth: "40px", height: "40px" }}
              onClick={() => {
                setShowRightSidebar(!showRightSidebar);
              }}
            >
              <img src={ProjectDetailsIcon} alt="" />
            </Button>
            <IconButton
              sx={(theme) => ({
                minWidth: "40px",
                height: "40px",
                display: "none",
                [theme.breakpoints.down("md")]: {
                  ml: "auto",
                  display: "block",
                },
              })}
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon sx={{ color: "#000" }} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{ "aria-labelledby": "long-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {parseInt(role) === 1 ? (
                <>
                  <MenuItem onClick={handleEditProject}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" sx={{ color: "#000" }} />
                    </ListItemIcon>
                    <Typography sx={{ fontSize: "14px" }}>
                      Edit Project
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleDeleteProject}>
                    <ListItemIcon>
                      <DeleteOutlineIcon
                        fontSize="small"
                        sx={{ color: "#000" }}
                      />
                    </ListItemIcon>
                    <Typography sx={{ fontSize: "14px" }}>
                      Delete Project
                    </Typography>
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={handleLeaveJob}>
                  <ListItemIcon>
                    <HighlightOffIcon fontSize="small" sx={{ color: "#000" }} />
                  </ListItemIcon>
                  <Typography sx={{ fontSize: "14px" }}>
                    Leave Project
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              setSearch(true);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>
      {search ? (
        <Box
          sx={{
            padding: "11px 100px",
            background: "white",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextField
            name="searchBar"
            fullWidth
            value={searchMessage}
            onChange={(e) => setSearchMessage(e.target.value)}
            placeholder="Find Message in your conversation "
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchMessage && (
                    <ClearIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleClearInput}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>{" "}
          {totalFound > 0 && (
            <Typography variant="p">
              {highlightedIndex + 1}/{totalFound}
            </Typography>
          )}
         {totalFound > 0 && <>
          <IconButton>
            <ExpandLessIcon onClick={() => downButton()} />
          </IconButton>
          <IconButton onClick={() => handleNavigate()}>
            <ExpandMoreIcon />
          </IconButton>
          </>}
          <Button
            onClick={() => {
              setSearch(false);
              setHighlightedMessage(null);
              setFilteredMessages([]);
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
