import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import "./ActionButtons.css";
import "./ProjectCompleteConfirmation.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { socket } from "../Main/Message";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";
import { useJobSharedState } from "src/utils/jobStateProvider";

const ProjectCompleteConfirmation = ({ setShowMarkAsComplete }) => {
  const { jobId } = useSharedState();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const userName = localStorage.getItem("userName");
  const { userJob, updateJob } = useJobSharedState();
  const handelMarkAsComplete = () => {
    jobAPI
      .updateStatusMarkAsComplete(jobId)
      .then((res) => {
        if (res.data.success === true) {
          const updatedUserJob = {
            ...userJob,
            markAsComplete: true,
          };
          updateJob(updatedUserJob);
          // console.log("i am update context",updatedUserJob);
          const messageData = {
            jobId: jobId,
            message: `${userName} has requested for job completion`,
          };
          socket.emit("rightSideBar", messageData);
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  };

  return (
    <>
      {isPopupVisible ? (
        <Box
          className="projectCompletionBox"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Typography sx={{ marginBottom: 0 }}>
              Are you sure you want to mark the project complete?
            </Typography>
          </div>
          <Box className="AcceptRejectButtons" sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              className="LoginButton"
              onClick={() => {
                handelMarkAsComplete();
              }}
              sx={{ py: 1, px: 2 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="rejectButton"
              color="secondary"
              onClick={() => setIsPopupVisible(false)}
              sx={{ py: 1, px: 2 }}
            >
              No
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            className="LoginButton"
            sx={{ width: "100%" }}
            onClick={() => setIsPopupVisible(true)}
          >
            Mark as Complete
          </Button>
        </Box>
      )}
    </>
  );
};

export default ProjectCompleteConfirmation;
