import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import "./DeleteConfirmationPopUp.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useNavigate } from "react-router-dom";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { toast } from "react-toastify";
import { jobAPI } from "src/services/jobApis";

function DeleteConfirmationPopup({ onDelete }) {
  const { jobId, setJobId, openDelete, setOpenDelete } = useSharedState();
  const { userJob } = useJobSharedState();
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    jobAPI
      .deleteJob(jobId)
      .then((res) => {
        if (res.data.success === true) {
          setJobId(null);
          setOpenDelete(false);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!!");
      });
  };

  return (
    <div>
      {userJob.jobStatus !== "pending"  ? (
        <Link href="#">Delete</Link>
      ) : (
        <Link href="#" onClick={handleClickOpen}>
          Delete
        </Link>
      )}
      <Dialog
        open={openDelete}
        onClose={handleClose}
        className="ConfirmationPopup"
      >
        <DialogTitle>Are you Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to delete this job? You cannot undo this.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteConfirmationPopup;
