/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Box } from "@mui/material";
import "./Main.css";
import CalendarConnect from "../profile/CalendarConnect";

import NoJob from "./NoJob";
import { userAPI } from "src/services/userApis";
import { Link, useLocation } from "react-router-dom";
// import Calendar from "./Calendar";
import { chatAPI } from "src/services/jobApis";
import { toast } from "react-toastify";
import TimeCalendar from "./TimeCalender";
import moment from "moment";
import { useSharedState } from "src/utils/SharedStateProvider";
import MobileProfileBackBtn from "../profile/MobileProfileBackBtn";
export default function NoJobCreated() {
  const [skip, setSkip] = React.useState(false);
  const [next, setNext] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [gotEvents, setGotEvents] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [calendars, setCalendars] = React.useState({
    google: false,
    microsoft: false,
    all: false,
  });
  const { selectedComponent } = useSharedState();
  const location = useLocation();
  const role = parseInt(localStorage.getItem("role"));
  const getCalender = async () => {
    try {
      const google = await userAPI.getCalendarEvents();
      const microsoft = await userAPI.getMicrosoftEvents();
      if (google.data.success && microsoft.data.success) {
        setCalendars({
          google: false,
          microsoft: false,
          all: true,
        });
        setNext(true);
      } else if (google.data.success) {
        setCalendars({
          google: true,
          microsoft: false,
          all: false,
        });
      } else if (microsoft.data.success) {
        setCalendars({
          google: false,
          microsoft: true,
          all: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCalenderEvents = () => {
    chatAPI
      .getUserEvents()
      .then((res) => {
        if (res.data.success) {
          setEvents(res.data.data);
          setGotEvents(true);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      });
  };

  async function formatToCustomFormat(googleCalendarDate) {
    const parsedDate = await moment(
      googleCalendarDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    parsedDate.subtract(5, "hours");
    parsedDate.subtract(30, "minutes");
    const customFormattedDate = parsedDate.format("YYYY-MM-DD[T]HH:mm");
    return customFormattedDate;
  }

  const handleEventClick = async (event) => {
    // setEvent({
    //   summary: "",
    //   description: "",
    //   location: "",
    //   startDateTime: "",
    //   endDateTime: "",
    // });
    setSelectedDate(
      await formatToCustomFormat(event.event._instance.range.start)
    );
  };

  React.useEffect(() => {
    getCalender();
    getCalenderEvents();
    const dateObject = new Date();
    const resultString = dateObject.toISOString().slice(0, 16);
    setSelectedDate(resultString);
    if (role === 2) {
      setSkip(true);
    }
  }, [role]);
  const containsSpecificValue = location.pathname.includes("/calendar");
  React.useEffect(() => {
    if (containsSpecificValue) {
      setSkip(true);
    } else {
      setSkip(false);
      setNext(false);
    }
  }, [containsSpecificValue, location]);

  // fc-bg-event
  return (
    <>
      {role === 1 ? (
        <Box
          className={
            calendars.all || next || selectedComponent === null
              ? "nojobcreated center"
              : "nojobcreated"
          }
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: containsSpecificValue
                ? "block !important"
                : "none !important",
            },
          })}
        >
          <MobileProfileBackBtn />

          {(calendars.all || next) && !skip ? (
            // selectedDate ? (
            <TimeCalendar
              events={events}
              setEvents={setEvents}
              handleEventClick={handleEventClick}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setSkip={setSkip}
              gotEvents={gotEvents}
              setGotEvents={setGotEvents}
            />
          ) : // ) : (
          //   <Calendar
          //     events={events}
          //     handleEventClick={handleEventClick}
          //     selectedDate={selectedDate}
          //   />
          // )
          calendars.all || skip 
          // || selectedComponent === null 
          ? (
            <CalendarConnect
              calendars={calendars}
              setCalendars={setCalendars}
              setSkip={setSkip}
              setNext={setNext}
            />
            ) : (
            <NoJob />
          )}
          <Box
            sx={{
              position: "absolute",
              bottom: "1em",
              right: "1em",
              gap: "0.5em",
            }}
          >
            <Link to="/PrivacyPolicy" className="privacyLinks" target="_blank">
              Policies
            </Link>
            <Link
              to="/TermsAndConditions"
              target="_blank"
              className="privacyLinks"
            >
              Terms
            </Link>
            <Link to="/ContactUs" target="_blank" className="privacyLinks">
              Contact Us
            </Link>
          </Box>
        </Box>
      ) : (
        <Box
          className="nojobcreated center"
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: { display: "none !important" },
          })}
        >
          <NoJob />
          <Box
            sx={{
              position: "absolute",
              bottom: "1em",
              right: "1em",
              gap: "0.5em",
            }}
          >
            <Link to="/PrivacyPolicy" className="privacyLinks" target="_blank">
              Policies
            </Link>
            <Link
              to="/TermsAndConditions"
              target="_blank"
              className="privacyLinks"
            >
              Terms
            </Link>
            <Link to="/ContactUs" target="_blank" className="privacyLinks">
              Contact Us
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
}
