import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

export default function RightMessageProfile({ msg }) {
  return (
    <div>
      {" "}
      <Box className="MessageDetail" sx={{ mt: 2 }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "12px",
            pr: 1,
            textAlign: "right",
            color: "#9D9EA8",
            fontWeight: "500",
          }}
        >
          {msg.name} {moment(msg.created_at).format("h:mmA")}
        </Typography>
      </Box>
    </div>
  );
}
