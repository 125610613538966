import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { userAPI } from "src/services/userApis";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "src/utils/auth-context";

export default function DeleteAccount() {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    userAPI
      .deleteUser({ reason, otherReason })
      .then((res) => {
        console.log("user Delete", res);
        navigate("/");
        localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenDeleteDialog(false);
  };
  const handleDeactivateAccount = () => {
    userAPI
      .decativateUser()
      .then((res) => {
        if (res.data.success === true) {
          navigate("/");
          localStorage.clear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenDeactivateDialog(false);
  };

  const handleReasonChange = (event) => {
    const selectedReason = event.target.value;
    setReason(selectedReason);

    // If 'other' is selected, clear previous otherReason value
    if (selectedReason !== "other") {
      setOtherReason("");
    }
  };
  const { user, initialize } = useAuth();
  console.log(user);

  const updateTour = () => {
    userAPI
      .updateTour()
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          initialize();
          toast.success("Tour Disabled successfully");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("user.tour", user.tour);
  return (
    <Box>
      {/* <Box
        sx={{
          my: 2,
          px: 2,
          display: "flex",
          alignItems: "center",
          fontWeight: "700",
        }}
      >
        <Button
          sx={{ minWidth: "40px", height: "40px" }}
          
        >
          <ChevronLeftIcon />
        </Button>
        Delete account
      </Box> */}
      <Box sx={{ paddingLeft: "43px" }}>
        {user.tour ? (
          <Box sx={{paddingBottom:"1em"}}>
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "1em",
              }}
            >
              Disable Intro Tour on Startup{" "}
            </Typography>
            <Link style={{ color: "#018385" }} onClick={() => updateTour()}>
              Stop Tour
            </Link>
          </Box>
        ) : (
          ""
        )}

        <Typography
          variant="subtitle1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Deleting your account will do the following things
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <HighlightOffIcon color="error" sx={{ marginRight: "8px" }} />
          Log you out of all devices
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <HighlightOffIcon color="error" sx={{ marginRight: "8px" }} />
          Delete all your account information
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <HighlightOffIcon color="error" sx={{ marginRight: "8px" }} />
          Delete all your projects, files, transactions & payment details, etc
        </Typography>
        <Box sx={{ marginTop: "2%" }}>
          <Link
            sx={{ color: "#018385" }}
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete My Account
          </Link>
        </Box>
        <Box sx={{ marginTop: "2%" }}>OR</Box>
        <Box sx={{ marginTop: "2%" }}>
          <Typography
            variant="subtitle1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Deactivate account
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            This will deactivate your account. Your name and profile will no
            longer be operable. <br />
            You can restore your account anytime you want.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "2%" }}>
          <Link
            sx={{ color: "#018385" }}
            onClick={() => setOpenDeactivateDialog(true)}
          >
            Deactivate My Account
          </Link>
        </Box>
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Why are you deleting your account?</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="delete-reason"
              name="delete-reason"
              value={reason}
              onChange={handleReasonChange}
            >
              <FormControlLabel
                value="I don’t need it anymore "
                control={<Radio />}
                label="I don’t need it anymore "
              />
              <FormControlLabel
                value="It’s too expensive"
                control={<Radio />}
                label="It’s too expensive"
              />
              <FormControlLabel
                value="I’m switching to someone else"
                control={<Radio />}
                label="I’m switching to someone else"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
            {reason === "other" && (
              <TextField
                label="Specify Other Reason*"
                value={otherReason}
                onChange={(event) => setOtherReason(event.target.value)}
                fullWidth
              />
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            className="LoginButton"
            sx={{ color: "#FFFFFF" }}
          >
            I've changed my mind
          </Button>
          <Button
            onClick={handleDeleteAccount}
            sx={{ padding: "8px 14px" }}
            className="rejectButton"
          >
            Delete My Account
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeactivateDialog}
        onClose={() => setOpenDeactivateDialog(false)}
      >
        <DialogTitle>Deactivate your account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will deactivate your account for 60 days
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeactivateDialog(false)}
            className="LoginButton"
            sx={{ color: "#FFFFFF" }}
          >
            I've changed my mind
          </Button>
          <Button
            onClick={handleDeactivateAccount}
            sx={{ padding: "8px 14px" }}
            className="rejectButton"
          >
            Deactivate My Account
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
