/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./SchedulePopUp.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Profile from "../../Assets/user-default-icon.png";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  Avatar,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Calendar from "react-calendar";

import moment from "moment";
import axios from "axios";
import { DateTime } from "luxon";
import LanguageIcon from "@mui/icons-material/Language";
import CreatableSelect from "react-select/creatable";
import calenderClock from "../../Assets/calendar-clock.svg";
import { useSharedState } from "src/utils/SharedStateProvider";
import { toast } from "react-toastify";
import { socket } from "./Message";
import { chatAPI } from "src/services/jobApis";
import meetingSchema from "src/validationSchema/meetingSchema";

const { zones } = require("tzdata");
//import "./ActionButtons.css";

//import "./PopUp.css";
const durations = [15, 30, 60, 90, 120];
export default function SchedulePopUp({ popUpVisible, setPopUpVisible }) {
  const { jobId } = useSharedState();
  const [open, setOpen] = React.useState(popUpVisible);
  // const [scroll, setScroll] = React.useState("paper");
  const [isPopupVisible, setIsPopupVisible] = useState(popUpVisible);
  const [date, setDate] = useState(new Date());
  const [range, setRange] = useState("");
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [duration, setDuration] = useState(durations[1]);
  const [emails, setEmails] = useState([]);
  const [ipAddress, setIpAddress] = useState("");
  const [nextButton, setNextButton] = useState(false);
  const [matchingTimeSlots, setMatchingTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [otherUser, setOtherUser] = useState({});
  const [meeting, setMeeting] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({ title: "", description: "" });
  const role = parseInt(localStorage.getItem("role"));
  useEffect(() => {
    const timeZoneNames = Object.keys(zones).filter(
      (key) => typeof zones[key] === "string"
    );
    setTimeZones(timeZoneNames);
  }, []);

  useEffect(() => {
    // Fetch IP address
    axios.get("https://api.ipify.org?format=json").then((response) => {
      setIpAddress(response.data.ip);
    });

    // Get the current time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimeZone(userTimeZone);
    // setEvent({
    //   ...event,
    //   timeZone: userTimeZone,
    // });
    // Example of using luxon to work with time zones
    const now = DateTime.now().setZone(userTimeZone);
    console.log("Current time in user timezone:", now.toISO());

    // Format a date to the user's time zone
    const someDate = DateTime.fromISO(now, { zone: userTimeZone });
    console.log(
      "Formatted date:",
      someDate.toLocaleString(DateTime.DATETIME_FULL)
    );
  }, []);

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  useEffect(() => {
    if (popUpVisible) {
      setIsPopupVisible(true);
      setOpen(true);
    }
  }, [popUpVisible]);
  const handleClose = () => {
    setOpen(false);
    setPopUpVisible(false);
    setIsPopupVisible(true);
  };

  function formatAndCreateDate(dateString) {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      const formattedDate = moment(date).format("ddd, MMM D, YYYY"); // Include day
      return formattedDate;
    } else {
      return "Invalid date";
    }
  }

  const handleChangeTimeZone = (timeZone) => {
    setSelectedTimeZone(timeZone);
  };

  const handleCreate = (inputValue) => {
    // Handle the creation of a new email and add it to the emails array
    setEmails((prevEmails) => [...prevEmails, inputValue]);
  };

  const handleRemove = (removedEmail) => {
    // Handle the removal of an email from the emails array
    setEmails((prevEmails) =>
      prevEmails.filter((email) => email !== removedEmail)
    );
  };

  const selectedValues = emails.map((email) => ({
    value: email,
    label: email,
  }));
  const handelNextPage = () => {
    setNextButton(true);
  };
  const formattedDate = formatAndCreateDate(date);

  function breakTimeIntoOneHourSlots(inputArray) {
    const outputArray = [];

    inputArray.forEach((item) => {
      const startHour = new Date(`2023-01-01T${item.start}`);
      const endHour = new Date(`2023-01-01T${item.end}`);

      while (startHour < endHour) {
        const nextHour = new Date(startHour);
        nextHour.setHours(nextHour.getHours() + 1);

        outputArray.push({
          start: startHour.toTimeString().substring(0, 5),
          end: nextHour.toTimeString().substring(0, 5),
        });

        startHour.setHours(startHour.getHours() + 1);
      }
    });

    return outputArray;
  }
  const [startLoading, setStartLoading] = useState(false);
  const getMatchingSlots = () => {
    if (formattedDate) {
      chatAPI
        .getMatchingTimeSlots({ jobId, date: formattedDate })
        .then((res) => {
          setMatchingTimeSlots(
            breakTimeIntoOneHourSlots(
              role === 2
                ? res.data.otherUserAvailableSlots
                : res.data.userAvailableSlots
            )
          );
          setStartLoading(false);
          setOtherUser(res.data.otherUser);
        })
        .catch((err) => {
          console.log(err);
          setStartLoading(false);
        });
    }
  };
  useEffect(() => {
    setStartLoading(true);
    getMatchingSlots();
  }, [jobId, formattedDate]);

  const calculateEndTime = (startTime, duration) => {
    const [hours, minutes] = startTime.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const endTimeInMinutes = totalMinutes + duration;
    const endHours = Math.floor(endTimeInMinutes / 60);
    const endMinutes = endTimeInMinutes % 60;

    // Ensure leading zeros for single-digit hours and minutes
    const endHoursStr = endHours.toString().padStart(2, "0");
    const endMinutesStr = endMinutes.toString().padStart(2, "0");

    return `${endHoursStr}:${endMinutesStr}`;
  };

  const handleSelectTimeSlot = (timeSlot) => {
    const startTime = timeSlot.start;
    const endTime = calculateEndTime(startTime, duration);
    const selectedTimeSlot = { start: startTime, end: endTime };

    // Set the selectedTimeSlot state with the calculated values
    setSelectedSlot(selectedTimeSlot);
  };
  const handleChangeDuration = (duration) => {
    if (selectedSlot) {
      const startTime = selectedSlot.start;
      const endTime = calculateEndTime(startTime, duration);
      const updatedTimeSlot = { start: startTime, end: endTime };

      // Update the selectedTimeSlot state with the new end time
      setSelectedSlot(updatedTimeSlot);
    }
    setDuration(duration);
  };
  const characterCount = meeting.description.length;

  const validateForm = () => {
    const { error } = meetingSchema.validate(meeting, { abortEarly: false });

    if (error) {
      const newErrors = {};
      error.details.forEach((detail) => {
        newErrors[detail.path[0]] = detail.message;
      });
      setErrors(newErrors);
      return false;
    }

    setErrors({ title: "", description: "" });
    return true;
  };

  const scheduleMeeting = () => {
    if (!selectedSlot.start) {
      toast.info("Please select a time slot!");
    } else {
      if (validateForm()) {
        chatAPI
          .scheduleMeeting({
            otherUserId: otherUser.id,
            meeting,
            timeZone: selectedTimeZone,
            emails: selectedValues,
            date: formattedDate,
            selectedSlot,
            duration,
            jobId,
          })
          .then((res) => {
            if (res.data.success) {
              toast.success(res.data.message);
              const messageData = {
                jobId: jobId,
                message: `meeeting updated`,
                stats: "accepted",
              };
              socket.emit("yemoMessage", { messageData });
            } else {
              toast.error(res.data.message);
            }
            setPopUpVisible(false);
            setOpen(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong!");
          });
      }
    }
  };
  const handleSubmit = () => {
    scheduleMeeting();
  };

  useEffect(() => {
    setSelectedSlot({});
  }, [formattedDate]);
  const handleDateChange = (date) => {
    const providedDate = new Date(date);
    const currentDate = new Date();
    if (providedDate >= currentDate) {
      setDate(date);
    }
  };
  return (
    <div>
      {!nextButton ? (
        <Box sx={{ p: 2 }}>
          <Dialog
            open={open}
            onClose={handleClose}
            //  scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="schedulePopUp"
          >
            <DialogTitle id="scroll-dialog-title" className="scheduleTitle">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                // container
                alignItems="center"
              >
                <Box item>
                  <Typography variant="h5" fontWeight={1000}>
                    Schedule a call
                  </Typography>
                </Box>
                <Button
                  sx={{
                    textDecoration: "none",
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "inherit !important", // Remove hover color change
                    },
                    color: "black",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </Button>
              </Box>
            </DialogTitle>
            <Divider />

            <DialogContent
            //dividers={scroll === "paper"}
            >
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <Grid container alignItems="center">
                  <Grid item xl={6} mb={6} sm={6}>
                    <Calendar
                      onChange={(e) => {
                        handleDateChange(e);
                      }}
                      value={date}
                    />
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    mb={6}
                    sm={6}
                    sx={{ borderLeft: "1px solid #ccc" }}
                  >
                    <Typography
                      sx={{ paddingLeft: "4%", paddingBottom: "2%" }}
                      variant="h6"
                    >
                      {formattedDate}
                    </Typography>
                    <Box className="timeSlotBox">
                      {matchingTimeSlots.length > 0 ? (
                        matchingTimeSlots.map((timeSlot, index) => (
                          <Button
                            className={
                              timeSlot.start === selectedSlot.start
                                ? "timeSlotsSelected"
                                : "timeSlots"
                            }
                            key={timeSlot}
                            onClick={() => {
                              handleSelectTimeSlot(timeSlot);
                            }}
                          >
                            {" "}
                            {timeSlot.start}{" "}
                          </Button>
                        ))
                      ) : startLoading ? (
                        <Typography style={{ paddingLeft: "5px" }}>
                          Calculating available time slots...
                        </Typography>
                      ) : (
                        <Typography style={{ paddingLeft: "5px" }}>
                          No matching time slot found!
                        </Typography>
                      )}

                      {/* <Button className="timeSlots"> 12:00 AM </Button>
                      <Button className="timeSlots"> 12:00 AM </Button>
                      <Button className="timeSlots"> 12:00 AM </Button>
                      <Button className="timeSlots"> 12:00 AM </Button> */}
                    </Box>
                    <TextField
                      className="scheduleFields"
                      select
                      // label="Timezone"
                      placeholder="Timezone*"
                      value={selectedTimeZone}
                      onChange={(e) => {
                        handleChangeTimeZone(e.target.value);
                      }}
                      fullWidth
                      InputProps={{
                        // Add LanguageIcon as startAdornment
                        startAdornment: (
                          <LanguageIcon
                            color="action"
                            sx={{ marginRight: "2%" }}
                          />
                        ),
                      }}
                    >
                      {timeZones.map((timeZone, index) => (
                        <MenuItem key={timeZone} value={timeZone}>
                          {timeZone}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      className="scheduleFields"
                      select
                      disabled={selectedSlot.start === undefined}
                      // label="Duration"
                      placeholder="Duraton*"
                      value={duration}
                      onChange={(e) => {
                        handleChangeDuration(e.target.value);
                      }}
                      fullWidth
                    >
                      {durations.map((duration, index) => (
                        <MenuItem key={duration} value={duration}>
                          {duration} Minutes
                        </MenuItem>
                      ))}
                    </TextField>

                    <CreatableSelect
                      className="creatableSelect"
                      isMulti
                      value={selectedValues}
                      onChange={(selectedOptions) => {
                        // Update the emails array with selected email strings
                        setEmails(
                          selectedOptions.map((option) => option.value)
                        );
                      }}
                      onCreateOption={handleCreate}
                      onInputChange={(inputValue) => inputValue.trim()}
                      onRemove={(removedValue) =>
                        handleRemove(removedValue.value)
                      }
                      placeholder="Type or select email addresses..."
                    />
                    <IconButton
                      onClick={handelNextPage}
                      sx={{ backgroundColor: "#333333", float: "inline-end" }}
                    >
                      <ChevronRightIcon
                        sx={{ color: "white", fontSize: "25px" }}
                      />{" "}
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <Box sx={{ p: 2, width: "1000px" }}>
          <Dialog
            open={open}
            onClose={handleClose}
            // scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className="schedulePopUp"
          >
            <DialogTitle id="scroll-dialog-title" className="scheduleTitle">
              <Grid container alignItems="center">
                <Grid item xl={11} paddingLeft={1}>
                  <Typography variant="h6" fontWeight={1000}>
                    <IconButton onClick={() => setNextButton(false)}>
                      <KeyboardArrowLeftIcon />
                    </IconButton>{" "}
                    Schedule a call
                  </Typography>
                </Grid>
                <Grid item xl={1}>
                  <IconButton
                    sx={{ float: "inline-end" }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {" "}
                    <CloseIcon sx={{ paddingTop: 1 }} />
                  </IconButton>

                  {/* </Button> */}
                </Grid>
              </Grid>
            </DialogTitle>
            <Divider />

            <DialogContent
            //dividers={scroll === "paper"}
            >
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xl={6}
                    mb={6}
                    sm={6}
                    sx={{ borderRight: "1px solid #ccc" }}
                  >
                    <Box m={3}>
                      <form>
                        <TextField
                          label="Title*"
                          name="title"
                          value={meeting.title}
                          onChange={(e) => {
                            setMeeting((prevMeeting) => ({
                              ...prevMeeting,
                              title: e.target.value,
                            }));
                          }}
                          placeholder="Title of the meeting"
                          fullWidth
                          sx={{ mb: 1 }}
                        />
                        {errors.title && (
                          <FormHelperText error>{errors.title}</FormHelperText>
                        )}
                        <TextField
                          fullWidth
                          id="outlined-multiline-flexible"
                          label="Description*"
                          multiline
                          value={meeting.description}
                          onChange={(e) => {
                            setMeeting((prevMeeting) => ({
                              ...prevMeeting,
                              description: e.target.value,
                            }));
                          }}
                          name="description"
                          rows={3}
                          placeholder="A short description "
                          sx={{ mb: 1 }}
                        />

                        <Typography
                          variant="p"
                          sx={{ ml: 1, fontSize: "12px", mb: 1 }}
                        >
                          ({characterCount}/100)
                        </Typography>
                        {errors.description && (
                          <FormHelperText error>
                            {errors.description}
                          </FormHelperText>
                        )}
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            className="LoginButton"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Schedule
                          </Button>
                        </Stack>
                      </form>
                    </Box>
                  </Grid>
                  <Grid item xl={6} mb={6} sm={6}>
                    <Box style={{ margin: "0% 25% 25% 25%" }}>
                      <Table className="no-border-bottom">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {" "}
                              <Avatar
                                src={Profile}
                                sx={{ height: "30px", width: "30px" }}
                              />{" "}
                            </TableCell>
                            <TableCell>
                              {" "}
                              <Typography variant="p">
                                {" "}
                                {otherUser.name}{" "}
                              </Typography>{" "}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <img
                                src={calenderClock}
                                alt="clock"
                                height="25px"
                                width="25px"
                              />
                            </TableCell>
                            <TableCell>{formattedDate}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <ScheduleIcon></ScheduleIcon>
                            </TableCell>
                            <TableCell>{duration} mins</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <LanguageIcon></LanguageIcon>
                            </TableCell>
                            <TableCell>{selectedTimeZone}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {selectedValues.length > 0 ? (
                                <EmailOutlinedIcon></EmailOutlinedIcon>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            {selectedValues.map((value) => (
                              <TableCell key={value}>{value.value}</TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    {/* <IconButton onClick={handelNextPage} sx={{backgroundColor:"#333333",float:"inline-end"}} ><ChevronRightIcon sx={{color:"white", fontSize:"25px"}}/> </IconButton> */}
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </div>
  );
}
