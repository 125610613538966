import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Home } from "../pages/home/home";
import { ForgetPassword } from "../pages/forgotPassword/forgotPassword";
import { PasswordRecovery } from "../pages/forgotPassword/passwordRecovery";
import { ResetPasswordSuccess } from "../pages/forgotPassword/ResetPasswordSuccess";
import { ForgotPasswordVerification } from "../pages/forgotPassword/forgotPasswordVerification";
import { Dashboard } from "src/pages/Dashboard/Dashboard";
import { Signup } from "src/pages/SignUp/Signup";
import { SignIn } from "src/pages/SignIn/SignIn";
import { ChatPage } from "src/pages/Dashboard/ChatPage";
import NoJobCreated from "src/pages/Main/NoJobCreated";
import PrivacyTerms from "src/pages/profile/PrivacyTerms";
import { PaymentStateProvider } from "src/utils/PaymentStateProvider";
import Privacy from "src/pages/home/Privacy";
import Contact from "src/pages/home/Contact";
import ContactForm from "src/pages/Main/ContactForm";
import PageNotFound from "src/pages/Error/Error";
import Globalfile from "src/pages/globalFile/Globalfile";
import JobFiles from "src/pages/globalFile/JobFiles";
import SelectMenu from "src/pages/profile/selectMenu";
import Payment from "src/pages/profile/Payment";
import ProfilePage from "src/pages/profile/ProfilePage";
// import CalendarConnect from "src/pages/profile/CalendarConnect";
// import CalendarSchedule from "src/pages/profile/CalendarSchedule";

export const PublicRoute = () => {
  const PrivateWrapper = () => {
    return localStorage.getItem("authProviderToken") ? (
      <Outlet />
    ) : (
      <Navigate to="/signin" />
    );
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/CardDetailsForm" element={<CardDetailsForm />} /> */}
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<Signup />} />
          <Route element={<PrivateWrapper />}>
            <Route
              path="/dashboard"
              element={
                <PaymentStateProvider>
                  <Dashboard />
                </PaymentStateProvider>
              }
            >
              <Route path="chat/:id?" element={<ChatPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="AccountSettings" element={<SelectMenu/>} />
              <Route path="privacyterms" element={<PrivacyTerms />} />
              <Route path="carddetails" element={<Payment />} />
              <Route path="contactus" element={<ContactForm />} />
              <Route path="calendar" element={<NoJobCreated />}/>
              <Route path="" element={<NoJobCreated />} />
              <Route path="file" element={<Globalfile />}>
                {" "}
              </Route>
              <Route path="file/jobFiles/:id/:name?/*" element={<JobFiles />} />
            </Route>
          </Route>
          <Route path="/forgotPassword" element={<ForgetPassword />} />
          <Route path="/passwordRecovery" element={<PasswordRecovery />} />
          <Route
            path="/resetpasswordsuccess"
            element={<ResetPasswordSuccess />}
          />
          <Route
            path="/verification"
            element={<ForgotPasswordVerification />}
          />
          <Route
            path="/forgotPasswordVerification"
            element={<ForgotPasswordVerification />}
          />
          <Route path="/PrivacyPolicy" element={<Privacy />} />
          <Route path="/TermsAndConditions" element={<Privacy />} />
          <Route path="/ContactUs" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
