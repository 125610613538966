import axios from "axios";
import {
  adminRoutes,
  baseURLs,
  jobRoutes,
  stripeRoutes,
  supervisorRoutes,
} from "../constants/api.constants";
import { yemoApiQuery } from "src/utils/apiQuery";
import { handleApiError } from "src/utils/apiError";
const accessToken = () => localStorage.getItem("authProviderToken");

export const supervisorApi = {
  getAllWatingJob: () =>
    handleApiError(async () => {
      const getAllWatingJob = await axios.get(
        `${baseURLs.yemo}${supervisorRoutes.getAllWatingJob}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return getAllWatingJob;
    }),
  getAllCompletedJob: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${supervisorRoutes.getAllCompletedJob}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getAllConflictJob: () =>
    handleApiError(async () => {
      const jobs = await axios.get(
        `${baseURLs.yemo}${supervisorRoutes.getAllConflictJob}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return jobs;
    }),
  getSellers: () =>
    handleApiError(async () => {
      const getSellers = await axios.get(
        `${baseURLs.yemo}${supervisorRoutes.getAllSellers}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return getSellers;
    }),
  getsuperviserJob: () =>
    handleApiError(async () => {
      const getsuperviserJob = await axios.get(
        `${baseURLs.yemo}${supervisorRoutes.getsuperviserJob}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return getsuperviserJob;
    }),
  addSellerToJob: (data) =>
    handleApiError(async () => {
      const getsuperviserJob = await axios.post(
        `${baseURLs.yemo}${supervisorRoutes.addSellerToJob}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return getsuperviserJob;
    }),
};

export const adminApi = {
  getStaticData: (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${adminRoutes.getStaticData}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  askQuestionYemoAI: async (data, jobId) =>
    handleApiError(async () => {
      const questions = await axios.patch(
        `${baseURLs.yemo}${adminRoutes.askQuestionYemo}/${jobId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return questions;
    }),
  getAllCategories: async () =>
    handleApiError(async () => {
      const categories = await yemoApiQuery.get(`${adminRoutes.getCategories}`);
      return categories;
    }),
  getSubCategories: async (id) =>
    handleApiError(async () => {
      const subCategories = await yemoApiQuery.get(
        `${adminRoutes.getSubCategoriesByCategoryId}/${id}`
      );
      return subCategories;
    }),
};

export const paymentAPI = {
  attachPayMethodToCustomer: async (body) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${stripeRoutes.paymentMethodToCustomer}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data };
    }),
  getPaymentMethodByCustomer: async () =>
    handleApiError(async () => {
      const { data } = await axios.get(
        `${baseURLs.yemo}${stripeRoutes.getPaymentMethodByCustomer}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  getCustomerId: async () =>
    handleApiError(async () => {
      const { data } = await axios.get(
        `${baseURLs.yemo}${stripeRoutes.getCustomerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data };
    }),
  getPayS: async () =>
    handleApiError(async () => {
      const { data } = await axios.get(
        `${baseURLs.yemo}${stripeRoutes.getPaySKey}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data };
    }),
  getJobPaymentSummary: async (jobId, req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.getPrice}/${jobId}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
    getJobPriceTab: async (jobId, req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.getPriceTab}/${jobId}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  createPaymentIntent: async (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${stripeRoutes.createPaymentIntent}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  setDefaultCard: async (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${stripeRoutes.setDefaultCard}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  createTransaction: async (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.createTransaction}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  updateWallet: async (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.updateWallet}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
  payWithWallet: async (req) =>
    handleApiError(async () => {
      const { data } = await axios.post(
        `${baseURLs.yemo}${jobRoutes.payWithWallet}`,
        req,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "authProviderToken"
            )}`,
          },
        }
      );
      return { data };
    }),
};
