/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import { chatAPI } from "src/services/jobApis";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useLocation } from "react-router-dom";
export default function TimeCalendar({
  events,
  setEvents,
  handleEventClick,
  selectedDate,
  setSelectedDate,
  setEvent,
  setSkip,
  gotEvents,
  setGotEvents
}) { 
  const [createdSlots, setCreatedSlots] = useState([]);
  const [unChangedSlots, setUnChangedSlots] = useState([]);
  const [monthDates, setMonthDates] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [deleteEvent,setDeleteEvent] = useState(null);
  const daysOfWeek = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ];

//  const generateRandomId = () => Math.floor(Math.random() * 100000);

  // const convertToOutputFormat = (slot, startDate) => {
  //   const startDateTime = moment(startDate)
  //     .day(slot.dayId - 1)
  //     .set("hour", parseInt(slot.start.split(":")[0]))
  //     .set("minute", parseInt(slot.start.split(":")[1]));

  //   const endDateTime = moment(startDate)
  //     .day(slot.dayId - 1)
  //     .set("hour", parseInt(slot.end.split(":")[0]))
  //     .set("minute", parseInt(slot.end.split(":")[1]));

  //   return {
  //     id: generateRandomId(),
  //     start: startDateTime.format(),
  //     end: endDateTime.format(),
  //     title: "time-slot",
  //     description: "time-slot",
  //     location: "yemo",
  //   };
  // };

  // const generateEventSlots = (inputSlots) => {
  //   const startDate = moment().startOf("day");
  //   const endDate = moment(startDate).add(1, "month");

  //   const eventSlots = [];

  //   while (startDate.isBefore(endDate)) {
  //     const dayId = startDate.day();

  //     inputSlots
  //       .filter((slot) => slot.dayId === dayId + 1)
  //       .forEach((slot) => {
  //         const eventSlot = convertToOutputFormat(slot, startDate);
  //         eventSlots.push(eventSlot);
  //       });

  //     startDate.add(1, "day");
  //   }

  //   return eventSlots;
  // };

  function formatDateDetails(dateString) {
    const date = new Date(dateString);
    const dayId = daysOfWeek[date.getDay()].id;
    
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${formattedHours}:${formattedMinutes}`;

    return { dayId, time };
  }

  const getOriginalTime = (dateString) => {
    const inputDate = moment(dateString);
    const resultDate = inputDate.subtract(5, "hours").subtract(30, "minutes");
    return resultDate.format("ddd MMM DD YYYY HH:mm:ss");
  };

  const checkDateRange = (dateString) => {
    const dateToCheck = moment(dateString);
    const currentDate = moment();
    const firstDayOfNextMonth = moment(currentDate).add(1, 'months').startOf('month');
    return dateToCheck.isSameOrAfter(currentDate) && dateToCheck.isBefore(firstDayOfNextMonth);
  };

  const handleDateSelect = (e) => {
    if(checkDateRange(e.startStr)){
      setCreatedSlots((prevArray) => [
        ...prevArray,
        {
          dayId: formatDateDetails(e.startStr).dayId,
          start: formatDateDetails(e.startStr).time,
          end: formatDateDetails(e.endStr).time,
        },
      ]);
      const slots = [
        ...createdSlots,
        {
          dayId: formatDateDetails(e.startStr).dayId,
          start: formatDateDetails(e.startStr).time,
          end: formatDateDetails(e.endStr).time,
        }
      ];
      const newEvents = generateTimeSlots(slots, monthDates,"#126AA8");
      setEvents((prevArray) => {
        const filteredArray = prevArray.filter(
          (prevSlot) =>
            !(
              prevSlot.title === "time-slot" &&
              prevSlot.description === "time-slot" &&
              prevSlot.location === "yemo"
            )
        );
        return [...filteredArray, ...newEvents];
      });
    }
  };

  const handleDeleteSlot = (objectToRemove) => {
    const newArray = createdSlots.filter((obj) => {
      return (
        obj.dayId !== objectToRemove.dayId ||
        obj.start !== objectToRemove.start ||
        obj.end !== objectToRemove.end
      );
    });
    setCreatedSlots(newArray);
    const newEvents = generateTimeSlots(newArray, monthDates,"#126AA8");

    return newEvents;
  };
  const handleDeleteEvent = (events) => {
    setEvents((prevArray) => {
      const filteredArray = prevArray.filter(
        (prevSlot) =>
          !(
            prevSlot.title === "time-slot" &&
            prevSlot.description === "time-slot" &&
            prevSlot.location === "yemo"
          )
      );
      return [...filteredArray, ...events];
    });
  };

  const handleDeleteEventClick = (e) => {
    if(e !== null && e.event._instance.range){
      const slot = {
        dayId: formatDateDetails(getOriginalTime(e.event._instance.range.end))
          .dayId,
        start: formatDateDetails(getOriginalTime(e.event._instance.range.start))
          .time,
        end: formatDateDetails(getOriginalTime(e.event._instance.range.end)).time,
      };
      const events = handleDeleteSlot(slot);
      handleDeleteEvent(events);
    }
  };
  const location = useLocation();
  const containsSpecificValue = location.pathname.includes('/calendar');  

  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2.find(item => (
        item.dayId === obj1.dayId &&
        item.start === obj1.start &&
        item.end === obj1.end
      ));
  
      if (!obj2) {
        return false;
      }
    }
  
    return true;
  }  


  const handleSave = () => {
    if (createdSlots !== unChangedSlots) {
      chatAPI
        .createTimeSlots(createdSlots)
        .then((res) => {
          setUnChangedSlots(res.data.data);
          setCreatedSlots(res.data.data);
          const newEvents = generateInitialTimeSlots(res.data.data, monthDates,"#126AA8");
          setEvents((prevArray) => {
            const filteredArray = prevArray.filter(
              (prevSlot) =>
                !(
                  prevSlot.title === "time-slot" &&
                  prevSlot.description === "time-slot" &&
                  prevSlot.location === "yemo"
                )
            );
            return [...filteredArray, ...newEvents];
                });
          toast.success('Saved time slots successfully');
          if(!containsSpecificValue){
            setSkip(true)
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!");
        });
    }
  };

  function generateSchedule() {
    const today = new Date();
    const result = [];

    daysOfWeek.forEach((day) => {
      const dayId = day.id;
      const dayDates = [];
      let nextDate = new Date(today);
      while (nextDate.getDay() !== dayId - 1) {
        nextDate.setDate(nextDate.getDate() + 1);
      }
      while (nextDate.getMonth() === today.getMonth()) {
        const formattedDate = `${nextDate.getDate()}-${
          nextDate.getMonth() + 1
        }-${nextDate.getFullYear()}`;
        dayDates.push(formattedDate);

        nextDate.setDate(nextDate.getDate() + 7);
      }
      const scheduleObject = {
        dayId,
        dates: dayDates,
      };
      result.push(scheduleObject);
    });
    setMonthDates(result);
    return result;
  }

  function convertDateTime(dateTime, time, dateFormat, timeFormat, timeZone) {
    const combinedDateTime = `${dateTime} ${time}`;
    const parsedDateTime = moment(
      combinedDateTime,
      `${dateFormat} ${timeFormat}`
    );
    const formattedDateTime = parsedDateTime.format(
      `YYYY-MM-DDTHH:mm:ss${timeZone}`
    );

    return formattedDateTime;
  }

  const findDifferences = (oldArray, newArray) => {
    const common = [];
    const uncommon = [];

    newArray.forEach(newObj => {
        const matchingObject = oldArray.find(oldObj =>
            oldObj.dayId === newObj.dayId &&
            oldObj.start === newObj.start &&
            oldObj.end === newObj.end
        );

        if (matchingObject) {
            common.push(newObj);
        } else {
            uncommon.push(newObj);
        }
    });

    return { common, uncommon };
}

  const generateTimeSlots = (timeSlots, schedule)=> {
    const result = [];
    const slots = findDifferences(unChangedSlots,timeSlots);

    slots.common.forEach((slot) => {
      const { start, end, dayId } = slot;
      schedule.forEach((daySchedule) => {
        if (daySchedule.dayId === dayId) {
          daySchedule.dates.forEach((date) => {
            const formattedSlot = {
              description: "time-slot",
              id: Math.floor(Math.random() * 100000), // Generate a random number for the id
              location: "yemo",
              start: convertDateTime(
                date,
                start,
                "D-M-YYYY",
                "HH:mm",
                "+05:30"
              ),
              end: convertDateTime(date, end, "D-M-YYYY", "HH:mm", "+05:30"),
              title: "time-slot",
              backgroundColor:"#128DA8",
              borderColor:"#128DA8"
            };
            result.push(formattedSlot);
          });
        }
      });
    });
    slots.uncommon.forEach((slot) => {
      const { start, end, dayId } = slot;

      schedule.forEach((daySchedule) => {
        if (daySchedule.dayId === dayId) {
          daySchedule.dates.forEach((date) => {
            const formattedSlot = {
              description: "time-slot",
              id: Math.floor(Math.random() * 100000), // Generate a random number for the id
              location: "yemo",
              start: convertDateTime(
                date,
                start,
                "D-M-YYYY",
                "HH:mm",
                "+05:30"
              ),
              end: convertDateTime(date, end, "D-M-YYYY", "HH:mm", "+05:30"),
              title: "time-slot",
              backgroundColor:"#DB751D",
              borderColor:"#DB751D"
            };
            result.push(formattedSlot);
          });
        }
      });
    });

    return result;
  }


  const generateInitialTimeSlots = (timeSlots, schedule)=> {
    const result = [];
    timeSlots.forEach((slot) => {
      const { start, end, dayId } = slot;
      schedule.forEach((daySchedule) => {
        if (daySchedule.dayId === dayId) {
          daySchedule.dates.forEach((date) => {
            const formattedSlot = {
              description: "time-slot",
              id: Math.floor(Math.random() * 100000), // Generate a random number for the id
              location: "yemo",
              start: convertDateTime(
                date,
                start,
                "D-M-YYYY",
                "HH:mm",
                "+05:30"
              ),
              end: convertDateTime(date, end, "D-M-YYYY", "HH:mm", "+05:30"),
              title: "time-slot",
              backgroundColor:"#128DA8",
              borderColor:"#128DA8"
            };
            result.push(formattedSlot);
          });
        }
      });
    });
    return result;
  }
  const getTimeSlots = () => {
    chatAPI
      .getTimeSlots()
      .then((res) => {
        if (res.data.success) {
          const newSlots = generateInitialTimeSlots(res.data.data, monthDates,"#126AA8");
          const slots = res.data.data.map((slot) => ({
            dayId: slot.dayId,
            start: slot.start,
            end: slot.end,
          }));
          setCreatedSlots(slots);
          setUnChangedSlots(slots);
          setEvents((prevArray) => {
            const duplicatesExist = newSlots.some((newSlot) =>
              prevArray.some(
                (prevSlot) =>
                  prevSlot.title === newSlot.title &&
                  prevSlot.description === newSlot.description &&
                  prevSlot.location === newSlot.location &&
                  prevSlot.start === newSlot.start &&
                  prevSlot.end === newSlot.end
              )
            );

            if (!duplicatesExist) {
              return [...prevArray, ...newSlots];
            }

            return prevArray;
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    if (monthDates.length > 0 && gotEvents) {
      getTimeSlots();
      setGotEvents(false);
    }
  }, [monthDates,gotEvents]);

  useEffect(() => {
    generateSchedule();
  }, []);
  console.log("event",deleteEvent);
 const handleOpenDialog = (e)=>{
  if(e.event._def.title=== "time-slot" && e.event._def.extendedProps.description === "time-slot" && e.event._def.extendedProps.location === "yemo"){
    setOpenDailog(true);setDeleteEvent(e)
  }
 }

 console.log("slots",areArraysEqual(createdSlots,unChangedSlots));
  return (
    <div
      style={{
        width: "80%",
        height: "100vh",
        backgroundColor: "#ffff",
        borderRadius: "20px",
        padding: "20px",
      }}
    >
      {/* <Link
        onClick={() => {
          setSelectedDate("");
        }}
      >
        <Button sx={{ minWidth: "40px", height: "40px" }}>
          <ChevronLeftIcon />
        </Button>
      </Link> */}
      <FullCalendar
        height="80%"
        expandRows={true}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        initialDate={selectedDate}
        events={events}
        // eventContent={renderEventContent}
        eventClick={(e)=>{handleOpenDialog(e)}}
        // Other timeGrid specific options
        selectable={true}
        select={handleDateSelect}
        allDaySlot={false}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        unselectAuto={false}
        selectOverlap={false}
      />
    
   {!areArraysEqual(createdSlots,unChangedSlots) ? <Typography>* Updated your time slots! Seal the deal with a click on 'Save.</Typography>:""}
      <Button
        onClick={() => {
          handleSave();
        }}
        className="LoginButton"
        sx={{ color: "white", marginTop: "2%" }}
      >
        Save Time-Slots
      </Button>
      <Dialog open={openDailog} onClose={() => setOpenDailog(false)}>
        <DialogTitle>Are you Sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
             To delete your Time-Slot
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDailog(false)} className="LoginButton" sx={{color:"#FFFFFF"}}>
            Cancel
          </Button>
          <Button onClick={(e)=>{handleDeleteEventClick(deleteEvent);setOpenDailog(false);setDeleteEvent(null)}} sx={{padding:"8px 14px !important"}} className="rejectButton">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// function renderEventContent(eventInfo) {
//   return (
//     <>
//       <Box>
//         <Stack sx={{ width: "80%", }}>
//           <Typography
//             variant="body"
//             sx={{ padding: "1%", fontWeight: 1000 }}
//           >
//             {eventInfo.timeText}m
//           </Typography>
//           <Typography variant="p" sx={{ padding: "2%" }}>
//             {eventInfo.event.title}
//           </Typography>
//           {/* <Typography variant="p" sx={{ padding: "2%" }}>
//             {eventInfo.event.extendedProps.description}
//           </Typography> */}
//           {/* <Typography variant="p" sx={{ padding: "2%" }}>
//             {eventInfo.event.extendedProps.location}
//           </Typography> */}
//         </Stack>
//       </Box>
//     </>
//   );
// }
