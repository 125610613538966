import React from "react";
import { Box, Typography, Button, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "./RaiseDispute.css";
import { useSharedState } from "src/utils/SharedStateProvider";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { socket } from "./Message";
import RaiseDisputeSchema from "src/validationSchema/raiseDisputeSchema";
import { toast } from "react-toastify";
import { chatAPI, jobAPI } from "src/services/jobApis";
const RaiseDispute = () => {
  const { jobId } = useSharedState();
  const [problemTitle, setProblemTitle] = React.useState("");
  const [problemDescription, setProblemDescription] = React.useState("");
  const getName = () => localStorage.getItem("userName");
  const { userJob, updateJob, setShowRejectReasonForm, setDisputeSuccess } =
    useJobSharedState();
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  };
  console.log(selectedFile);
  const validateForm = () => {
    const { error } = RaiseDisputeSchema.validate(
      { problemTitle, problemDescription, selectedFile },
      {
        abortEarly: false,
      }
    );
    if (!error) {
      setErrors({});
      return true;
    }
    const newErrors = {};
    error.details.forEach((error) => {
      newErrors[error.path[0]] = error.message;
    });
    setErrors(newErrors);
    return false;
  };
  const handleSubmitFiles = () => {
    if (selectedFile.length > 0) {
      const payload = {
        files: selectedFile,
        jobId,
        yemo: true,
      };
      chatAPI
        .sendFiles(payload)
        .then((res) => {
          setSelectedFile(0);
          const messageData = res.data.data[0];
          socket.emit("sendMessage", { messageData });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    }
  };
  const raiseDispute = () => {
    if (validateForm()) {
      if (selectedFile.length > 0) {
        handleSubmitFiles();
      }
      jobAPI
        .markJobAsComplete(jobId, {
          problemTitle,
          problemDescription,
          transferToYemo: true,
        })
        .then((res) => {
          if (res.data.success) {
            setShowRejectReasonForm(false);
            setDisputeSuccess(true);
            const updatedUserJob = {
              ...userJob,
              markJobAsComplete: false,
            };
            updateJob(updatedUserJob);
            const messageData = {
              jobId: jobId,
              message: `${getName()} decline the request for completion`,
            };
            socket.emit("yemoMessage", { messageData });
            socket.emit("rightSideBar", messageData);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong!!");
        });
    }
  };
  const characterCount = problemDescription.length;
  return (
    <Box className="RaiseDisputeBox">
      <Typography variant="body1">Let me help you solve your issue </Typography>
      <TextField
        id="outlined-basic"
        label="Title*"
        variant="outlined"
        name="problemTitle"
        value={problemTitle}
        onChange={(e) => {
          if (problemTitle.length < 100) setProblemTitle(e.target.value);
        }}
        error={!!errors.problemTitle}
      />
      <FormHelperText>{errors.problemTitle}</FormHelperText>
      <TextField
        fullWidth
        id="outlined-multiline-flexible"
        multiline
        label="Describe your issue here*"
        variant="outlined"
        name="problemDescription"
        value={problemDescription}
        onChange={(e) => {
          if (problemDescription.length < 100)
            setProblemDescription(e.target.value);
        }}
        error={!!errors.problemDescription}
      />
      <Typography variant="p" sx={{ ml: 1, fontSize: "12px" }}>
        ({characterCount}/100)
      </Typography>
      <FormHelperText>{errors.problemDescription}</FormHelperText>
      <label
        htmlFor="formFileMultiple"
        style={{ cursor: "pointer", display: "contents" }}
      >
        <Box
          className="UploadCnt"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <UploadFileIcon />
          Click to upload or drag and drop
          <Typography variant="body1">
            SVG, PNG, JPG, or GIF (max. 3MB)
          </Typography>
          {selectedFile &&
            selectedFile.map((file) => (
              <Typography variant="body1">{file.name}</Typography>
            ))}
          <Box className="fileUploadd">
            <input
              type="file"
              id="formFileMultiple"
              style={{ display: "none" }}
              multiple
              name="file"
              accept=".svg, .jpg, .jpeg, .png, .pdf"
              onChange={handleFileChange}
            />
          </Box>
        </Box>
      </label>

      <Button
        variant="contained"
        onClick={() => {
          raiseDispute();
        }}
      >
        Solve my issue
      </Button>
    </Box>
  );
};

export default RaiseDispute;
