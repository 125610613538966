/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import "./RejectAlert.css";
import ProfileImg from "../../Assets/Profile.png";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useJobSharedState } from "src/utils/jobStateProvider";
import { socket } from "../Main/Message";
import { useSharedState } from "src/utils/SharedStateProvider";

const RejectAlert = () => {
  const userName = localStorage.getItem("userName");
  const { jobId, setIsPopupVisible } = useSharedState();
  const { setDisputeEngineer, setDisputeYemo, setDispute } =
    useJobSharedState();
  const handleEngg = () => {
    setDisputeEngineer(true);
    setDispute(false);
    const messageData = {
      jobId: jobId,
      message: `${userName}  decline the request for completion`,
    };
    socket.emit("rightSideBar", messageData);
  };

  return (
    <>
      <Box className="RejectAlerts">
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <Avatar src={ProfileImg} alt="Avatar" />
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            I see you rejected the request for completion. Would you like to
            raise a dispute?
          </Typography>
          <Link
            onClick={() => {
              setDispute(false);
              setIsPopupVisible(true);
            }}
          >
            <CloseIcon />
          </Link>
        </Box>
        <Stack spacing={1} direction="row">
          <Button
            variant="contained"
            className="LoginButton"
            size="small"
            onClick={handleEngg}
          >
            {" "}
            Resolve with engg
          </Button>
          <Button
            variant="contained"
            className="rejectButton"
            size="small"
            onClick={() => {
              setDisputeYemo(true);
            }}
          >
            {" "}
            Expert Advice{" "}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default RejectAlert;
