/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Tab, Typography, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link } from "react-router-dom";
import logo from "../../Assets/512_-removebg-preview1.png";

import "./Privacy.css";
import { toast } from "react-toastify";
import { adminApi } from "src/services/api";

export default function Privacy() {
  const [value, setValue] = useState("1");
  const [privacy, setPrivacy] = useState({});
  const [terms, setTerms] = useState({});
  useEffect(() => {
    getPrivacyTerms("privacy")
      .then((res) => {
        setPrivacy(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
    getPrivacyTerms("terms")
      .then((res) => {
        setTerms(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);

        toast.error("Something went wrong!!");
      });
  }, []);
  const privacyTabs = [
    { label: "Privacy Policy", value: "1" },
    { label: "Terms & Conditions", value: "2" },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPrivacyTerms = (name) => {
    return adminApi.getStaticData({ name });
  };
  const url = window.location.href;
  useEffect(() => {
    if (url.indexOf("TermsAndConditions") !== -1) {
      setValue("2");
    }
  }, []);
  return (
    <div>
      <Box className="privacyTerms">
        {/* <Box sx={{ my: 2, px: 2, display: "flex", alignItems: "center" }}>
        <Link to="..">
          <Button>
            <ChevronLeftIcon />
          </Button>
        </Link>
        Privacy Policy
      </Box> */}
        <Box className="LoginHeader">
          <Link to="/">
            <img src={logo} alt="logo" height={28} />
          </Link>
          <Box>
            <Link to={"/signin"}>
              <Button variant="text">Login</Button>
            </Link>
            <Link to={"/signup"}>
              <Button variant="contained" className="signup">
                Sign up
              </Button>
            </Link>
          </Box>
        </Box>

        <TabContext value={value}>
          <Box sx={{ borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              {privacyTabs.map((obj) => (
                <Tab label={obj.label} value={obj.value} key={obj.value} />
              ))}
            </TabList>
          </Box>
          <TabPanel value="1" className="TabContent">
            <Box
              dangerouslySetInnerHTML={{ __html: privacy.data }}
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: { fontSize: "14px" },
              })}
            />
          </TabPanel>
          <TabPanel value="2" className="TabContent">
            <Box
              dangerouslySetInnerHTML={{ __html: terms.data }}
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: { fontSize: "14px" },
              })}
            />
          </TabPanel>
        </TabContext>

        <Box
          className="YemoFooter"
          sx={(theme) => ({
            backgroundColor: "white",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              textAlign: "center",
            },
          })}
        >
          <Typography
            variant="p"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                width: "100%",
                order: "2",
                mt: 1,
                fontSize: "12px",
                flexDirection: "column",
              },
            })}
          >
            YeMo.ai 2023 © All Rights Reserved
          </Typography>
          <Box
            className="footerLinks"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: { width: "100%" },
            })}
          >
            <Link target="_blank" to="/PrivacyPolicy">
              Policies
            </Link>
            <Link target="_blank" to="/TermsAndConditions">
              Terms
            </Link>
            <Link target="_blank" to="/ContactUs">
              Contact us
            </Link>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
