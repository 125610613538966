/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Button, Divider, Tab } from "@mui/material";
import { useSharedState } from "src/utils/SharedStateProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ProjectDetailsIcon from "../../Assets/icon-project-details.svg";
import { Link } from "react-router-dom";
import { TabContext, TabList } from "@mui/lab";
import {  useJobSharedState } from "src/utils/jobStateProvider";

export default function SuperHeader() {
  const { showRightSidebar, setShowRightSidebar } = useSharedState();
  const { superviserAccept, setSuperviserAccept } = useJobSharedState();
  const {userJob} = useJobSharedState();
  const {jobId} = useSharedState();
  const handleTabChange = (event, newValue) => {
    setSuperviserAccept(newValue === "people");
  };

  useEffect(() => {
    // Update the tab value when jobId changes
    if (userJob.jobStatus !== "conflict" || userJob.jobStatus !== "") {
      setSuperviserAccept(true);
    }
  }, [jobId]);

  useEffect (()=>{
      if(userJob.jobStatus !== "waiting")
      {
        setSuperviserAccept(false)
      }
  },[jobId])

  return (
    <>
      <Box
        className="chatheader"
        sx={(theme) => ({ mt: 1, [theme.breakpoints.down("md")]: { mt: 0 } })}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up("md")]: { display: "none" },
            })}
          >
            <Link to="..">
              <Button sx={{ minWidth: "40px", height: "40px" }}>
                <ChevronLeftIcon />
              </Button>
            </Link>
          </Box>
          <Box sx={{ ml: 1 }} className="superHeader">
            <TabContext
              value={superviserAccept ? "people" : "chat"}
              sx={{ width: "100%" }}
            >
              {userJob.jobStatus === "waiting" ? (
                <TabList onChange={handleTabChange}>
                  <Tab label="People" value="people" className="buyerTab" />
                  <Tab label="Chat" value="chat" className="buyerTab" />
                </TabList>
              ) : (
                <TabList>
                  <Tab label="Chat" value="chat" className="buyerTab" />
                </TabList>
              )}
            </TabContext>
          </Box>
          <Box
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.down("md")]: { ml: "auto", display: "flex" },
            })}
          >
            <Button
              sx={{ minWidth: "40px", height: "40px" }}
              onClick={() => {
                setShowRightSidebar(!showRightSidebar);
              }}
            >
              <img src={ProjectDetailsIcon} alt="" />
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
}
